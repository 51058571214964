<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">

    <form (submit)="addQuestion(form)" #form="ngForm" >
        <div class="card inner-card-1">
            <div class="text-center row">       
                <div class="col-md-12">
                    <h1 style="font-size: 25px;"><u>Edit Multiple Question {{questionData.questionNumber}}</u></h1>
                </div>
            </div>
            <div class="row"> 
                <div class="col-md-4">
                    Question Text : 
                </div>
                <div class="col-md-8">
                    <textarea type="text" style="width: 100%;"  name="questionText" [(ngModel)]="questionData.questionText" required placeholder="Enter Question Title...."></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    Responses: 
                </div>
                <div class="col-md-8 text-center">
                    <div  class="row" style="height: 220px;overflow-y:scroll;" #content>
                        <div class="col-md-12" #messages *ngFor="let response of questionData.options;let q=index;">
                            <input type="text" class="mb-1" name="singleResponse{{q}}" style="width: 250px;"
                            [(ngModel)]="response.text"
                            required placeholder="Response {{q+1}}">

                        <textarea class="mb-1" name="singleResponseDesc{{q}}" style="width: 250px;"
                            [(ngModel)]="response.description"
                            required placeholder="Description {{q+1}}">
                        </textarea>
                        </div>
                    </div>
                    <div  class="row" >
                        <div class="col-md-12 text-center">
                        <button (click)="addNewResponse()" type="button" class="btn1"><i class="fas fa-plus"></i> Add New Response</button>
                    </div>
                    </div>
                </div>
            </div>    
            <div class="row"> 
                <div class="col-md-4">
                    Manual User Entry : 
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3">
                    <input type="radio" [(ngModel)]="questionData.userEntry" name="userEntry" value="1" required> Yes
                </div>
                <div class="col-md-3">
                    <input type="radio" [(ngModel)]="questionData.userEntry" name="userEntry" value="0" required> No
                </div>
            </div>            
            <div class="row text-center" style="margin-top:-20px">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <button (click)="addSubQuestions()" type="button" class="btn btn-success">Add SubQuestion</button>                        
                </div>
                <div class="col-md-4">
                    <button type="submit" [disabled]="!form.valid" id="saveBtn" class="btn btn-success">Update</button>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>

    </form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>
