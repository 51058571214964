import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
//to install angular material
//ng add @angular/material

//for pie chart --
//npm install --save ng2-charts
//npm install --save chart.js

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
// import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
// import { NamefilterPipe } from './namefilter.pipe';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { NameFilterPipe } from './name-filter.pipe';
import { LoginComponent } from './login/login.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { ProfileComponent } from './profile/profile.component';
import { ShortenPipe } from './shorten.pipe';
import { OnlyLoginGuardService } from './services/only-login-guard.service';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ExcelService } from './services/excel.service';
import { ModuleFilterPipe } from './module-filter.pipe';
import { ClassFilterPipe } from './class-filter.pipe';
import { ShowAllUsersComponent } from './show-all-users/show-all-users.component';
import { ShowDetailsComponent } from './show-details/show-details.component';
import { DeleteCustomerComponent } from './delete-customer/delete-customer.component';
import { AllSuggestionsComponent } from './all-suggestions/all-suggestions.component';
import { SendNotificationsComponent } from './send-notifications/send-notifications.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AddTestDetailsComponent } from './add-test-details/add-test-details.component';
import { AddTestQuestionsComponent } from './add-test-questions/add-test-questions.component';
import { AllTestsComponent } from './all-tests/all-tests.component';
import { AllSubCategoriesComponent } from './all-sub-categories/all-sub-categories.component';
import { ShowQuestionsComponent } from './show-questions/show-questions.component';
import { CreateNewCategoryComponent } from './create-new-category/create-new-category.component';
import { ViewCategoryDetailsComponent } from './view-category-details/view-category-details.component';
import { EditCategoryDetailsComponent } from './edit-category-details/edit-category-details.component';
import { EditSubCategoryDetailsComponent } from './edit-sub-category-details/edit-sub-category-details.component';
import { EditQuestionDialogComponent } from './edit-question-dialog/edit-question-dialog.component';
import { CreateNewScaleComponent } from './create-new-scale/create-new-scale.component';
import { CreateNewQuescategoryComponent } from './create-new-quescategory/create-new-quescategory.component';
import { ChartsModule } from 'ng2-charts';
import { DashboardDataComponent } from './dashboard-data/dashboard-data.component';
import { ShowScalesComponent } from './show-scales/show-scales.component';
import { ShowQuescategoriesComponent } from './show-quescategories/show-quescategories.component';
import { EditScaleComponent } from './edit-scale/edit-scale.component';
import { CreateNewQuesCategoryDialogComponent } from './create-new-ques-category-dialog/create-new-ques-category-dialog.component';
import { EditQuescategoryComponent } from './edit-quescategory/edit-quescategory.component';
import { AddQuestionDialogComponent } from './add-question-dialog/add-question-dialog.component';
import { AddRemainingQuestionsComponent } from './add-remaining-questions/add-remaining-questions.component';
import { AllFaqComponent } from './all-faq/all-faq.component';
import { CreateFaqComponent } from './create-faq/create-faq.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { AboutDetailsComponent } from './about-details/about-details.component';
import { EditAboutComponent } from './edit-about/edit-about.component';
import { AddAboutComponent } from './add-about/add-about.component';
import { ShowAboutImageComponent } from './show-about-image/show-about-image.component';
import { ShowPoliciesComponent } from './show-policies/show-policies.component';
import { ViewScaleComponent } from './view-scale/view-scale.component';
import { ViewQuesCategoryComponent } from './view-ques-category/view-ques-category.component';
import { AllReportsComponent } from './all-reports/all-reports.component';
import { ViewSingleReportComponent } from './view-single-report/view-single-report.component';
import { AllFeedbacksComponent } from './all-feedbacks/all-feedbacks.component';
import { AddQuestionsMultipletypeComponent } from './add-questions-multipletype/add-questions-multipletype.component';
import { AddSubquestionComponent } from './add-subquestion/add-subquestion.component';
import { CreateScaleMultipleComponent } from './create-scale-multiple/create-scale-multiple.component';
import { ShowSubquestionScaleoptionsComponent } from './show-subquestion-scaleoptions/show-subquestion-scaleoptions.component';
import { ShowQuestionMultipleComponent } from './show-question-multiple/show-question-multiple.component';
import { ShowSubQuestionsDialogComponent } from './show-sub-questions-dialog/show-sub-questions-dialog.component';
import { EditMultiQuestionComponent } from './edit-multi-question/edit-multi-question.component';
import { EditSubQuestionComponent } from './edit-sub-question/edit-sub-question.component';
import { CategoryUsersComponent } from './all-sub-categories/category-users/category-users.component';
import { UserResponsesComponent } from './all-sub-categories/user-responses/user-responses.component';
import { ShowSubquestionResponsesComponent } from './all-sub-categories/show-subquestion-responses/show-subquestion-responses.component';
import { EditAdminPasswordComponent } from './edit-admin-password/edit-admin-password.component';
import { ReportFeedbacksComponent } from './report-feedbacks/report-feedbacks.component';
import { ShowReportFeedbackComponent } from './show-report-feedback/show-report-feedback.component';
import { QuillModule } from 'ngx-quill';
import { ShowQuestionCategoryComponent } from './show-question-category/show-question-category.component';
import { SubAdminListComponent } from './sub-admin-list/sub-admin-list.component';
import { SubAdminSaveComponent } from './sub-admin-save/sub-admin-save.component';
import { AuthGuard } from './services/auth.guard';
import { ViewSingleFeedbackComponent } from './view-single-feedback/view-single-feedback.component';
import { AllFeaturesComponent } from './all-features/all-features.component';
import { ViewSingleFeatureComponentComponent } from './view-single-feature-component/view-single-feature-component.component';
import { AllQuotesComponent } from './all-quotes/all-quotes.component';
import { CreateQuoteComponent } from './create-quote/create-quote.component';
import { WaitlistUsersComponent } from './waitlist-users/waitlist-users.component';
import { CreateWaitlistUserComponent } from './create-waitlist-user/create-waitlist-user.component';

const appRoutes: Routes = [
  {
    path: 'admin/dashboard',
    component: DashboardDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/all-users',
    component: ShowAllUsersComponent,
    canActivate: [AuthGuard],
    data: { permission: 'userData' },
  },
  {
    path: 'admin/all-suggestions',
    component: AllSuggestionsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'admin/send-notifications',
    component: SendNotificationsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'notification' },
  },
  {
    path: 'admin/add-test-questions',
    component: AddTestQuestionsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/all-tests',
    component: AllTestsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/all-subcategories/:id',
    component: AllSubCategoriesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/test-details/:categoryId',
    component: AddTestDetailsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/add-test-questions',
    component: AddTestQuestionsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/create-new-category',
    component: CreateNewCategoryComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/edit-category/:categoryId',
    component: EditCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/edit-sub-category/:categoryId/:subCategoryId',
    component: EditSubCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/add-test-questions-multiple',
    component: AddQuestionsMultipletypeComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/edit-test-questions-multiple/:quesNum',
    component: EditMultiQuestionComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/add-test-subquestions/:questionNumber',
    component: AddSubquestionComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/edit-subquestions',
    component: EditSubQuestionComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/show-test-questions/:subCategoryId',
    component: ShowQuestionsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/participated-users/:subCategoryId',
    component: CategoryUsersComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/show-user-responses/:subCategoryId/:userId',
    component: UserResponsesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/show-test-questions-multiple/:subCategoryId',
    component: ShowQuestionMultipleComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/add-questions',
    component: AddRemainingQuestionsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'testManagement' },
  },
  {
    path: 'admin/sub-admins',
    component: SubAdminListComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/sub-admin/save',
    component: SubAdminSaveComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/sub-admin/save/:id',
    component: SubAdminSaveComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/track-quotes',
    component: AllQuotesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/waitlist',
    component: WaitlistUsersComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/all-scales',
    component: ShowScalesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'qResponse' },
  },
  {
    path: 'admin/all-quescategories',
    component: ShowQuescategoriesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'dimension' },
  },
  {
    path: 'admin/question-category/:categoryId/view',
    component: ShowQuestionCategoryComponent,
    canActivate: [AuthGuard],
    data: { permission: 'dimension' },
  },
  {
    path: 'admin/all-faqs',
    component: AllFaqComponent,
    canActivate: [AuthGuard],
    data: { permission: 'faq' },
  },
  {
    path: 'admin/about-details',
    component: AboutDetailsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'about' },
  },
  {
    path: 'admin/show-policies',
    component: ShowPoliciesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'policy' },
  },
  {
    path: 'admin/all-reports',
    component: AllReportsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'bugReport' },
  },
  {
    path: 'admin/all-feedbacks',
    component: AllFeedbacksComponent,
    canActivate: [AuthGuard],
    data: { permission: 'feedback' },
  },
  {
    path: 'admin/all-features',
    component: AllFeaturesComponent,
    canActivate: [AuthGuard],
    data: { permission: 'feedback' },
  },
  {
    path: 'admin/report-feedbacks',
    component: ReportFeedbacksComponent,
    canActivate: [AuthGuard],
    data: { permission: 'reportFeedback' },
  },

  {
    path: 'admin/login',
    component: LoginUserComponent,
    canActivate: [OnlyLoginGuardService],
  },
  { path: 'admin/profile', component: ProfileComponent },

  { path: '', redirectTo: '/admin/login', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    // NamefilterPipe,
    NameFilterPipe,
    LoginUserComponent,
    ProfileComponent,
    ShortenPipe,
    EditAdminComponent,
    ModuleFilterPipe,
    ClassFilterPipe,
    ShowAllUsersComponent,
    ShowDetailsComponent,
    DeleteCustomerComponent,
    AllSuggestionsComponent,
    SendNotificationsComponent,
    EditUserComponent,
    AddTestDetailsComponent,
    AddTestQuestionsComponent,
    AllTestsComponent,
    AllSubCategoriesComponent,
    ShowQuestionsComponent,
    CreateNewCategoryComponent,
    ViewCategoryDetailsComponent,
    EditCategoryDetailsComponent,
    EditSubCategoryDetailsComponent,
    EditQuestionDialogComponent,
    CreateNewScaleComponent,
    CreateNewQuescategoryComponent,
    DashboardDataComponent,
    ShowScalesComponent,
    ShowQuescategoriesComponent,
    EditScaleComponent,
    CreateNewQuesCategoryDialogComponent,
    EditQuescategoryComponent,
    AddQuestionDialogComponent,
    AddRemainingQuestionsComponent,
    AllFaqComponent,
    CreateFaqComponent,
    EditFaqComponent,
    AboutDetailsComponent,
    EditAboutComponent,
    AddAboutComponent,
    ShowAboutImageComponent,
    ShowPoliciesComponent,
    ViewScaleComponent,
    ViewQuesCategoryComponent,
    AllReportsComponent,
    ViewSingleReportComponent,
    AllFeedbacksComponent,
    AddQuestionsMultipletypeComponent,
    AddSubquestionComponent,
    CreateScaleMultipleComponent,
    ShowSubquestionScaleoptionsComponent,
    ShowQuestionMultipleComponent,
    ShowSubQuestionsDialogComponent,
    EditMultiQuestionComponent,
    EditSubQuestionComponent,
    CategoryUsersComponent,
    UserResponsesComponent,
    ShowSubquestionResponsesComponent,
    EditAdminPasswordComponent,
    ReportFeedbacksComponent,
    ShowReportFeedbackComponent,
    ShowQuestionCategoryComponent,
    SubAdminListComponent,
    SubAdminSaveComponent,
    ViewSingleFeedbackComponent,
    AllFeaturesComponent,
    ViewSingleFeatureComponentComponent,
    AllQuotesComponent,
    CreateQuoteComponent,
    WaitlistUsersComponent,
    CreateWaitlistUserComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxImageZoomModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    ChartsModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CommonModule,
    PdfViewerModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [MessagingService, AsyncPipe, OnlyLoginGuardService, ExcelService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
})
export class AppModule {}
