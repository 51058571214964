<div class="container">
    <div *ngFor="let question of testDetails;let i = index;">
    <div class="row pb-4 pt-2" style="background-color: #4A9F37;" *ngIf="i==questionCount">
        <div class="col-md-3 mt-3" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="decreaseQuesNo()">
            <i class="fas fa-arrow-circle-left" style="font-size: 31px;"></i> <span > Previous Qns </span>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-4 mt-4 p-3" style="background-color: white;border-radius: 16px;">
            <p style="color:#000000;font-size:18px;font-weight: 650;"> Question {{i+1}} out of {{testDetails.length}} </p>
            
            <p style="color:#4A9F37;;font-size:15px;font-weight: 600;">
                 {{question.questionText }}
            </p>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 mt-3 text-right" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="increaseQuesNo()">
            <span> Next Qns </span> <i class="fas fa-arrow-circle-right" style="font-size: 31px;"></i>
        </div>
        <div class="col-md-12 text-center mt-3" style="margin-bottom: -15px;">
            <p style="color:white;font-size:15px;font-weight: 700;">
                QUESTION CATEGORY : {{question.questionCategory | titlecase }}
           </p>
        </div>
    </div>   
    <div class="row text-center mt-4" *ngIf="i==questionCount">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <h5 class="text-left">All Options <span style="color: #4A9F37;">( {{question.scaleName | titlecase}} )</span></h5>
            <div *ngFor="let option of question.options;let j = index;">
                <p class="shadow p-3 mb-3 bg-white rounded" >{{option.text}}</p>
            </div>
            
        </div>
        <div class="col-md-4 text-lg-right mt-lg-4">
            <div class="row">
                <div class="col-md-12">
                    <button (click)="editQuestion(question,i)" class="btn" style="border-radius: 8px;background-color: #4A9F37;color: white;">
                        <i class="fas fa-pen"></i> Edit Question
                    </button>
                </div>
            </div>
            <!-- <div class="row mt-4" *ngIf="i==(testDetails.length-1)"> -->
            <div class="row mt-4" >
                <div class="col-md-12">
                    <button (click)="addNewTestQuestions(question,testDetails.length-1)" class="btn" style="border-radius: 8px;background-color: rgb(60, 154, 241);color: white;">
                        <i class="fas fa-plus"></i> Add Question
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>
