import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-show-question-category',
  templateUrl: './show-question-category.component.html',
  styleUrls: ['./show-question-category.component.css']
})
export class ShowQuestionCategoryComponent implements OnInit {

  questionCateDetails: any;
  _id: any;

  constructor(
    private testsService: TestsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe((params) => {
      this._id = params.categoryId;
      this.testsService.getAllQuestionCategories({}).subscribe((res: any) => {
        this.spinner.hide();
        const index = res.questionCategoryData.findIndex(i=>i._id==this._id);
        this.questionCateDetails = res.questionCategoryData[index];
      })
    });
  }

}
