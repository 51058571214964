<div class="container">
    <hr>
    <div class="row">
        <div class="col-md-5">
            <label for="">No. Of Questions : </label>
        </div>
        <div class="col-md-7">
            <input type="text" class="form-control" name="noOfQuestions" [(ngModel)]="noOfQuestions" placeholder="Enter no. of Questions">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12 text-center">
            <button type="button" (click)="addNewQuestions()" class="btn">Continue <i class="fas fa-arrow-alt-circle-right"></i></button>
        </div>
    </div>
    <hr>
</div>