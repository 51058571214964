import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';
import { CreateNewQuescategoryComponent } from '../create-new-quescategory/create-new-quescategory.component';
import { CreateNewScaleComponent } from '../create-new-scale/create-new-scale.component';

@Component({
  selector: 'app-add-test-questions',
  templateUrl: './add-test-questions.component.html',
  styleUrls: ['./add-test-questions.component.css']
})
export class AddTestQuestionsComponent implements OnInit {

  categoryId;
  categoryName;
  subCategoryId;
  subCategoryName;

  totalQuestions;
  allQuestions = [];
  showText = [];

  allowSubmit = false;
  questionCount = 0;

  allQuesCategories = [];
  defaultQuesCategory = "0";

  allOptionScales = [];
  defaultOptionScale = "0";
  scaleId = "";
  scaleTitle = "";

  submitQuesCount = 0;
  defaultQuesType = "0";

  constructor(public matDialog:MatDialog,private toast:ToastrService,private testsService:TestsService,private spinner:NgxSpinnerService,private routerBtn:Router)
  {
    this.testsService.listen().subscribe((m:any)=>{
      console.log(m);
      if(m=="scale")
      {
        this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
          //Instead of resetting entire array , concatenation of last added item is done to prevent existing state(selected quescategory from form may not get cleared up)
          // this.allOptionScales = this.allOptionScales.concat(resScale["ScaleData"][resScale["ScaleData"].length-1]);
          //Concatenation of first element, as now data is coming in reverse order
          this.allOptionScales = this.allOptionScales.concat(resScale["ScaleData"][0]);

          // console.log(this.allOptionScales);
        });
      }
      else if(m=="quesCategory")
      {
        this.testsService.getAllQuestionCategories({subCategory_id: this.subCategoryId}).subscribe(res=>{
          //Instead of resetting entire array , concatenation of last added item is done to prevent existing state(selected quescategory from form may not get cleared up)
          // this.allQuesCategories = this.allQuesCategories.concat(res["questionCategoryData"][res["questionCategoryData"].length-1]);
          //Concatenation of first element, as now data is coming in reverse order
          this.allQuesCategories = this.allQuesCategories.concat(res["questionCategoryData"][0]);
         
          // console.log(this.allQuesCategories);
        });
        // this.allQuesCategories = this.allQuesCategories.concat({});
      }
      else{
        console.log("All");
        this.ngOnInit();
      }

    });
  }

  ngOnInit(): void {

     //Get all question categories
    this.testsService.getAllQuestionCategories({subCategory_id: JSON.parse(sessionStorage.getItem('newQuesData')).subCategory_id}).subscribe(res=>{
      this.allQuesCategories = res["questionCategoryData"];
      console.log(this.allQuesCategories);
      //Get all option scales
      this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
        this.allOptionScales = resScale["ScaleData"];
        // console.log(this.allOptionScales);
      });
    });

    if(sessionStorage.getItem('testDetailsAdmin')!=null)
    {
      console.log(JSON.parse(sessionStorage.getItem('testDetailsAdmin')).noOfQuestions);
      this.totalQuestions = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).noOfQuestions;

      this.categoryId = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).category_id;
      this.categoryName = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).category;
      this.subCategoryId = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).subCategory_id;
      this.subCategoryName = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).subCategory;

      for(var i = 0; i<this.totalQuestions;i++)
      {
        this.allQuestions.push({id:"",category:this.categoryName,category_id:this.categoryId,subCategory:this.subCategoryName,
                                subCategory_id:this.subCategoryId,questionNumber:(i+1),questionText:"",
                                questionCategoryId:"",questionCategory:"",options:"0",multipleAnswers:"",
                                scaleName:"",questionType:"0",questionCatObj:"0"});

        this.showText.push("Save Question "+(i+1));
      }
    }
  }

  onSelectedScale(value)
  {
    value = value.split(":")[0];
    // console.log("Scale : ",this.allOptionScales[value-1]);
    this.scaleId = this.allOptionScales[value-1].scaleId;
    this.scaleTitle = this.allOptionScales[value-1].title;
  }

  addQuestion(form:NgForm,index)
  {
    console.log(form.value,index,this.allQuestions);
    // if(this.allQuestions[index].questionText.length==0)
    // {
    //   this.toast.success("Question Saved Successfully");
    // }
    // else{
    //   this.toast.success("Question Updated Successfully");
    // }
   
    this.allQuestions[index].questionText = form.value.questionText;
    this.allQuestions[index].questionCategoryId = form.value.questionCategory.questionCategoryId;
    this.allQuestions[index].questionCategory = form.value.questionCategory.questionCategory;
    this.allQuestions[index].options = form.value.options;
    this.allQuestions[index].multipleAnswers = false;
    this.allQuestions[index].scaleId = this.scaleId;
    this.allQuestions[index].scaleName = this.scaleTitle;
    this.allQuestions[index].questionType = form.value.questionType;
    console.log(this.allQuestions[index]);

    //Set same scale for next question
    if((index+1)!=this.totalQuestions)
    {
      this.allQuestions[index+1].scaleId = this.scaleId;
      this.allQuestions[index+1].scaleName = this.scaleTitle;
      this.allQuestions[index+1].options = form.value.options;
    }

    //Default Question Category
    const indexCat = this.allQuesCategories.findIndex(i=>i.questionCategoryId==this.allQuestions[index].questionCategoryId);
    if(indexCat!=-1)
    {
      this.allQuestions[index].questionCatObj = this.allQuesCategories[indexCat];
      // console.log("Question Category Object : ",this.allQuestions[index].questionCatObj);
      //moving this question category to first position
      var element = this.allQuesCategories[indexCat];
      this.allQuesCategories.splice(indexCat,1);
      this.allQuesCategories.splice(0,0,element);
    }

    //Check if we need to save or update the question
    var btnName = document.getElementById("saveBtn").innerText;
    // console.log(btnName.trim().toLocaleLowerCase());
    if(btnName.trim().toLocaleLowerCase().startsWith("save"))
    {
      if(this.allQuestions[index].questionCategoryId==undefined)
      {
        this.toast.error("Select Question Category");
      }

      if(this.allQuestions[index].scaleId=="")
      {
        this.toast.error("Select Scale");
      }

      if(this.allQuestions[index].questionCategoryId!=undefined && this.allQuestions[index].scaleId!="")
      {
        console.log("Save Question");
        this.testsService.createNewQuestion(this.allQuestions[index]).subscribe(res=>{
          if(res["status"])
          {
            this.spinner.hide();
    
            this.showText[index] = "Update";     
            this.allQuestions[index].id = res["data"]._id;

             //Moving to next question
             if((this.questionCount + 1)!=this.totalQuestions)
             {
               this.questionCount = this.questionCount + 1;
             }

            this.submitQuesCount +=1;
            if(this.submitQuesCount==this.allQuestions.length)
            {
              this.allowSubmit = true;
            }
    
            this.toast.success(res["message"]);
          }
          else{
            this.spinner.hide();
            this.toast.error(res["message"]);
          }
         },err=>{      
          this.spinner.hide();
          this.toast.error(err);
        });
      }
     
    }
    else{
      console.log("Update Question");
      console.log(this.allQuestions[index]);
      this.testsService.updateQuestion(this.allQuestions[index]).subscribe(res=>{
        if(res["status"])
        {
          this.spinner.hide();
          
           //Moving to next question
           if((this.questionCount + 1)!=this.totalQuestions)
           {
             this.questionCount = this.questionCount + 1;
           }

          this.toast.success(res["description"]);
        }
        else{
          this.spinner.hide();
          this.toast.error("Error Occured");
        }
       },err=>{
        this.spinner.hide();
        this.toast.error(err);
      });
    }
       
  }

  submitTest()
  {   
    this.routerBtn.navigate(['/admin/all-subcategories/'+this.categoryId]);       
    sessionStorage.removeItem("testDetailsAdmin");
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.totalQuestions)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

  addNewQuestionCategory()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-new-quesCategory-component';
    dialogConfig.height = "480px";
    dialogConfig.width = "590px";
    //passing data
    dialogConfig.data = {categoryId:this.categoryId,categoryName:this.categoryName,
                        subCategoryId:this.subCategoryId,subCategoryName:this.subCategoryName};

    const modalDialog = this.matDialog.open(CreateNewQuescategoryComponent,dialogConfig);
  }

  addNewScale()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-new-scale-component';
    dialogConfig.height = "360px";
    dialogConfig.width = "470px";
    //passing data
    dialogConfig.data = {categoryId:this.categoryId,categoryName:this.categoryName,
                        subCategoryId:this.subCategoryId,subCategoryName:this.subCategoryName};

    const modalDialog = this.matDialog.open(CreateNewScaleComponent,dialogConfig);
  }

}
