import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import { TestsService } from '../services/tests.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-scale',
  templateUrl: './edit-scale.component.html',
  styleUrls: ['./edit-scale.component.css']
})
export class EditScaleComponent implements OnInit {

  signupForm:FormGroup;
  scaleDetails;

  scaleOptions = [];

  constructor(private _fb: FormBuilder,private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<EditScaleComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.scaleDetails = data.scaleData;
    console.log(this.scaleDetails);
  }

  ngOnInit()
  {
    this.signupForm = new FormGroup({
      'title' : new FormControl(null, [Validators.required]),
      'options':new FormArray([]),
      'reverse' : new FormControl('', [Validators.required]),
    });
    
    this.scaleDetails.options.forEach(singleScale=>{
      this.scaleOptions.push(singleScale.text);
    });
    //Setting initial value for the form
    this.signupForm = this._fb.group({
      title:this.scaleDetails.title,
      options: this._fb.array(this.scaleOptions),
      reverse:(this.scaleDetails.reverse!=undefined)?this.scaleDetails.reverse.toString():'false'
    });
  }

  onSubmit()
  {
    console.log(this.signupForm.value);

    for(var i=0;i<this.scaleDetails.options.length;i++)
    {
      this.scaleDetails.options[i].text = this.signupForm.value.options[i];
      console.log( this.scaleDetails.options[i].text);
    }

    var scaleData = {
      id:this.scaleDetails._id,
      title:this.signupForm.value.title,
      multipleAnswers:false,
      options:this.scaleDetails.options,
      reverse:this.signupForm.value.reverse=='true'?true:false
    };
    console.log(scaleData);
    this.testsService.updateScale(scaleData).subscribe(res=>{
      console.log(res);
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);      
        this.closeModel();
      }
      else{
        this.spinner.hide();
        this.toast.error(res["description"]);
      }
      },err=>{      
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  onAddOptions()
  {
    const control = new FormControl(null, Validators.required);
      // typecasting into FormArray
    (<FormArray>this.signupForm.get('options')).push(control);
  }

  onRemoveOptions(index)
  {
    (<FormArray>this.signupForm.get('options')).removeAt(index);
  }

  getControls() {
    return (<FormArray>this.signupForm.get('options')).controls;
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("Created");
  }

}
