<div class="wrapper mt-2">
    <div class="table2 " >                                         
      <div class="row2">
        <div class="cell2 text-center" style="box-shadow: none;">
            <h4><u>All Participated Users </u> </h4>
        </div>
        <div class="cell2 mt-2 text-center">
          <button class="btn" (click)="showOnlyPendingCommentUsers()" type="button" [ngClass]="{selectedClass: showAdminTopics==0,nonSelectedClass:showAdminTopics==1}">Pending Comments</button>
          <button class="btn" (click)="showOnlyAddedCommentUsers()" type="button" [ngClass]="{selectedClass: showAdminTopics==1,nonSelectedClass:showAdminTopics==0}">Added Comments</button>
       </div>   
        <div class="cell2 myCell pl-lg-2 mt-2" >
          <div class="form-group">
            <span class="fa fa-search form-control-icon"></span>
            <input type="text" class="form-control" [(ngModel)]="filteredStatus" placeholder="Search User">   
          </div>
        </div>
      </div>
    </div> 

    <div class="table shadow p-3 mb-5" *ngIf="allParticipatedUsers.length!=0">
      
      <div class="row header" >
        
        <div class="cell">
          S.No
        </div>    
        <div class="cell">
          User Name
        </div>  
        <div class="cell">
          User Email
        </div>
        <div class="cell">
          Submission Date
        </div>
        <div class="cell">
          Responses
        </div>
      </div>
      
      <div class="row" *ngFor="let user of allParticipatedUsers | nameFilter : filteredStatus | paginate :{itemsPerPage:8,currentPage:p};let i = index;">
      
        <div class="cell" data-title="S.No.">
            {{i+1}}
        </div>
        <div class="cell" data-title="User Name">
            {{ user.name[0] | titlecase }}
        </div>
        <div class="cell" data-title="User Email">
            {{ (user.user_email[0].length==0)?'NA':user.user_email[0] }}
        </div>
        <div class="cell" data-title="Submission Date">
            {{ user.date | date:'mediumDate'}}
        </div>
        <div class="cell" data-title="Test" style="cursor:pointer;color:darkblue" (click)="showResponses(user.uid)" >
            View &nbsp; <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px;"></i>
        </div>
             
      </div>          
    </div>

    <div class="text-center mt-5" *ngIf="allParticipatedUsers.length==0">
      <h5 style="color: darkblue;">**No User Exists**</h5>
    </div>
    <!-- pagination -->
    <pagination-controls (pageChange)="p=$event" *ngIf="allParticipatedUsers.length!=0"></pagination-controls>
 </div> 

  
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>
