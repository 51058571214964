<div class="wrapper mt-2">
  <div class="table2 ">
    <div class="row2">
      <div class="cell2 myCell pl-lg-2 mt-2">
        <div class="form-group">
          <span class="fa fa-search form-control-icon"></span>
          <input type="text" class="form-control" [(ngModel)]="filteredStatus" placeholder="Search Dimension"/>
        </div>
      </div>

      <div class="cell2 myCell pl-lg-2 mt-2 text-right">
        <h4><u>All Test Dimensions </u> </h4>
      </div>

      <div class="cell2 myCell pl-lg-2 mt-2 text-right">
        <button (click)="addNewQuesCategory()" style="background-color: #4A9F37;border-radius: 8px;color: white;"
          class="btn mb-1"><i class="fas fa-plus"></i> Add Dimension</button>
      </div>
    </div>
  </div>

  <div class="table shadow p-3 mb-5" *ngIf="allQuesCategories.length!=0">

    <div class="row header">

      <div class="cell">
        S.No
      </div>
      <div class="cell">
        Image
      </div>
      <div class="cell">
        Dimension Name
      </div>
      <div class="cell">
        Associated Category
      </div>
      <div class="cell">
        Associated Tests
      </div>
      <div class="cell">
        Description of Dimension
      </div>
      <div class="cell">

      </div>
      <div class="cell">

      </div>
      <div class="cell">

      </div>
    </div>

    <div class="row"
      *ngFor="let qCat of allQuesCategories | nameFilter: filteredStatus | paginate :{itemsPerPage:8,currentPage:p};let i = index;">

      <div class="cell" data-title="S.No.">
        {{i+1}}
      </div>
      <div class="cell" data-title="Image">
        <div class="image-upload" (click)="onSelectRow(qCat._id, i)">
          <label for="file-input">
            <img [src]="environment.baseURL + 'app/read-file' + qCat.url"
              onerror="this.src='./../../../assets/profile.png'" height="36px" width="36px" alt=""
              style="border-radius: 50%;">
          </label>
        </div>
        <input id="file-input" type="file" #fileInput hidden (change)="selectImage($event)" />
      </div>
      <div class="cell" data-title="Dimension Name">
        {{ qCat.questionCategory | titlecase}}
      </div>
      <div class="cell" data-title="Associated Category">
        {{ qCat.category | titlecase}}
      </div>
      <div class="cell" data-title="Associated Tests">
        {{ qCat.subCategory | titlecase}}
      </div>
      <div class="cell" data-title="Description of Dimension">
        {{ qCat.description }}
      </div>
      <div class="cell" (click)="viewQuesCategory(qCat)">
        <a routerLink="/admin/question-category/{{qCat._id}}/view">
          <i class="fas fa-external-link-alt" aria-hidden="true"
            style="font-size: 17px;color:rgb(16, 107, 243);cursor: pointer;"></i>
        </a>
      </div>
      <div class="cell" (click)="editQuesCategory(qCat)">
        <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;color:darkgreen;cursor: pointer;"></i>
      </div>
      <div class="cell" style="cursor:pointer;">
        <i class="fas fa-trash" aria-hidden="true" style="font-size: 17px;color:rgb(192, 31, 31);cursor: pointer;"></i>
      </div>

    </div>
  </div>

  <div class="text-center mt-5" style="margin-left: -40px;" *ngIf="allQuesCategories.length==0">
    <h5 style="color: darkblue;">**No Test Dimension Exists**</h5>
  </div>
  <!-- pagination -->
  <pagination-controls (pageChange)="p=$event" *ngIf="allQuesCategories.length!=0"></pagination-controls>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>