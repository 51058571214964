import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CreateNewQuesCategoryDialogComponent } from '../create-new-ques-category-dialog/create-new-ques-category-dialog.component';
import { EditQuescategoryComponent } from '../edit-quescategory/edit-quescategory.component';
import { TestsService } from '../services/tests.service';
import { ViewQuesCategoryComponent } from '../view-ques-category/view-ques-category.component';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-show-quescategories',
  templateUrl: './show-quescategories.component.html',
  styleUrls: ['./show-quescategories.component.css'],
})
export class ShowQuescategoriesComponent implements OnInit {
  allQuesCategories = [];
  filteredStatus= "";
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  selectedRowId: any;
  selectedRowIdx: any;
  environment = environment;

  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    private testsService: TestsService,
    private routerBtn: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    this.testsService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.testsService.getAllQuestionCategories({}).subscribe((res) => {
      this.allQuesCategories = res['questionCategoryData'];
      console.log(this.allQuesCategories);
      this.spinner.hide();
    });
  }

  addNewQuesCategory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-new-quesCategory-dialog-component';
    dialogConfig.height = '640px';
    dialogConfig.width = '920px';
    //passing data
    dialogConfig.data = {};

    const modalDialog = this.matDialog.open(
      CreateNewQuesCategoryDialogComponent,
      dialogConfig
    );
  }

  viewQuesCategory(quesCategoryData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-quescategory-component';
    dialogConfig.height = '530px';
    dialogConfig.width = '640px';
    //passing data
    dialogConfig.data = { quesCategoryData: quesCategoryData };

    const modalDialog = this.matDialog.open(
      ViewQuesCategoryComponent,
      dialogConfig
    );
  }

  editQuesCategory(quesCategoryData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-quescategory-component';
    dialogConfig.height = '540px';
    dialogConfig.width = '920px';
    //passing data
    dialogConfig.data = { quesCategoryData: quesCategoryData };

    const modalDialog = this.matDialog.open(
      EditQuescategoryComponent,
      dialogConfig
    );
  }

  onSelectRow(categoryId, idx) {
    this.selectedRowId = categoryId;
    this.selectedRowIdx = idx;
  }

  selectImage(e) {
    const file = e.target.files[0];
    this.spinner.show();
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('id', this.selectedRowId);

    this.testsService.updateImage(formData).subscribe((res: any) => {
      if (res) {
        e.target.value = null;
        this.spinner.hide();
        this.toast.success(res.description);
        this.allQuesCategories.map((cate) => {
          if (cate._id === this.selectedRowId) {
            cate.url = res.file_path;
          }
        });
      }
    });
  }
}
