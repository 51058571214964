<div class="container">
    <div class="row">
        <div class="col-md-12">
            <img [src]="aboutImage" onerror="this.src='../../assets/personalityTest.jpg'" width="100%" height="280px" alt="">
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-1"></div>
        <div class="col-md-4">
            <label for="" style="font-weight: 500;">Change Image : </label>
        </div>
        <div class="col-md-7">
            <input id="file-input" type="file" (change)="selectImage($event)" />
        </div>
    </div>
</div>
