import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';
import {Location} from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { CreateScaleMultipleComponent } from '../create-scale-multiple/create-scale-multiple.component';
import { ShowSubquestionScaleoptionsComponent } from '../show-subquestion-scaleoptions/show-subquestion-scaleoptions.component';

@Component({
  selector: 'app-add-subquestion',
  templateUrl: './add-subquestion.component.html',
  styleUrls: ['./add-subquestion.component.css']
})
export class AddSubquestionComponent implements OnInit {

  totalQuestions;
  allSubQuestions = [];

  questionNo;

  showText = [];

  allowSubmit = false;
  questionCount = 0;

  allOptionScales = [];
  defaultOptionScale = "0";
  scaleId = "";
  scaleTitle = "";
  scaleData = {title:"",options:[]};

  submitQuesCount = 0;
  defaultQuesType = "0";

  defaultResponseType = "-1";


  constructor(public matDialog:MatDialog,private route:ActivatedRoute,private _location: Location,private toast:ToastrService,private testsService:TestsService,private spinner:NgxSpinnerService,private routerBtn:Router)
  {
    this.route.params.subscribe((Params:HttpParams)=>{
      this.questionNo = Params['questionNumber'];
    })
    this.testsService.listen().subscribe((m:any)=>{
      // console.log(m);
      if(m.type!=undefined)
      {
        if(m.type=="multiQuesScale")
        {
          this.scaleData = m.data;

          this.allSubQuestions[m.index].scaleName = m.data.title;
          this.allSubQuestions[m.index].options = m.data.options;
          this.allSubQuestions[m.index].reverse = m.data.reverse;
          
          // console.log("Scale Data : ",this.scaleData);
        }
        else if(m.type=="prevSubScales")
        {
          this.allSubQuestions[m.index].answers = m.data;
        }
      }
    });
    this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
      this.allOptionScales = resScale["ScaleData"];
      // console.log(this.allOptionScales);
    });
  }

  ngOnInit(): void {

    if(sessionStorage.getItem('allSubQuestionsData')!=null)
    {
      // console.log(sessionStorage.getItem('allSubQuestionsData'));
      const subIndex = JSON.parse(sessionStorage.getItem('allSubQuestionsData')).findIndex(j=>+j.questionNo==this.questionNo);
      if(subIndex!=-1)
      {
        this.allSubQuestions = JSON.parse(sessionStorage.getItem('allSubQuestionsData'))[subIndex].subQuestions;
        console.log(this.allSubQuestions);
        this.allSubQuestions.forEach(s=>{
          s.isScale = s.isScale.toString();
          s.userEntry = s.userEntry.toString();
        });
      }
      else{ 
        this.allSubQuestions.push({id:"",subQuesNo:1,subQuestion:"",linkedWith:"-1",linkSubQuesNo:"",limitManualEntry:"",number:"",userEntry:"",isScale:"",scaleName:"",scaleId:"",options:[],showManualEntryLimit:false,showLinkSubQuesNo:false,answers:[]});
      }
    }
    else{
      this.allSubQuestions.push({id:"",subQuesNo:1,subQuestion:"",linkedWith:"-1",linkSubQuesNo:"",limitManualEntry:"",number:"",userEntry:"",isScale:"",scaleName:"",scaleId:"",options:[],showManualEntryLimit:false,showLinkSubQuesNo:false,answers:[]});
    }
       
  }

  handleRadioChangePositive(eventInfo,index)
  {
    console.log(eventInfo.target.checked);
    this.allSubQuestions[index].isScale = "1";
  }

  handleRadioChangeNegative(eventInfo,index)
  {
    console.log(eventInfo.target.checked);
    this.allSubQuestions[index].isScale = "0";
  }

  addQuestion(form:NgForm,index)
  {
    // console.log(form.value,index);

    this.allSubQuestions[index].subQuestion = form.value.subQuestion;
    this.allSubQuestions[index].linkedWith = form.value.linkedWith;
    if(form.value.linkedWith=="RESPONSE_SUB_QUESTION"||form.value.linkedWith=="ALL_RESPONSE_SUB_QUESTION")
    {
      this.allSubQuestions[index].linkSubQuesNo = form.value.linkSubQuesNo;
      this.allSubQuestions[index].limitManualEntry = "";
      this.allSubQuestions[index].number = form.value.linkSubQuesNo;
    }

    if(form.value.linkedWith=="MANUAL_ENTRY")
    {
      this.allSubQuestions[index].limitManualEntry = form.value.limitManualEntry;
      this.allSubQuestions[index].linkSubQuesNo = "";
      this.allSubQuestions[index].number = form.value.limitManualEntry;
    }
    
    this.allSubQuestions[index].userEntry = form.value.userEntry;
    this.allSubQuestions[index].isScale = form.value.isScale;
    if(+this.allSubQuestions[index].isScale==0)
    {
      this.allSubQuestions[index].isScale = "0";
      this.allSubQuestions[index].scaleId = "";
      this.allSubQuestions[index].scaleName = "";
      this.allSubQuestions[index].options = [];
    }
    else{      
      this.allSubQuestions[index].isScale = "1";
      // this.allSubQuestions[index].scaleId = form.value.scaleId;
      // const scaleIndex = this.allOptionScales.findIndex(i=>i.scaleId == form.value.scaleId);
      // if(scaleIndex!=-1)
      // {
      //   this.allSubQuestions[index].scaleName = this.allOptionScales[scaleIndex].title;
      // }
      this.allSubQuestions[index].scaleId = "";
      // this.allSubQuestions[index].scaleName = this.scaleData.title;    //Filling both of these from ngOnInit
      // this.allSubQuestions[index].options = this.scaleData.options;

    }
    console.log(this.allSubQuestions[index]);
    this.toast.success("","SubQuestion Saved Successfully");
    // //Check if we need to save or update the question
    // var btnName = document.getElementById("saveBtn").innerText;
    // // console.log(btnName.trim().toLocaleLowerCase());
    // if(btnName.trim().toLocaleLowerCase().startsWith("save"))
    // {
    //   if(this.allSubQuestions[index].questionCategoryId==undefined)
    //   {
    //     this.toast.error("Select Question Category");
    //   }

    //   if(this.allSubQuestions[index].scaleId=="")
    //   {
    //     this.toast.error("Select Scale");
    //   }

    //   if(this.allSubQuestions[index].questionCategoryId!=undefined && this.allSubQuestions[index].scaleId!="")
    //   {
    //     console.log("Save Question");
    //     this.testsService.createNewQuestion(this.allSubQuestions[index]).subscribe(res=>{
    //       if(res["status"])
    //       {
    //         this.spinner.hide();
    
    //         this.showText[index] = "Update";     
    //         this.allSubQuestions[index].id = res["data"]._id;

    //          //Moving to next question
    //          if((this.questionCount + 1)!=this.totalQuestions)
    //          {
    //            this.questionCount = this.questionCount + 1;
    //          }

    //         this.submitQuesCount +=1;
    //         if(this.submitQuesCount==this.allSubQuestions.length)
    //         {
    //           this.allowSubmit = true;
    //         }
    
    //         this.toast.success(res["message"]);
    //       }
    //       else{
    //         this.spinner.hide();
    //         this.toast.error(res["message"]);
    //       }
    //      },err=>{      
    //       this.spinner.hide();
    //       this.toast.error(err);
    //     });
    //   }
     
    // }
    // else{
    //   console.log("Update Question");
    //   console.log(this.allSubQuestions[index]);
    //   this.testsService.updateQuestion(this.allSubQuestions[index]).subscribe(res=>{
    //     if(res["status"])
    //     {
    //       this.spinner.hide();
          
    //        //Moving to next question
    //        if((this.questionCount + 1)!=this.totalQuestions)
    //        {
    //          this.questionCount = this.questionCount + 1;
    //        }

    //       this.toast.success(res["description"]);
    //     }
    //     else{
    //       this.spinner.hide();
    //       this.toast.error("Error Occured");
    //     }
    //    },err=>{
    //     this.spinner.hide();
    //     this.toast.error(err);
    //   });
    // }
       
  }

  returnToMainQuestion()
  { 
    console.log(this.allSubQuestions);
    if(sessionStorage.getItem('allSubQuestionsData')!=null)
    {
      let allSubData = JSON.parse(sessionStorage.getItem('allSubQuestionsData'));
      const index = allSubData.findIndex(i=>+i.questionNo==+this.questionNo);
      if(index!=-1)
      {
        allSubData[index].subQuestions = this.allSubQuestions;
      }
      else{
        allSubData = allSubData.concat({questionNo:this.questionNo,subQuestions:this.allSubQuestions});
      }
      sessionStorage.setItem('allSubQuestionsData',JSON.stringify(allSubData));
      console.log(allSubData);
    }
    else{
      let allSubData = [{questionNo:this.questionNo,subQuestions:this.allSubQuestions}];
      sessionStorage.setItem('allSubQuestionsData',JSON.stringify(allSubData));
    }
    this.routerBtn.navigate(['/admin/add-test-questions-multiple/']);
  }

  addSubQuestions()
  {
    this.allSubQuestions.push({id:"",subQuesNo:this.allSubQuestions[this.allSubQuestions.length-1].subQuesNo+1,
                      subQuestion:"",linkedWith:"-1",linkSubQuesNo:"",limitManualEntry:"",number:"",userEntry:"",isScale:"",scaleName:"",scaleId:"",options:[],
                      showManualEntryLimit:false,showLinkSubQuesNo:false,answers:[]});

    this.questionCount = this.questionCount + 1;

    console.log(this.allSubQuestions.length);
  }

  onSelectedLink(value,index)
  {
    // console.log(value);
    if(value=="RESPONSE_SUB_QUESTION" || value=="ALL_RESPONSE_SUB_QUESTION")
    {
      this.allSubQuestions[index].showLinkSubQuesNo = true;
      this.allSubQuestions[index].showManualEntryLimit = false;
    }
    else if(value=="MANUAL_ENTRY")
    {
      this.allSubQuestions[index].showManualEntryLimit = true;
      this.allSubQuestions[index].showLinkSubQuesNo = false;
    }
    else{
      this.allSubQuestions[index].showLinkSubQuesNo = false;
      this.allSubQuestions[index].showManualEntryLimit = false;
      if(value=="RESPONSE_SUB_QUESTION_WITH_SCALE")
      {
        // console.log("Index : ",index);
        if(index==0)
        {
          this.toast.error("No Previous SubQuestion exists","");
        }
        else{
          this.choosePreviousSubquesOptions(index,this.allSubQuestions);
        }
      }
    }
  }

  addNewScale(index)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-scale-multiple-component';
    dialogConfig.height = "360px";
    dialogConfig.width = "470px";
    //passing data
    dialogConfig.data = {index:index};

    const modalDialog = this.matDialog.open(CreateScaleMultipleComponent,dialogConfig);
  }

  choosePreviousSubquesOptions(index,previousSubquestions)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'show-subquestion-scale-options-component';
    dialogConfig.height = "340px";
    dialogConfig.width = "630px";
    //passing data
    dialogConfig.data = {index:index,previousSubquestions:previousSubquestions,existingAnswers:this.allSubQuestions[index].answers};

    const modalDialog = this.matDialog.open(ShowSubquestionScaleoptionsComponent,dialogConfig);
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.allSubQuestions.length)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

}
