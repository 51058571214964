<div class="container">
    <hr>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-5">
            <label for="username">Title : </label>
        </div>
        <div class="col-md-7">
            <input
            type="text"
            id="title"
            formControlName="title"
            class="form-control"
            placeholder="Enter Title">

            <span   
            *ngIf="!signupForm.get('title').valid && signupForm.get('title').touched"
            class="help-block">
            <span *ngIf="signupForm.get('title').errors['required']" class="errorDiv">Title is required!</span>
          </span>
        </div>
    </div>
    
    <!-- Now, here we want the user to dynamically add the formControls -->
    <div class="row mt-3" formArrayName="options">
        <div class="col-md-5">
            <label for="username">Options : </label>
        </div>
        <div class="col-md-7">
            <div class="row mt-1" *ngFor="let hobbyControl of getControls(); let i = index;">
                <div class="col-md-10">
                    <!-- <div class="form-group" *ngFor="let hobbyControl of getControls(); let i = index;"> -->
                        <input type="text" class="form-control" [formControlName]="i">
                      <!-- </div> -->
                </div>
                <div class="col-md-2">
                    <button class="btn btn-danger rounded-circle" style="border-radius: 8px;color: white;font-weight: 480;" type="button" (click)="onRemoveOptions(i)">X</button>
                </div>
            </div>
            <button class="btn btn-warning mt-1" style="border-radius: 8px;color: white;font-weight: 480;" type="button" (click)="onAddOptions()">Add Option</button>         
          </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-5">
            <label for="username">Reverse : </label>
        </div>
        <div class="col-md-7">
            <div class="row">
                <div class="col-md-6">
                    <input type="radio" value='true' formControlName="reverse"> True
                </div>
                <div class="col-md-6">
                    <input type="radio" value='false' formControlName="reverse"> False
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <button class="btn btn-success btn-block" [disabled]="!signupForm.valid" type="submit">Submit</button>
        </div>  
    </div>
    </form>
    <hr>
</div>
