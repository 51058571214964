import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CreateFaqComponent } from '../create-faq/create-faq.component';
import { EditFaqComponent } from '../edit-faq/edit-faq.component';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-all-faq',
  templateUrl: './all-faq.component.html',
  styleUrls: ['./all-faq.component.css'],
})
export class AllFaqComponent implements OnInit {
  allFaqs = [];

  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    this.contentDataService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.contentDataService.getAllFaqs().subscribe((res) => {
      this.allFaqs = res['FaqData'];
      console.log(this.allFaqs);
      this.spinner.hide();
    });
  }

  addNewFAQ() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-faq-dialog-component';
    dialogConfig.height = '340px';
    dialogConfig.width = '470px';
    //passing data
    dialogConfig.data = {};

    const modalDialog = this.matDialog.open(CreateFaqComponent, dialogConfig);
  }

  editFaq(faqData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-faq-component';
    dialogConfig.height = '340px';
    dialogConfig.width = '470px';
    //passing data
    dialogConfig.data = { faqData: faqData };

    const modalDialog = this.matDialog.open(EditFaqComponent, dialogConfig);
  }
}
