import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { EditAdminPasswordComponent } from '../edit-admin-password/edit-admin-password.component';
import { EditAdminComponent } from '../edit-admin/edit-admin.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  firstName = '';
  lastName = '';
  email = '';
  adminId;
  userImage;

  uploadText;

  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) {
    this.authService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.uploadText = 'Loading Profile';
    this.spinner.show();

    this.adminId = JSON.parse(localStorage.getItem('authUserData'))._id;
    // console.log(this.adminId);
    this.firstName = JSON.parse(localStorage.getItem('authUserData')).firstName;
    this.lastName = JSON.parse(localStorage.getItem('authUserData')).lastName;
    this.email = JSON.parse(localStorage.getItem('authUserData')).email;
    this.userImage =
      environment.baseURL +
      'app/download' +
      JSON.parse(localStorage.getItem('authUserData')).adminImage;
    this.spinner.hide();
  }

  selectImage(event) {
    this.uploadText = 'Saving Image';

    const file = event.target.files[0];
    this.userImage = file;
    const adminDetails = JSON.parse(localStorage.getItem('authUserData'));

    this.spinner.show();

    const formData = new FormData();
    formData.append('photo', this.userImage);

    delete adminDetails.password;

    this.authService
      .editAdminImage(adminDetails, formData)
      .subscribe((resAdmin: any) => {
        console.log(resAdmin);
        if (resAdmin.status) {
          this.userImage =
            environment.baseURL + 'app/download' + resAdmin.data.adminImage;
          console.log(this.userImage);
          this.spinner.hide();
          this.toast.success(resAdmin.message, 'Successs', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
          localStorage.setItem('authUserData', JSON.stringify(resAdmin.data));
          this.authService.loggedIn.next(true);
        } else {
          this.spinner.hide();
          this.toast.error(resAdmin.message, 'Error', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    //if the user clicks outside the modal, it doesn’t close
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-admin-component';
    dialogConfig.height = '550px';
    dialogConfig.width = '1000px';
    //passing data
    dialogConfig.data = { adminEmail: this.email };

    const modalDialog = this.matDialog.open(EditAdminComponent, dialogConfig);
  }

  editPasswordDialog() {
    const dialogConfig = new MatDialogConfig();
    //if the user clicks outside the modal, it doesn’t close
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-admin-password-component';
    dialogConfig.height = '430px';
    dialogConfig.width = '850px';
    //passing data
    dialogConfig.data = { adminEmail: this.email };

    const modalDialog = this.matDialog.open(
      EditAdminPasswordComponent,
      dialogConfig
    );
  }
}
