import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CreateNewScaleComponent } from '../create-new-scale/create-new-scale.component';
import { EditScaleComponent } from '../edit-scale/edit-scale.component';
import { AuthService } from '../services/auth.service';
import { TestsService } from '../services/tests.service';
import { ViewScaleComponent } from '../view-scale/view-scale.component';

@Component({
  selector: 'app-show-scales',
  templateUrl: './show-scales.component.html',
  styleUrls: ['./show-scales.component.css'],
})
export class ShowScalesComponent implements OnInit {
  allOptionScales = [];

  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    private testsService: TestsService,
    private routerBtn: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    this.testsService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.testsService.getAllOptionsScales({}).subscribe((res) => {
      this.allOptionScales = res['ScaleData'];
      console.log(this.allOptionScales);
      this.spinner.hide();
    });
  }

  addNewScale() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'create-new-scale-dialog-component';
    dialogConfig.height = '360px';
    dialogConfig.width = '470px';
    //passing data
    dialogConfig.data = {};

    const modalDialog = this.matDialog.open(
      CreateNewScaleComponent,
      dialogConfig
    );
  }

  viewScale(scaleData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-scale-dialog-component';
    dialogConfig.height = '280px';
    dialogConfig.width = '390px';
    //passing data
    dialogConfig.data = { scaleData: scaleData };

    const modalDialog = this.matDialog.open(ViewScaleComponent, dialogConfig);
  }

  editScale(scaleData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-scale-dialog-component';
    dialogConfig.height = '420px';
    dialogConfig.width = '490px';
    //passing data
    dialogConfig.data = { scaleData: scaleData };

    const modalDialog = this.matDialog.open(EditScaleComponent, dialogConfig);
  }
}
