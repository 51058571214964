import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddAboutComponent } from '../add-about/add-about.component';
import { EditAboutComponent } from '../edit-about/edit-about.component';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';
import { ShowAboutImageComponent } from '../show-about-image/show-about-image.component';

@Component({
  selector: 'app-about-details',
  templateUrl: './about-details.component.html',
  styleUrls: ['./about-details.component.css'],
})
export class AboutDetailsComponent implements OnInit {
  aboutId;
  aboutContents = [];
  aboutImage = '';

  showAboutApp = true;

  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    private contentDataService: ContentdataService,
    private routerBtn: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    this.contentDataService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    if (this.showAboutApp) {
      this.showAboutAppData();
    } else {
      this.showAboutFounderData();
    }
  }

  showAboutAppData() {
    this.spinner.show();
    this.showAboutApp = true;
    this.contentDataService
      .getAllAboutList({ type: 'About Flourish Thyself' })
      .subscribe((res) => {
        res['contentData'][0].contents.forEach((a) => {
          console.log(a.description.length);
          if (a.description.length >= 30) {
            a.newDescription = a.description.substring(0, 27) + '...';
            console.log(a.newDescription);
          } else {
            a.newDescription = a.description;
          }
        });
        this.aboutContents = res['contentData'][0].contents;
        this.aboutId = res['contentData'][0]._id;
        this.aboutImage = res['contentData'][0].imageURL;
        console.log(this.aboutContents, this.aboutId);
        this.spinner.hide();
      });
  }

  showAboutFounderData() {
    this.spinner.show();
    this.showAboutApp = false;
    this.contentDataService
      .getAllAboutList({ type: 'About Paul' })
      .subscribe((res) => {
        res['contentData'][0].contents.forEach((a) => {
          console.log(a.description.length);
          if (a.description.length >= 30) {
            a.newDescription = a.description.substring(0, 27) + '...';
            console.log(a.newDescription);
          } else {
            a.newDescription = a.description;
          }
        });
        this.aboutContents = res['contentData'][0].contents;
        console.log(this.aboutContents);
        this.aboutId = res['contentData'][0]._id;
        this.aboutImage = res['contentData'][0].imageURL;
        console.log(this.aboutContents, this.aboutId);
        this.spinner.hide();
      });
  }

  showAboutImage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'show-about-image-component';
    dialogConfig.height = '410px';
    dialogConfig.width = '530px';
    //passing data
    dialogConfig.data = { aboutId: this.aboutId, aboutImage: this.aboutImage };

    const modalDialog = this.matDialog.open(
      ShowAboutImageComponent,
      dialogConfig
    );
  }

  addNewAboutContent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'add-about-component';
    dialogConfig.height = '340px';
    dialogConfig.width = '470px';
    //passing data
    dialogConfig.data = {
      allAboutContent: this.aboutContents,
      aboutId: this.aboutId,
    };

    const modalDialog = this.matDialog.open(AddAboutComponent, dialogConfig);
  }

  editAboutDetails(aboutData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-about-component';
    dialogConfig.height = '340px';
    dialogConfig.width = '470px';
    //passing data
    dialogConfig.data = {
      aboutData: aboutData,
      allAboutContent: this.aboutContents,
      aboutId: this.aboutId,
    };

    const modalDialog = this.matDialog.open(EditAboutComponent, dialogConfig);
  }
}
