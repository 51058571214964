
<div class="row mt-4">
    <div class="col-md-3 mb-3">
      <div class="card text-center o-hidden h-100" style="border-radius: 8px;background-color: #4A9F37;margin-bottom: -10px;">
        <div class="card-body">
          <p class="countStyle">{{totalUsers}}</p>
          <p class="tagStyle"><i class="fas fa-user-circle"></i> Registered Users</p>
        </div>
        
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="card text-center o-hidden h-100" style="border-radius: 8px;background-color: #4A9F37;margin-bottom: -10px;">
        <div class="card-body">
          <p class="countStyle">{{totalPremiumUsers}}</p>
          <p class="tagStyle"><i class="fas fa-users"></i> Premium Users</p>
        </div>
        
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="card text-center o-hidden h-100" style="border-radius: 8px;background-color: #4A9F37;margin-bottom: -10px;">
        <div class="card-body">
          <p class="countStyle">{{totalTopics}}</p>
          <p class="tagStyle"><i class="fas fa-file-alt"></i> Tests Added</p>
        </div>
        
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="card text-center o-hidden h-100" style="border-radius: 8px;background-color: #4A9F37;margin-bottom: -10px;">
        <div class="card-body">
          <p class="countStyle">{{totalSuggestions}}</p>
          <p class="tagStyle"><i class="fas fa-list-alt"></i> Tests given by Users</p>
        </div>
        
      </div>
    </div>
   
</div>

<div class="row mt-4">
    <div class="col-md-6">
        <canvas baseChart 
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [colors]="barChartColors"
            [chartType]="barChartType">
        </canvas>
    </div>
    <div class="col-md-6"></div>
</div>