import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TestsService } from 'src/app/services/tests.service';

@Component({
  selector: 'app-category-users',
  templateUrl: './category-users.component.html',
  styleUrls: ['./category-users.component.css']
})
export class CategoryUsersComponent implements OnInit {

  subCategoryId;  
  allParticipatedUsers = [];

  filteredStatus = '';
  p:number =1;

  showAdminTopics = 0;

  constructor(
    private routerBtn:Router,
    private route:ActivatedRoute,
    private testsService:TestsService,
    private spinner:NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(Params=>{
      this.subCategoryId = Params['subCategoryId'];
      this.showOnlyPendingCommentUsers();
    });
  }

  showOnlyPendingCommentUsers()
  { 
    this.showAdminTopics = 0;
      this.testsService.getAllCategoryUsersList({subCategory_id:this.subCategoryId,adminComment:false}).subscribe(res=>{
        if(res["status"])
        {
          this.allParticipatedUsers = res["data"];
        }
        this.spinner.hide();
      })
  }

  showOnlyAddedCommentUsers()
  { 
    this.showAdminTopics = 1;
      this.testsService.getAllCategoryUsersList({subCategory_id:this.subCategoryId,adminComment:true}).subscribe(res=>{
        if(res["status"])
        {
          this.allParticipatedUsers = res["data"];
        }
        this.spinner.hide();
      })
  }

  showResponses(userId)
  {
    this.routerBtn.navigate(['/admin/show-user-responses',this.subCategoryId,userId]);
  }

}
