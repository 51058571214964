<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
    integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">

<div class="container-fluid text-center">
    <!-- <div class="card outer-card" > -->
    <form (submit)="addTestDetails(form)" #form="ngForm">
        <div class="card inner-card-1">
            <h1 class="text-center" style="font-size: 30px;"><u>Add New Test</u></h1>

            <div>
                Test Category:
                <select class="select-dropdown" name="category" [ngModel]="defaultCategory" #mySelect
                    (change)='onSelectedCategory(mySelect.value)'>
                    <option value="0" disabled>Select Category</option>
                    <option disabled [ngValue]="categ.category" *ngFor="let categ of allCategories">{{categ.category |
                        titlecase}}</option>
                </select>
            </div>
            <div style="margin-top: -10px;">
                Name of Test:
                <input type="text" placeholder="Enter Name of Test" name="subCategory" ngModel required>

            </div>
            <div style="margin-top: -10px;">
                Curios Question:
                <input type="text" placeholder="Enter Curios Question" name="title" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Sub-Category Image:
                <input type="file" (change)="selectImage($event)" placeholder="Enter Sub-Category Image" name="photo"
                    ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Report type of results:
                <select class="select-dropdown" name="graph_type" [ngModel]="defaultGraphType" required>
                    <option value="-1" disabled>Select Report Type</option>
                    <option value="1">Ranked traits</option>
                    <option value="2">Trait opposites</option>
                    <option value="3">Unranked lists</option>
                </select>
            </div>
            <div style="margin-top: -10px;">
                Multiple:
                <select class="select-dropdown" name="isMultiple" [ngModel]="defaultMultipleValue" required>
                    <option value="-1" disabled>Select Value</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
            <div style="margin-top: -10px;">
                Number of Qns:
                <input type="number" placeholder="Enter no.of questions" name="noOfQuestions" ngModel min="1"
                    oninput="validity.valid||(value='');" required>
            </div>
            <div style="margin-top: -10px;">
                Test Time:
                <input type="text" placeholder="Enter Test Time" name="time" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Test Diamonds:
                <input type="text" placeholder="Enter Test Diamonds" name="points" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Test Coins:
                <input type="text" placeholder="Enter Test Coins" name="coins" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Unlock Coins:
                <input type="text" placeholder="Enter Unlock Coins" name="unlockCoins" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Unlock Diamonds:
                <input type="text" placeholder="Enter Unlock Diamonds" name="unlockDiamonds" ngModel required>
            </div>
            <div style="margin-top: -10px;">
                Price:
                <input type="text" placeholder="Enter Price" name="price" ngModel required>
            </div>
            <div style="margin-top: -10px; display: unset;">
                <div class="row text-left">
                    <div class="col-md-12 mt-2">
                        Special Test:
                    </div>
                    <div class="col-md-12 mt-2">
                        <mat-slide-toggle style="padding-top: 5px;" ngModel name="specialTest"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div style="margin-top: -10px; display: unset;">
                <div class="row text-left">
                    <div class="col-md-12 mt-2">
                        Source:
                    </div>
                    <div class="col-md-12 mt-2">
                        <input type="text" placeholder="Enter Source" name="source"
                            style="width: 100%; border-radius: 5px;" ngModel required>
                    </div>
                </div>
            </div>
            <div style="margin-top: -10px; display: unset;">
                <div class="row text-left">
                    <div class="col-md-12 mt-2">
                        Short Description:
                    </div>
                    <div class="col-md-12 mt-2">
                        <textarea rows="3" placeholder="Enter Short Description" name="preDescription" ngModel
                            style="width: 100%" #shortDescriptionRef="ngModel" required></textarea>
                    </div>
                </div>
            </div>
            <div style="margin-top: -10px;">
                <div class="row text-left">
                    <div class="col-md-12 mt-2">
                        Description of Test:
                    </div>
                    <div class="col-md-12 mt-2">
                        <!-- <textarea style="width: 570px;" rows="7" placeholder="Enter Description of Test" name="description" ngModel required></textarea> -->
                        <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText"
                            [preserveWhitespace]="false" name="description" ngModel #descriptionRef="ngModel">
                        </quill-editor>
                    </div>
                </div>
            </div>

            <div class=" text-center myBtn ">
                <button type="submit" [disabled]="!form.valid" class="btn btn-success">Continue <i
                        class="fas fa-arrow-alt-circle-right"></i></button>
            </div>
        </div>
    </form>
    <!-- </div> -->
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>