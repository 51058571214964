import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestsService } from '../services/tests.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-question-dialog',
  templateUrl: './edit-question-dialog.component.html',
  styleUrls: ['./edit-question-dialog.component.css']
})
export class EditQuestionDialogComponent implements OnInit {

  quesNo = -1;
  questionDetails;

  allQuesCategories = [];
  defaultQuestionCategory = "0";
  allOptionScales = [];
  defaultOptionScale = "0";
  defaultQuesType = "0";
  scaleId = "";
  scaleTitle = "";

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<EditQuestionDialogComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.questionDetails = data.questionDetails;
    this.quesNo = data.quesNo;
    console.log(this.questionDetails,this.quesNo);
    this.defaultQuesType = this.questionDetails.questionType;

    //Get all question categories
    this.testsService.getAllQuestionCategories({}).subscribe(res=>{
      this.allQuesCategories = res["questionCategoryData"];
      // console.log(this.allQuesCategories);
      //Setting default initial question category
      const index = this.allQuesCategories.findIndex(i=>i.questionCategoryId==this.questionDetails.questionCategoryId);
      this.defaultQuestionCategory = this.allQuesCategories[index];
      //Get all option scales
      this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
        this.allOptionScales = resScale["ScaleData"];
        console.log(this.allOptionScales);
        //Setting default initial question Scale
        const indexScale = this.allOptionScales.findIndex(i=>i.title.trim().toLowerCase()==this.questionDetails.scaleName.trim().toLowerCase());
        this.defaultOptionScale = this.allOptionScales[indexScale].options;
        this.scaleId = this.allOptionScales[indexScale].scaleId;
        this.scaleTitle = this.allOptionScales[indexScale].title;
      })
    })
  }

  ngOnInit(): void {
  }

  onSelectedScale(value)
  {
    value = value.split(":")[0];
    console.log("Scale : ",this.allOptionScales[value-1]);
    this.scaleId = this.allOptionScales[value-1].scaleId;
    this.scaleTitle = this.allOptionScales[value-1].title;
  }

  editQuestion(form:NgForm)
  {
    // console.log(form.value);
    // console.log(this.questionDetails);
    var updateData = {
      questionText:form.value.questionText,
      id:this.questionDetails._id,  //question ID
      category:this.questionDetails.category,
      category_id:this.questionDetails.category_id,
      subCategory:this.questionDetails.subCategory,
      subCategory_id:this.questionDetails.subCategory_id,
      questionCategory:form.value.questionCategory.questionCategory,
      questionCategoryId:form.value.questionCategory.questionCategoryId,
      multipleAnswers:false,
      scaleId:this.scaleId,
      scaleName:this.scaleTitle,
      questionNumber:this.quesNo,
      options:form.value.options,
      questionType:this.defaultQuesType
    };
    console.log(updateData);
    this.testsService.updateQuestion(updateData).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);
      }
      else{
        this.spinner.hide();
        this.toast.error(res["description"]);
      }
      this.closeModel();
      },err=>{      
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter(this.quesNo);
  }

}
