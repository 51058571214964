import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-edit-about',
  templateUrl: './edit-about.component.html',
  styleUrls: ['./edit-about.component.css']
})

export class EditAboutComponent implements OnInit {

  aboutId;
  aboutDetails;
  allAboutContent;

  constructor(private contentDataService:ContentdataService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<EditAboutComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.aboutId = data.aboutId;
    this.aboutDetails = data.aboutData;
    this.allAboutContent = data.allAboutContent;
    console.log(this.aboutId,this.aboutDetails,this.allAboutContent);
  }

  ngOnInit(): void {
  }

  editAboutDetails(form:NgForm)
  {
    // console.log(form.value);
    //Single Content Id
    const contentId = this.aboutDetails._id;
    // console.log(contentId);
    const index = this.allAboutContent.findIndex(i=>i._id==contentId);
    if(index!=-1)
    {
      this.allAboutContent[index].title = form.value.title;
      this.allAboutContent[index].description = form.value.description;
    }

    let aboutUpdatedData = {
      id:this.aboutId,
      content:this.allAboutContent
    };
    console.log(aboutUpdatedData);
    this.contentDataService.updateAboutList(aboutUpdatedData).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);
        this.closeModel();
      }
      else{
        this.spinner.hide();
        this.toast.error(res["description"]);
      }
      },err=>{
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  closeModel()
  {
    this.dialogRef.close();
    this.contentDataService.filter("scale");
  }

}
