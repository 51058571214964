import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentdataService } from '../services/contentdata.service';
import { CreateQuoteComponent } from '../create-quote/create-quote.component';

@Component({
  selector: 'app-all-quotes',
  templateUrl: './all-quotes.component.html',
  styleUrls: ['./all-quotes.component.css']
})
export class AllQuotesComponent implements OnInit {

  quotes = [];
  filteredStatus = '';
  selectedQuote: any;

  constructor(
    private contentDataService: ContentdataService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getQuotes();
  }

  getQuotes() {
    this.spinner.show();
    this.contentDataService.getAllQuotes({}).subscribe((res: any) => {
      this.quotes = res.data;
      this.spinner.hide();
    }, err => {});
  }

  addQuote() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'save-quote';
    dialogConfig.height = 'auto';
    dialogConfig.width = '500px';
    //passing data
    // dialogConfig.data = { bookingNumber: this.bookingNumber };

    this.matDialog.open(CreateQuoteComponent, dialogConfig);
    this.matDialog
      ._getAfterAllClosed()
      .subscribe((res: any) => this.getQuotes());
  }

  editQuote(quote) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-quote';
    dialogConfig.height = 'auto';
    dialogConfig.width = '500px';
    //passing data
    dialogConfig.data = { quote };

    this.matDialog.open(CreateQuoteComponent, dialogConfig);
    this.matDialog
      ._getAfterAllClosed()
      .subscribe((res: any) => this.getQuotes());
  }

  onDelete(quote) {
    this.selectedQuote = quote;
  }

  deleteQuote() {
    this.contentDataService.deleteQuote({id: this.selectedQuote._id}).subscribe((res: any) => {
      if(res.status) {
        this.toast.success('Reflective Question deleted successfully.');
        this.getQuotes();
      } else {
        this.toast.error('Error occured!');
      }
    });
  }

}
