import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sub-admin-save',
  templateUrl: './sub-admin-save.component.html',
  styleUrls: ['./sub-admin-save.component.css'],
})
export class SubAdminSaveComponent implements OnInit {
  headingText = 'Add New Admin';
  subAdminForm: FormGroup;
  formSubmitted = false;
  permissions: any[];
  isUpdate = false;
  adminDetails: any;
  showPassword = false;
  loaderText = 'Loading details...';

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subAdminForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dashboard: [false],
      userData: [false],
      testManagement: [false],
      dimension: [false],
      qResponse: [false],
      notification: [false],
      bugReport: [false],
      feedback: [false],
      reportFeedback: [false],
      faq: [false],
      about: [false],
      policy: [false],
      isSuperAdmin: [false],
    });

    this.permissions = [
      { key: 'userData', name: 'User data', isChecked: false },
      { key: 'testManagement', name: 'Test management', isChecked: false },
      {
        key: 'dimension',
        name: 'Dimension/report management',
        isChecked: false,
      },
      {
        key: 'qResponse',
        name: 'Q-Response type management',
        isChecked: false,
      },
      {
        key: 'notification',
        name: 'Notification management',
        isChecked: false,
      },
      { key: 'isSuperAdmin', name: 'Sub admins', isChecked: false },
      { key: 'bugReport', name: 'Bug report management', isChecked: false },
      { key: 'feedback', name: 'Feedback', isChecked: false },
      { key: 'reportFeedback', name: 'Report feedback', isChecked: false },
      { key: 'faq', name: 'FAQ management', isChecked: false },
      { key: 'about', name: 'About management', isChecked: false },
      { key: 'policy', name: 'Policy management', isChecked: false },
    ];
  }

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.isUpdate = true;
      this.getAdminProfile(this.route.snapshot.params.id);
      this.subAdminForm.controls['email'].disable();
      this.headingText = 'Update Sub Admin';
    } else {
      this.isUpdate = false;
      this.subAdminForm.controls['email'].enable();
    }
  }

  getAdminProfile(adminId) {
    this.spinner.show();
    this.auth.getAllSubAdmins().subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.adminDetails = res.data.find((admin) => admin._id == adminId);
          this.subAdminForm.patchValue(this.adminDetails);
          this.permissions.map((p) => {
            p.isChecked = this.adminDetails[p.key];
          });
        }
      },
      (errors: any) => {
        this.spinner.hide();
      }
    );
  }

  onSubmit() {
    this.loaderText = 'Saving details...';
    this.spinner.show();
    const permissions = this.permissions.reduce(
      (obj, item) => ({ ...obj, [item.key]: item.isChecked }),
      {}
    );

    const admin = { ...this.adminDetails, ...this.subAdminForm.value };

    const payload = {
      ...admin,
      ...permissions,
    };

    payload.id = payload._id;

    if (!this.isUpdate) {
      this.auth.createSubAdmin(payload).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toastr.success(res.message, 'Successs', {
              timeOut: 2500,
              progressBar: true,
              progressAnimation: 'increasing',
              positionClass: 'toast-top-right',
            });
            this.router.navigateByUrl('/admin/sub-admins');
          }
        },
        (errors: any) => {
          this.spinner.hide();
          this.toastr.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );
    } else {
      if (this.adminDetails.password === this.subAdminForm.value.password) {
        delete payload.password;
      }

      this.auth.editSubAdmin(payload).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toastr.success('Sub admin updated successfully', 'Successs', {
              timeOut: 2500,
              progressBar: true,
              progressAnimation: 'increasing',
              positionClass: 'toast-top-right',
            });
            this.router.navigateByUrl('/admin/sub-admins');
          }
        },
        (errors: any) => {
          this.spinner.hide();
          this.toastr.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );
    }
  }

  passwordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
