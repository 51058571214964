import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';
import { ShowReportFeedbackComponent } from '../show-report-feedback/show-report-feedback.component';

@Component({
  selector: 'app-report-feedbacks',
  templateUrl: './report-feedbacks.component.html',
  styleUrls: ['./report-feedbacks.component.css'],
})
export class ReportFeedbacksComponent implements OnInit {
  allReportFeedbacks = [];

  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.contentDataService
      .getAllReportFeedbacks({ skip: 0, limit: 0 })
      .subscribe((res) => {
        this.allReportFeedbacks = res['SuggestionData'];
        console.log(this.allReportFeedbacks);
        this.spinner.hide();
      });
  }

  viewFeedbackDetails(feedbackData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'show-report-feedback-component';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.width = '950px';
    //passing data
    dialogConfig.data = { feedbackData: feedbackData };

    const modalDialog = this.matDialog.open(
      ShowReportFeedbackComponent,
      dialogConfig
    );
  }
}
