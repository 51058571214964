import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-view-single-report',
  templateUrl: './view-single-report.component.html',
  styleUrls: ['./view-single-report.component.css']
})
export class ViewSingleReportComponent implements OnInit {

  reportDetails: any;
  previewImg: any;

  constructor(public dialogRef: MatDialogRef<ViewSingleReportComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private contentDataService: ContentdataService,) {
    this.reportDetails = data.reportData;
    this.previewImg = environment.baseURL + "app/download" + this.reportDetails.imageURL;
  }

  ngOnInit(): void {
  }

  readFile(url) {
    this.contentDataService.readFile(url).subscribe((image: any) => {
      this.previewImg = image;
    }, error => { });
  }

}
