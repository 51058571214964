<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">

<div class="container-fluid">
    <div *ngFor="let ques of allQuestions;let i=index;">
    <div *ngIf="i==questionCount">
        <form (submit)="addQuestion(form,i)" #form="ngForm" >
            <div class="card inner-card-1">
                <div class="text-center row">
                    <div class="col-md-3 text-left" (click)="decreaseQuesNo()" style="cursor: pointer;">
                        <i class="fas fa-chevron-circle-left" style="font-size: 22px;"></i>
                    </div>
                    <div class="col-md-6">
                        <h1 style="font-size: 25px;"><u>Add Question {{i+1}}/{{allQuestions.length}}</u></h1>
                    </div>
                    <div class="col-md-3 text-right" (click)="increaseQuesNo()" style="cursor: pointer;">
                        <i class="fas fa-chevron-circle-right" style="font-size: 22px;"></i>
                    </div>
                </div>
                <div>
                    Question Text: 
                    <!-- <input type="text" name="questionText" [(ngModel)]="allQuestions[i].quesText" required placeholder="Enter Question Title...."> -->
                    <textarea type="text" name="questionText" [(ngModel)]="allQuestions[i].quesText" required placeholder="Enter Question Title...."></textarea>
                </div>
                <div style="margin-top:-25px">
                    Associated Dimension: 
                    <select class="select-dropdown" name="questionCategory" [(ngModel)]="allQuestions[i].questionCatObj">
                        <option value="0" disabled>Select Category</option>
                        <option [ngValue]="categ" *ngFor="let categ of allQuesCategories">{{categ.questionCategory | titlecase}}</option>                     
                    </select>
                </div>
                <div class="row" style="margin-top:-55px">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 text-center">                     
                        <button (click)="addNewQuestionCategory()" type="button" class="btn1"><i class="fas fa-plus"></i> Create New</button>
                    </div>
                </div>              
                <!-- <div>
                    Question Type:
                    <input type="text" name="option1" ngModel required placeholder="Enter Option 1....">
                </div> -->
                <div style="margin-top:-27px">
                    Q-Response Type:
                    <!-- <select class="select-dropdown" name="options" required [(ngModel)]="allQuestions[i].options" #myOptions 
                    (change)='onSelectedScale(myOptions.value)'> -->
                    <select class="select-dropdown" name="options" required [(ngModel)]="allQuestions[i].options" #myOptions 
                    (change)='onSelectedScale(myOptions.value)'>
                        <option value="0" disabled>Select Scale</option>
                        <option [ngValue]="scale.options" *ngFor="let scale of allOptionScales">{{scale.title | titlecase}}</option>
                    </select>
                </div>       
                <div class="row" style="margin-top:-55px">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 text-center">                     
                        <button (click)="addNewScale()" type="button" class="btn1"><i class="fas fa-plus"></i> Create New</button>
                    </div>
                </div>
                <div style="margin-top:-27px">
                    Q-Response Format:
                    <!-- <select class="select-dropdown" name="questionType" required [(ngModel)]="allQuestions[i].questionType"> -->                        
                    <select class="select-dropdown" name="questionType" required [(ngModel)]="defaultQuesType">
                        <option value="0" disabled>Select Question Type</option>
                        <option value="1">List Format</option>
                        <option value="2">Line Format</option>
                    </select>
                </div>
                <div class="text-center myBtn" style="margin-top:-20px">
                    <button type="submit" [disabled]="!form.valid" id="saveBtn" class="btn btn-success">{{showText[i]}}</button>
                </div>
            </div>

        </form>        
    </div>
    </div>

    <button (click)="submitTest()" [disabled]="!allowSubmit" type="button" class="btn float my-float" style="background-color: #F06528; color: white;">
        Return
    </button>   
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>
