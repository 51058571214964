import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-add-test-details',
  templateUrl: './add-test-details.component.html',
  styleUrls: ['./add-test-details.component.css']
})
export class AddTestDetailsComponent implements OnInit {
 
  categoryId;
  allCategories = [];
  allSubCategories = [];
  defaultCategory = "";
  defaultSubCategory = "";
  defaultGraphType = "-1";
  defaultMultipleValue = "-1";
  editorText: string;

  subCategoryImage;
  quillConfig={
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'], 
      ],
      handlers: {'emoji': function() {}}
    },
  }

  constructor(private route:ActivatedRoute,private routerBtn:Router,private testsService:TestsService,
              private spinner:NgxSpinnerService,private toast:ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(Params=>{
      this.categoryId = Params['categoryId'];

      this.testsService.getAllCategories({}).subscribe(res=>{
        this.allCategories = res["categoryData"];
  
        //Setting default category
        const index = this.allCategories.findIndex(i=>i.category_id==this.categoryId);
        if(index!=-1)
        {
          this.defaultCategory = this.allCategories[index].category;
        }
      })
    })
   
  }
  
  selectImage(event)
  {
    this.spinner.show();
    const file = event.target.files[0];
    this.subCategoryImage = file;
    console.log(this.subCategoryImage);

    this.spinner.hide();                
  }

  onSelectedCategory(value)
  {
    console.log("Value "+value);

    this.defaultCategory = value.split(':')[1].trim();
    const index = this.allCategories.findIndex(i=>i.name.trim()==value.split(':')[1].trim());
    if(index!=-1)
    {
      this.allSubCategories = this.allCategories[index].subCategories;
      this.onSelectedSubCategory("0:"+this.allCategories[index].subCategories[0]);
    }
  }

  onSelectedSubCategory(value)
  {
    console.log("Sub Category value : ",value);    
    this.defaultSubCategory = value.split(':')[1].trim();
  }
 
  addTestDetails(form:NgForm)
  {
    console.log(form.value);
    this.spinner.show();
    form.value.description = this.editorText;
    const formData = new FormData();
    formData.append("photo", this.subCategoryImage);
    formData.append("category", this.defaultCategory);
    formData.append("title", form.value.title);
    formData.append("subCategory", form.value.subCategory);
    formData.append("category_id",this.categoryId);
    formData.append("description", form.value.description);
    formData.append("isMultiple", form.value.isMultiple);
    formData.append("preDescription", form.value.preDescription);
    formData.append("time", form.value.time);
    formData.append("points", form.value.points);
    formData.append("coins", form.value.coins);
    formData.append("unlockCoins", form.value.unlockCoins);
    formData.append("unlockDiamonds", form.value.unlockDiamonds);
    formData.append("price", form.value.price);
    formData.append("specialTest", form.value.specialTest === true ? "YES" : "NO");
    formData.append("source", form.value.source);
    
    console.log("Category ID : "+this.categoryId+", CategoryName :"+this.defaultCategory+", Title : "+form.value.title+", Sub-Category : "+form.value.subCategory+", Description : "+form.value.description+", Graph_type : "+form.value.graph_type+", IsMultiple : "+form.value.isMultiple);

    if(+form.value.graph_type == -1)
    {
      this.spinner.hide();
      this.toast.error("Select Valid Graph Type");
    }
    else{
        formData.append("graph_type", form.value.graph_type);
        this.testsService.createNewSubCategory(formData).subscribe(res=>{
        console.log(res);   
        this.toast.success(res["message"]);
          
        var testDetails = {
          category:this.defaultCategory,
          category_id:this.categoryId,
          subCategory:form.value.subCategory,
          subCategory_id:res["data"].subCategory_id,
          noOfQuestions:+form.value.noOfQuestions
        };
        console.log(testDetails);      
        this.spinner.hide();
        sessionStorage.setItem('testDetailsAdmin',JSON.stringify(testDetails));
        if(form.value.isMultiple=="false")
        {
          this.routerBtn.navigate(['/admin/add-test-questions']);
        }
        else{          
          this.routerBtn.navigate(['/admin/add-test-questions-multiple']);
        }
        
        },err=>{
        this.spinner.hide();
        this.toast.error(err);
      });
    }
  }

}
