import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-add-questions-multipletype',
  templateUrl: './add-questions-multipletype.component.html',
  styleUrls: ['./add-questions-multipletype.component.css']
})
export class AddQuestionsMultipletypeComponent implements OnInit,AfterViewInit {

  categoryId;
  categoryName;
  subCategoryId;
  subCategoryName;

  totalQuestions;
  allQuestions = [];
  showText = [];

  allowSubmit = false;
  questionCount = 0;

  allQuesCategories = [];
  defaultQuesCategory = "0";

  allOptionScales = [];
  defaultOptionScale = "0";
  scaleId = "";
  scaleTitle = "";

  submitQuesCount = 0;
  defaultQuesType = "0";

  allResponses = [{text:"",description:""}];


  //For always scrolling to bottom
  @ViewChildren('messages') messages: QueryList<any>;
  @ViewChild('content') content: ElementRef;

  constructor(public matDialog:MatDialog,private toast:ToastrService,private testsService:TestsService,private spinner:NgxSpinnerService,private routerBtn:Router)
  {
    this.testsService.listen().subscribe((m:any)=>{
      console.log(m);
      if(m=="scale")
      {
        this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
          //Instead of resetting entire array , concatenation of last added item is done to prevent existing state(selected quescategory from form may not get cleared up)
          // this.allOptionScales = this.allOptionScales.concat(resScale["ScaleData"][resScale["ScaleData"].length-1]);
          //Concatenation of first element, as now data is coming in reverse order
          this.allOptionScales = this.allOptionScales.concat(resScale["ScaleData"][0]);

          // console.log(this.allOptionScales);
        });
      }
      else if(m=="quesCategory")
      {
        this.testsService.getAllQuestionCategories({}).subscribe(res=>{
          //Instead of resetting entire array , concatenation of last added item is done to prevent existing state(selected quescategory from form may not get cleared up)
          // this.allQuesCategories = this.allQuesCategories.concat(res["questionCategoryData"][res["questionCategoryData"].length-1]);
          //Concatenation of first element, as now data is coming in reverse order
          this.allQuesCategories = this.allQuesCategories.concat(res["questionCategoryData"][0]);
         
          // console.log(this.allQuesCategories);
        });
        // this.allQuesCategories = this.allQuesCategories.concat({});
      }
      else{
        console.log("All");
        this.ngOnInit();
      }

    });
  }

  ngOnInit(): void {

     //Get all question categories
    this.testsService.getAllQuestionCategories({}).subscribe(res=>{
      this.allQuesCategories = res["questionCategoryData"];
      // console.log(this.allQuesCategories);
      //Get all option scales
      this.testsService.getAllOptionsScales({}).subscribe(resScale=>{
        this.allOptionScales = resScale["ScaleData"];
        // console.log(this.allOptionScales);
      });
    });

    if(sessionStorage.getItem('testDetailsAdmin')!=null)
    {
      this.totalQuestions = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).noOfQuestions;

      this.categoryId = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).category_id;
      this.categoryName = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).category;
      this.subCategoryId = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).subCategory_id;
      this.subCategoryName = JSON.parse(sessionStorage.getItem('testDetailsAdmin')).subCategory;

      for(var i = 0; i<this.totalQuestions;i++)
      {
        this.allQuestions.push({id:"",questionText:"",category:this.categoryName,category_id:this.categoryId,
                                subCategory:this.subCategoryName,subCategory_id:this.subCategoryId,multipleAnswers:true,
                                questionNumber:(i+1),userEntry:"",options:[{text:"",description:""}],
                                subQuestion:[]});

        this.showText.push("Save Question "+(i+1));
        
      }
      
      if(sessionStorage.getItem('allMultipleQuesData')!=null)
      {
        this.allQuestions = JSON.parse(sessionStorage.getItem('allMultipleQuesData'));
        console.log(this.allQuestions);
       
        //To prefill question after coming back from "Adding Sub-Question"(or reload)
        for(let j=0;j<JSON.parse(sessionStorage.getItem('allMultipleQuesData')).length;j++)
        {
          console.log(this.allQuestions[j]);
          this.allQuestions[j].subQuestion = JSON.parse(sessionStorage.getItem('allMultipleQuesData'))[j].subQuestions!=undefined?JSON.parse(sessionStorage.getItem('allMultipleQuesData'))[j].subQuestions:[];
          if(this.allQuestions[j].id!="")
          {
            this.showText[j] = "Update";
          }
          
          this.submitQuesCount +=1;
          if(this.submitQuesCount==this.allQuestions.length)
          {
            this.allowSubmit = true;
          }
        }
      }

    }

    //To prefill question after coming back from "Adding Sub-Question"(or reload)
    // if(sessionStorage.getItem('allSubQuestionsData')!=null)
    // {
    //   console.log(JSON.parse(sessionStorage.getItem('allSubQuestionsData')));
    //   for(let j=0;j<JSON.parse(sessionStorage.getItem('allSubQuestionsData')).length;j++)
    //   {
    //     this.allQuestions[j].subQuestion = JSON.parse(sessionStorage.getItem('allSubQuestionsData'))[j].subQuestions;
    //     this.showText[j] = "Update";
    //   }
    // }

  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.messages.changes.subscribe(this.scrollToBottom);
  }
  
  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    } catch (err) {}
  }

  addNewResponse(index)
  {
    // this.allResponses.push({text:"",description:""});
    this.allQuestions[index].options.push({text:"",description:""});
  }

  addQuestion(form:NgForm,index)
  {
    // console.log(form.value,index);

    this.allQuestions[index].questionText = form.value.questionText;
    this.allQuestions[index].userEntry = form.value.userEntry;
    // this.allQuestions[index].options = [];
    //Mapping options(Responses)
    // for(const key in form.value)
    // {
    //   // console.log("Key : "+key+", value : "+form.value[key]);
    //   if(key.startsWith('singleResponse'))
    //   {
    //     this.allQuestions[index].options.push({text:form.value[key]});
    //   }
    // }
    //Mapping Subquestion(if exists)
    if(sessionStorage.getItem('allSubQuestionsData')!=null)
    {
      // console.log(JSON.parse(sessionStorage.getItem('allSubQuestionsData')));
      const subIndex = JSON.parse(sessionStorage.getItem('allSubQuestionsData')).findIndex(j=>+j.questionNo==(index+1));
      if(subIndex!=-1)
      {
        this.allQuestions[index].subQuestion = JSON.parse(sessionStorage.getItem('allSubQuestionsData'))[subIndex].subQuestions;
        this.showText[index] = "Update";
      }
    }
    console.log(this.allQuestions[index]);

    //Check if we need to save or update the question
    var btnName = document.getElementById("saveBtn").innerText;
    // console.log(btnName.trim().toLocaleLowerCase());
    if(btnName.trim().toLocaleLowerCase().startsWith("save"))
    {
      console.log("Save Question");
      this.testsService.createNewQuestion(this.allQuestions[index]).subscribe(res=>{
        console.log(res);
        if(res["status"])
        {
          this.spinner.hide();
  
          this.showText[index] = "Update";     
          this.allQuestions[index].id = res["data"]._id;

          //Moving to next question
          if((this.questionCount + 1)!=this.totalQuestions)
          {
            this.questionCount = this.questionCount + 1;
          }

          this.submitQuesCount +=1;
          if(this.submitQuesCount==this.allQuestions.length)
          {
            this.allowSubmit = true;
          }
  
          this.toast.success(res["message"]);
        }
        else{
          this.spinner.hide();
          this.toast.error(res["message"]);
        }
        },err=>{
          console.log(err);  
        this.spinner.hide();
        this.toast.error(err);
      });
    }
    else{
      console.log("Update Question");
      console.log(this.allQuestions[index]);
      this.testsService.updateMultipleQuestion(this.allQuestions[index]).subscribe(res=>{
        if(res["status"])
        {
          this.spinner.hide();
          
          //Moving to next question
          if((this.questionCount + 1)!=this.totalQuestions)
          {
            this.questionCount = this.questionCount + 1;
          }

          this.submitQuesCount +=1;
          if(this.submitQuesCount==this.allQuestions.length)
          {
            this.allowSubmit = true;
          }

          this.toast.success(res["description"]);
        }
        else{
          this.spinner.hide();
          this.toast.error("Error Occured");
        }
       },err=>{
        console.log(err);
        this.spinner.hide();
        this.toast.error(err);
      });
    }
       
  }

  submitTest()
  {   
    this.routerBtn.navigate(['/admin/all-subcategories/'+this.categoryId]);       
    sessionStorage.removeItem("testDetailsAdmin");  
    sessionStorage.removeItem("allMultipleQuesData");
    sessionStorage.removeItem("allSubQuestionsData");
  }

  addSubQuestions(index)
  {
    sessionStorage.setItem('allMultipleQuesData',JSON.stringify(this.allQuestions));
    this.routerBtn.navigate(['/admin/add-test-subquestions/'+(index+1)]);
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.totalQuestions)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

}
