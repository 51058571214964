import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-add-about',
  templateUrl: './add-about.component.html',
  styleUrls: ['./add-about.component.css']
})
export class AddAboutComponent implements OnInit {

  aboutId;
  allAboutContent;

  constructor(private contentDataService:ContentdataService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<AddAboutComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.aboutId = data.aboutId;
    this.allAboutContent = data.allAboutContent;
    console.log(this.aboutId,this.allAboutContent);
  }

  ngOnInit(): void {
  }

  addAboutDetails(form:NgForm)
  {
    // console.log(form.value);
    //Single Content Id
    // console.log(contentId);
    this.allAboutContent.push(form.value);

    let aboutUpdatedData = {
      id:this.aboutId,
      content:this.allAboutContent
    };
    console.log(aboutUpdatedData);
    this.contentDataService.updateAboutList(aboutUpdatedData).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);
        this.closeModel();
      }
      else{
        this.spinner.hide();
        this.toast.error(res["description"]);
      }
      },err=>{
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  closeModel()
  {
    this.dialogRef.close();
    this.contentDataService.filter("scale");
  }

}
