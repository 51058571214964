<div class="container">
    <hr>
    <div class="row">
        <div class="col-md-6">
            <label for="">Category Name : </label>
        </div>
        <div class="col-md-6">
            {{categoryDetails.category}}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Title : </label>
        </div>
        <div class="col-md-6">
            {{categoryDetails.title}}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Short Description : </label>
        </div>
        <div class="col-md-6">
            {{categoryDetails.preDescription}}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Description : </label>
        </div>
        <div class="col-md-6" [innerHtml]="categoryDetails.description"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="">Created On : </label>
        </div>
        <div class="col-md-6">
            {{categoryDetails.createdAt | date:'medium'}}
        </div>
    </div>
    <div class="row text-center">
        <div class="col-md-12">
            <img src={{categoryDetails.imageUrl}} onerror="this.src='../../assets/personalityTest.jpg'" height="172px" width="auto" alt="">
        </div>
        
    </div>
    <hr>
</div>