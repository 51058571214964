import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestsService } from '../services/tests.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-new-quescategory',
  templateUrl: './create-new-quescategory.component.html',
  styleUrls: ['./create-new-quescategory.component.css']
})
export class CreateNewQuescategoryComponent implements OnInit {

  categoryId;
  categoryName;
  subCategoryId;
  subCategoryName;

  defaultBreakPercentage = 33;
  defaultBreakPercentageHigh = 66;

  lowEditorText: string;
  mediumEditorText: string;
  highEditorText: string;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    },
  }

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<CreateNewQuescategoryComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.categoryId = data.categoryId;
    this.categoryName = data.categoryName;
    this.subCategoryId = data.subCategoryId;
    this.subCategoryName = data.subCategoryName;
    console.log(this.categoryId,this.categoryName,this.subCategoryId,this.subCategoryName);
  }

  ngOnInit(): void {
  }

  addNewQuestionCategory(form:NgForm)
  {
    var quesCategoryData = {
      questionCategory:form.value.questionCategory,
      category:this.categoryName,
      category_id:this.categoryId,
      subCategory:this.subCategoryName,
      subCategory_id:this.subCategoryId,
      description:form.value.description,
      detailedDescriptionHigh:form.value.detailedDescriptionHigh,
      detailedDescriptionLow:form.value.detailedDescriptionLow,
      detailedDescriptionMedium:form.value.detailedDescriptionMedium,
      breakPercentage:form.value.breakPercentage,
      breakPercentageHigh:form.value.breakPercentageHigh,
      lowDetails: this.lowEditorText,
      mediumDetails: this.mediumEditorText,
      highDetails: this.highEditorText
    };
    console.log(quesCategoryData);
    this.testsService.createNewQuestionCategory(quesCategoryData).subscribe(res=>{
      this.spinner.hide();
      this.toast.success(res["message"]);      
      this.closeModel();
      },err=>{      
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("quesCategory");
  }

}
