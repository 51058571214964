<div class="container">
    <hr>
    <form (submit)="createNewFaq(form)" #form="ngForm">
    <div class="row">
        <div class="col-md-5">
            <label for="">Title : </label>
        </div>
        <div class="col-md-7">
            <input type="text" class="form-control" placeholder="Enter Title" name="title" ngModel required>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-5">
            <label for="">Description : </label>
        </div>
        <div class="col-md-7">
            <textarea name="description" class="form-control" placeholder="Enter Description" rows="3" ngModel required></textarea>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-12">
            <button class="btn btn-success btn-block" type="submit" [disabled]="!form.valid">Submit</button>
        </div>
    </div>
    </form>
    <hr>
</div>
