import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestsService } from '../services/tests.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-show-subquestion-scaleoptions',
  templateUrl: './show-subquestion-scaleoptions.component.html',
  styleUrls: ['./show-subquestion-scaleoptions.component.css']
})
export class ShowSubquestionScaleoptionsComponent implements OnInit {
 
  index;
  previousSubquestions = [];
  showSubquesOptions = [];

  allPreviousOptions = [];

  existingAnswers = [];

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<ShowSubquestionScaleoptionsComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    // this.previousSubquestions = data.previousSubquestions;
    //last item of this array is current subquestion itself, so removing that
    // this.previousSubquestions.pop();
    this.index = +data.index;

    for(var i = 0;i<data.previousSubquestions.length-1;i++)
    {
      if(data.previousSubquestions[i].scaleName.length!=0)
      this.previousSubquestions.push(data.previousSubquestions[i]);
    }
    console.log(this.previousSubquestions);
    //Fill scaleNames in new array
    this.previousSubquestions.forEach(sub=>{
      this.showSubquesOptions.push({scaleName:sub.scaleName, showOptions:false});
    });
    // console.log(this.showSubquesOptions);
    
    this.existingAnswers = data.existingAnswers;
    console.log(this.existingAnswers);
    let newExistingAnswers = [];
    if(this.existingAnswers.length<this.previousSubquestions.length)
    {
      for(var i=0;i<this.previousSubquestions.length;i++)
      {
        const index1 = this.existingAnswers.findIndex(j=>j.scaleName==this.previousSubquestions[i].scaleName);
        if(index1!=-1)
        {
          newExistingAnswers.push(this.existingAnswers[index1]);
        }
        else{
          newExistingAnswers.push({subQuesNo:(i+1),scaleName:"",options:[]});
        }
      }
      // for(var k = 0;k<newExistingAnswers.length;k++)
      // {
      //   const index1 = this.existingAnswers.findIndex(j=>j.subQuesNo==(k+1));
      //   if(index1!==1)
      //   {
      //     newExistingAnswers[k] = this.existingAnswers[index1];
      //   }      
      // }
      console.log(newExistingAnswers);
      this.existingAnswers = newExistingAnswers;
    }

    for(var i=0;i<this.existingAnswers.length;i++)
    {
      if(this.existingAnswers[i].scaleName!="")
      this.showSubquesOptions[i].showOptions = true;
    }
  
  }

  ngOnInit(): void {
  }

  testMain(index,scaleName)
  {
    if(this.existingAnswers[index].scaleName.length==0)
    {
      return false;
    }
    else{
      return true;
    }
  }
  
  dataScale(e, id: any) {
    console.log(e.target.value, e.target.checked,id);
    if(this.allPreviousOptions.length!=0)
    {
      const index = this.showSubquesOptions.findIndex(i=>i.scaleName==e.target.value);
      if(index!=-1 && e.target.checked)
      {
        this.showSubquesOptions[index].showOptions = true;
        if(e.target.value!=true && e.target.value!=false)
        {
          this.allPreviousOptions.push({subQuesNo:id,scaleName:e.target.value,options:[]});
        }
      }
      else{
        this.showSubquesOptions[index].showOptions = false;
        const prevIndex = this.allPreviousOptions.findIndex(j=>j.scaleName==e.target.value);
        if(prevIndex!=-1)
        {
          this.allPreviousOptions.splice(prevIndex,1);
        }
      }
    }
    else{
      console.log("Existing", this.showSubquesOptions);
      console.log("Target Value : ",e.target.value)
      const index = this.showSubquesOptions.findIndex(i=>i.scaleName==e.target.value);
      if(index!=-1 && e.target.checked)
      {
        this.showSubquesOptions[index].showOptions = true;
        if(e.target.value!=true && e.target.value!=false)
        {
          console.log(this.existingAnswers,index);
          if(this.existingAnswers.length==0)
          {
            for(var s = 0;s<this.showSubquesOptions.length;s++)
            {
              this.existingAnswers.push({subQuesNo:s+1,scaleName:"",options:[]});
            }
          }
          console.log("Index : ",this.existingAnswers,index);
          
         this.existingAnswers[index] = {subQuesNo:id,scaleName:e.target.value,options:[]};
        
        }
      }
      else{
        console.log("Remove1");
        this.showSubquesOptions[index].showOptions = false;
        const prevIndex = this.existingAnswers.findIndex(j=>j.scaleName==e.target.value);
        if(prevIndex!=-1)
        {
          console.log("Remove 2");
          this.existingAnswers.splice(prevIndex,1);
        }
      }
    }
  }

  dataScaleOptions(e,id)
  {
    console.log(e.target.value, e.target.checked,id);
    if(this.allPreviousOptions.length!=0)
    {
      const index = this.allPreviousOptions.findIndex(i=>i.subQuesNo==id);
      if(index!=-1)
      {
        if(e.target.checked){
          this.allPreviousOptions[index].options.push({text:e.target.value});
        }
        else{
          const prevIndex = this.allPreviousOptions[index].options.findIndex(j=>j.text==e.target.value);
          if(prevIndex!=-1)
          {
            this.allPreviousOptions[index].options.splice(prevIndex,1);
          }
        }
      }
    }
    else{
      console.log("Existing answers : ",this.existingAnswers);
      const index = this.existingAnswers.findIndex(i=>i.subQuesNo==id && (i.scaleName.toString().trim()!="false" && i.scaleName.toString().trim()!="true"));
      if(index!=-1)
      {
        if(e.target.checked){
          this.existingAnswers[index].options.push({text:e.target.value});
        }
        else{
          const prevIndex = this.existingAnswers[index].options.findIndex(j=>j.text==e.target.value);
          if(prevIndex!=-1)
          {
            this.existingAnswers[index].options.splice(prevIndex,1);
          }
        }
      }
    }
   
  }

  test(scaleName,optionName)
  {
    // console.log(index,optionName);
    const quesIndex = this.existingAnswers.findIndex(i=>i.scaleName==scaleName);
    if(quesIndex!=-1)
    {
      const opIndex = this.existingAnswers[quesIndex].options.findIndex(o=>o.text==optionName);
      if(opIndex!=-1)
      {
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  onSubmit(form:NgForm)
  {
    let allOptionsData = this.allPreviousOptions;
    if(this.allPreviousOptions.length==0)
    {
      allOptionsData = this.existingAnswers;
    }

    allOptionsData = allOptionsData.filter(i=>i.scaleName.toString().trim()!="true" && i.scaleName.toString().trim()!="false" && i.scaleName.toString().trim().length!=0);
    allOptionsData.forEach(op=>{
      console.log(op.scaleName, typeof op.scaleName, op.scaleName.toString().trim());
    });
    
    for(var i=0;i<allOptionsData.length;i++)
    {
      const index = this.previousSubquestions.findIndex(j=>j.scaleName==allOptionsData[i].scaleName);
      allOptionsData[i].subQuesNo = this.previousSubquestions[index].subQuesNo;
    }
    console.log(allOptionsData);
    this.dialogRef.close();
    this.testsService.filter({type:"prevSubScales",data:allOptionsData,index:this.index});
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("scale");
  }

}


//When scale is added back(checkbox for subques is selected again, fill all options also)
