import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-sub-questions-dialog',
  templateUrl: './show-sub-questions-dialog.component.html',
  styleUrls: ['./show-sub-questions-dialog.component.css']
})
export class ShowSubQuestionsDialogComponent implements OnInit, OnDestroy {

  allSubQuestions;

  constructor(public dialogRef:MatDialogRef<ShowSubQuestionsDialogComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {    
    this.allSubQuestions = data.subQuestion;

    this.allSubQuestions.forEach(subQ=>{
      // if(subQ.linkedWith=="MANUAL_ENTRY")
      // {
      //   subQ.linkedWith = "Manual Entry of Responses (Limit = "+subQ.number+")";
      // }
      // if(subQ.linkedWith=="RESPONSE_SUB_QUESTION")
      // {
      //   subQ.linkedWith = "Selected Responses of Sub Question Number "+subQ.number;
      // }
      // if(subQ.linkedWith=="ALL_RESPONSE_SUB_QUESTION")
      // {
      //   subQ.linkedWith = "All Responses of Sub Question Number "+subQ.number;
      // }
      // if(subQ.linkedWith=="RESPONSE_MAIN_QUESTION")
      // {
      //   subQ.linkedWith = "Selected Responses of Main Question";
      // }
      // if(subQ.linkedWith=="ALL_RESPONSE_MAIN_QUESTION")
      // {
      //   subQ.linkedWith = "All Responses of Main Question";
      // }
      // if(subQ.linkedWith=="RESPONSE_SUB_QUESTION_WITH_SCALE")
      // {
      //   subQ.linkedWith = "Selected Response of Sub Question with Scale";
      // }
    });
    console.log(this.allSubQuestions);
  }

  ngOnInit(): void {
  }

  ngOnDestroy()
  {
    console.log("Destroy Started");
  }

}
