import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-edit-sub-category-details',
  templateUrl: './edit-sub-category-details.component.html',
  styleUrls: ['./edit-sub-category-details.component.css']
})
export class EditSubCategoryDetailsComponent implements OnInit {

  categoryId;
  _id;
  subCategoryDetails;
  subCategoryImage;
  editorText: string;

  newImage = null;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    }
  }

  constructor(private route: ActivatedRoute, private testsService: TestsService, private routerBtn: Router, private toast: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(Params => {
      this.categoryId = Params['categoryId'];
      this._id = Params['subCategoryId'];
      this.testsService.getAllSubCategories({ category_id: this.categoryId }).subscribe(res => {
        const index = res["subCategoryData"].findIndex(i => i._id == this._id);
        this.subCategoryDetails = res["subCategoryData"][index];
        this.editorText = this.subCategoryDetails.description;
        this.subCategoryDetails.specialTest = this.subCategoryDetails.specialTest === "YES" ? true : false; 

        this.subCategoryDetails.imageUrl = environment.baseURL + "app/download" + this.subCategoryDetails.imageUrl;
        console.log(this.subCategoryDetails);
      });
    });

  }

  selectImage(event) {
    const file = event.target.files[0];
    this.subCategoryImage = file;
    console.log("Media Files : ", this.subCategoryImage);
    this.newImage = file;

    //Displaying the uploaded image
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //error message  
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.subCategoryDetails.imageUrl = reader.result;
    }
  }

  onSubmit(form: NgForm) {
    console.log(form.value);
    // console.log(form.value.subCategory,form.value.title,form.value.description,this._id,this.newImage);
    form.value.description = this.editorText;
    const formData = new FormData();
    formData.append("subCategory", form.value.subCategory);
    formData.append("title", form.value.title);
    formData.append("description", form.value.description);
    formData.append("id", this._id);
    formData.append("graph_type", form.value.graph_type);
    formData.append("photo", this.newImage);
    formData.append("preDescription", form.value.preDescription);
    formData.append("time", form.value.time);
    formData.append("points", form.value.points);
    formData.append("coins", form.value.coins);
    formData.append("unlockCoins", form.value.unlockCoins);
    formData.append("unlockDiamonds", form.value.unlockDiamonds);
    formData.append("price", form.value.price);
    formData.append("specialTest", form.value.specialTest === true ? "YES" : "NO");
    formData.append("source", form.value.source);

    this.testsService.editSubCategory(formData).subscribe(res => {
      this.toast.info(res["message"], "", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
      this.routerBtn.navigate(['/admin/all-subcategories/' + this.categoryId]);
    }, err => {
      this.toast.error(err, "Error Occured", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    });
  }

}
