<div class="row mt-4">
  <div class="col-md-1"></div>
  <div class="col-md-6 text-right">
    <h4><u>Categories for Tests</u></h4>
  </div>
  <div class="col-md-5 text-right">
    <button routerLink="/admin/create-new-category" style="background-color: #4A9F37;border-radius: 8px;color: white;" class="btn mb-1">
      <i class="fas fa-plus"></i> Add Category
    </button>
  </div>
</div>
<hr class="my-3">
<div class="row mt-4">
    <div class="col-md-3 mb-4" *ngFor="let cat of allCategories;let i = index;">
      
      <div class="card text-center o-hidden h-250" style="cursor:pointer;border-radius: 8px;background-color: #4A9F37;margin-bottom: -10px;">
        <div class="row">
          <div class="col-md-10" (click)="goToSubCategories(cat.category_id)">
            <div class="card-body">
              <p class="tagStyle">{{cat.category | titlecase}}</p>
            </div>
          </div>
          <div class="col-md-2 text-left" style="background-color: rgb(241, 238, 232);margin-left: -9px;">
            <p (click)="viewCategoryDetails(cat)" style="margin-bottom: 6px;margin-top: 2px;"><i class="fas fa-eye" style="color: darkblue;"></i></p>
            <p routerLink="/admin/edit-category/{{cat._id}}" style="margin-bottom: 6px;"><i class="fas fa-pen" style="color: darkgreen;"></i></p>
            <p><i class="fas fa-trash" style="color: red;"></i></p>
          </div>
        </div>
        <div class="row py-1" style="background-color: #b2ccac;">
          <div class="col-md-6">
            <h6>Archive   
              <mat-slide-toggle style="padding-top: 5px;" [checked]="cat.archive" (change)="toggle($event,cat)"></mat-slide-toggle>       
            </h6>
          </div>

          <div class="col-md-5 mt-1 mb-1">
            <select class="custom-select w-auto custom-select-sm" [(ngModel)]="cat.categoryStatus" (change)="onStatusChange($event, cat._id)">
              <option *ngFor="let status of statuses" [value]="status.value">{{ status.name }}</option>
            </select>
          </div>
        </div>
       
      </div>
    </div>   
</div>


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>