import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // tokenString = 'Bearer '+localStorage.getItem("adminAuthToken").replace(/^["'](.+(?=["']$))["']$/, '$1');

  constructor(private http: HttpClient) {
  }

  private listeners = new Subject<any>();
  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(filterBy) {
    this.listeners.next(filterBy);
  }

  fetchAllUsers(data) {
    return this.http.post(environment.baseURL + 'app/get-all-user-profile/', data);
  }

  deleteSingleUser(id) {
    return this.http.get(environment.baseURL + 'delete-user/' + id);
  }

  editUserDetails(data) {
    return this.http.post(environment.baseURL + 'edit-username-bio/', data);
  }

}



//REPORT Feedbacks
//Name, email, Dimension (questionCategory from API),createdAt, View Feedback

//View Feedback : (inside of "data" in API)
//Ques Category
//description
//percentage

//**AFTER ARROW DOWN */
//detailedDescription
//detailedDescriptionDetails
//Heading , and POINTS


