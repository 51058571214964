import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuggesstionService } from '../services/suggesstion.service';

@Component({
  selector: 'app-all-suggestions',
  templateUrl: './all-suggestions.component.html',
  styleUrls: ['./all-suggestions.component.css']
})
export class AllSuggestionsComponent implements OnInit {

  allSuggestions = [];

  filteredStatus = '';
  p:number =1;

  constructor(private suggestionService:SuggesstionService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.suggestionService.getAllSuggestions().subscribe(res=>{
      this.allSuggestions = res["suggestions"];
      console.log(this.allSuggestions);
      this.spinner.hide();
    });
  }

}
