import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-edit-category-details',
  templateUrl: './edit-category-details.component.html',
  styleUrls: ['./edit-category-details.component.css']
})
export class EditCategoryDetailsComponent implements OnInit {

  _id;
  categoryDetails;
  categoryImage;
  editorText: string;

  newImage = null;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    },
  }


  constructor(
    private route: ActivatedRoute,
    private testsService: TestsService,
    private routerBtn: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(Params => {
      this._id = Params['categoryId'];
      this.testsService.getAllCategories({}).subscribe(res => {
        const index = res["categoryData"].findIndex(i => i._id == this._id);
        this.categoryDetails = res["categoryData"][index];
        this.editorText = this.categoryDetails.description;

        this.categoryDetails.imageUrl = environment.baseURL + "app/download" + this.categoryDetails.imageUrl;
        console.log(this.categoryDetails);
      });
    });

  }

  selectImage(event) {
    const file = event.target.files[0];
    this.categoryImage = file;
    this.newImage = file;
    console.log("Media Files : ", this.categoryImage);

    //Displaying the uploaded image
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //error message  
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.categoryDetails.imageUrl = reader.result;
    }
  }

  onSubmit(form: NgForm) {
    this.spinner.show();
    // console.log(form.value.category,form.value.title,form.value.description,this._id,this.newImage);
    if (this.editorText) {
      form.value.description = this.editorText;
    }

    const formData = new FormData();
    formData.append("category", form.value.category);
    formData.append("title", form.value.title,);
    formData.append("description", form.value.description);
    formData.append("id", this._id);
    formData.append("archive", this.categoryDetails.archive);
    formData.append("photo", this.newImage);
    formData.append("preDescription", form.value.preDescription);

    this.testsService.editCategory(formData).subscribe(res => {
      // console.log(res);
      if (res["status"]) {
        this.spinner.hide();
        this.toast.info(res["description"], "", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
        this.routerBtn.navigate(['/admin/all-tests']);

      } else {
        this.toast.error(res["description"], "Error Occured", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
      }
    }, err => {
      this.spinner.hide();
      this.toast.error(err, "Error Occured", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    });
  }

}
