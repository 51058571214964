<div class="container">
    <hr>    
    <form (submit)="addNewQuestionCategory(form)" #form="ngForm" >
          <div class="row mt-4">
            <div class="col-md-4">
                <label> Category: </label>
            </div>
            <div class="col-md-8"> 
                <select class="select-dropdown form-control" name="category" [ngModel]="defaultCategory" #mySelect
                (change)='onSelectedCategory(mySelect.value)' >
                    <option value="0" disabled>Select Category</option>
                    <option [ngValue]="categ.category_id" *ngFor="let categ of allCategories">{{categ.category | titlecase}}</option>                     
                </select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Sub-Category: </label>
            </div>
            <div class="col-md-8"> 
                <select class="select-dropdown form-control" name="subCategory" [ngModel]="defaultSubCategory" #mySelect1
                (change)='onSelectedSubCategory(mySelect1.value)'>
                    <option value="0" disabled>Select Sub-Category</option>
                    <option [ngValue]="subCateg.subCategory_id" *ngFor="let subCateg of allSubCategories">{{subCateg.subCategory | titlecase}}</option>                     
                </select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Dimension Name: </label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" name="questionCategory" ngModel required placeholder="Enter Dimension Name....">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Description of Dimension: </label>
            </div>
            <div class="col-md-8">               
                <!-- <input type="text" class="form-control" name="description" ngModel required placeholder="Enter description of dimension...."> -->
                <textarea  class="form-control" rows="4" name="description" ngModel required placeholder="Enter description of dimension...."></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Detailed Desc. Low: </label>
            </div>
            <div class="col-md-8">
                <textarea class="form-control" rows="4" name="detailedDescriptionLow" ngModel required placeholder="Enter Detailed Description Low...."></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Low details: </label>
            </div>
            <div class="col-md-8">
                <quill-editor [styles]="{height: '200px'}"  [modules]="quillConfig" [(ngModel)]="lowEditorText"
                    [preserveWhitespace]="false" name="lowDetails">
                </quill-editor>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Detailed Desc. Medium: </label>
            </div>
            <div class="col-md-8">
                <textarea class="form-control" rows="4" name="detailedDescriptionMedium" ngModel required placeholder="Enter Detailed Description Medium...."></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label>Medium details: </label>
            </div>
            <div class="col-md-8">
                <quill-editor [styles]="{height: '200px'}"  [modules]="quillConfig" [(ngModel)]="mediumEditorText"
                    [preserveWhitespace]="false" name="mediumDetails">
                </quill-editor>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Detailed Desc. High: </label>
            </div>
            <div class="col-md-8">
                <textarea class="form-control" rows="4" name="detailedDescriptionHigh" ngModel required placeholder="Enter Detailed Description High...."></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label>High details: </label>
            </div>
            <div class="col-md-8">
                <quill-editor [styles]="{height: '200px'}"  [modules]="quillConfig" [(ngModel)]="highEditorText"
                    [preserveWhitespace]="false" name="highDetails">
                </quill-editor>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Break Percentage 1: </label>
            </div>
            <div class="col-md-8">
                <input type="number" class="form-control" name="breakPercentage" [(ngModel)]="defaultBreakPercentage" min="0" max="100" oninput="validity.valid||(value='');" required placeholder="Enter Break Percentage....">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <label> Break Percentage 2: </label>
            </div>
            <div class="col-md-8">
                <input type="number" class="form-control" name="breakPercentageHigh" [(ngModel)]="defaultBreakPercentageHigh" min="0" max="100" oninput="validity.valid||(value='');" required placeholder="Enter Break Percentage High....">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <button type="submit" [disabled]="!form.valid" class="btn btn-block btn-success">Submit</button>
            </div>
        </div>
    </form>
    <hr>
</div>
