<div class="container">
    <hr class="my-3">
    <div class="row" *ngFor="let subQues of allSubQuestions;let i=index">
        <div class="col-md-12 mt-3">
            <h6 class="text-center">SubQuestion - {{i+1}}</h6>
        </div>
        <div class="col-md-6">
            SubQuestion Title : 
        </div>
        <div class="col-md-6">
            {{subQues.subQuestion}}
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.scaleName.length!=0">
            Scale Name : 
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.scaleName.length!=0">
            {{subQues.scaleName}}
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.scaleName.length!=0">
            Scale Options : 
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.scaleName.length!=0">
            <div class="row" *ngFor="let op of subQues.options">
                <div class="col-md-12">
                    {{op.text | titlecase}}
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.answers.length==0">
            Linked With : 
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.answers.length==0">
            <p *ngIf="subQues.linkedWith=='MANUAL_ENTRY'">Manual Entry of Responses (Limit = {{subQues.number}})</p>
            <p *ngIf="subQues.linkedWith=='RESPONSE_SUB_QUESTION'">Selected Responses of Sub Question Number {{subQues.number}}</p>
            <p *ngIf="subQues.linkedWith=='ALL_RESPONSE_SUB_QUESTION'">All Responses of Sub Question Number {{subQues.number}}</p>
            <p *ngIf="subQues.linkedWith=='RESPONSE_MAIN_QUESTION'">Selected Responses of Main Question</p>
            <p *ngIf="subQues.linkedWith=='ALL_RESPONSE_MAIN_QUESTION'">All Responses of Main Question</p>
            <p *ngIf="subQues.linkedWith=='RESPONSE_SUB_QUESTION_WITH_SCALE'">Selected Response of Sub Question with Scale</p>
            
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.answers.length!=0">
            Linked With : 
        </div>
        <div class="col-md-6 mt-1" *ngIf="subQues.answers.length!=0">
            <div class="row">
                <div class="col-md-12">
                    {{subQues.linkedWith}}
                </div>
            </div>
            <div class="row " *ngFor="let ans of subQues.answers">
                <div class="col-md-12">
                    <hr>
                </div>
                <div class="col-md-4">
                    Scale : 
                </div>
                <div class="col-md-8 text-center">
                    {{ans.scaleName}} 
                </div>
                <div class="col-md-5">
                    Options : 
                </div>
                <div class="col-md-7 text-center">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let op of ans.options">
                            {{op.text}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="subQues.answers.length!=0">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-1">
            Manual User Entry : 
        </div>
        <div class="col-md-6 mt-1">
            {{subQues.userEntry?'Yes':'No'}}
        </div>
    </div>
    <hr class="my-3">
</div>