import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';
import { ViewSingleFeedbackComponent } from '../view-single-feedback/view-single-feedback.component';

@Component({
  selector: 'app-all-feedbacks',
  templateUrl: './all-feedbacks.component.html',
  styleUrls: ['./all-feedbacks.component.css'],
})
export class AllFeedbacksComponent implements OnInit {
  allFeedbacks = [];

  p: number = 1;
  totalCount: number;

  constructor(
    public matDialog: MatDialog,
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => { },
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

      this.getAllFeedbacks(0);
  }

  getAllFeedbacks(skip) {
    this.spinner.show();
    let payload = {
      skip,
      limit: 10
    }

    this.contentDataService
      .getAllFeedbacks(payload)
      .subscribe((res) => {
        this.allFeedbacks = res['feedbackData'];
        this.totalCount = res['feedbackTotal']
        this.spinner.hide();
      });
  }

  onPageChange(e) {
    this.p = e;
    let skip = e == 1 ? 0 : (e - 1) * 10;
    this.getAllFeedbacks(skip)
  }

  viewSingleFeedback(feedback: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-single-report-component';
    dialogConfig.height = "auto";
    dialogConfig.width = '460px';
    //passing data
    dialogConfig.data = { feedback };

    const modalDialog = this.matDialog.open(
      ViewSingleFeedbackComponent,
      dialogConfig
    );
  }
}
