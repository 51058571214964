import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-add-question-dialog',
  templateUrl: './add-question-dialog.component.html',
  styleUrls: ['./add-question-dialog.component.css']
})
export class AddQuestionDialogComponent implements OnInit {

  noOfQuestions = "";
  lastQuestionDetals;
  lastQuesNo;
  allQuestions;

  constructor(private routerBtn:Router,private testsService:TestsService,public dialogRef:MatDialogRef<AddQuestionDialogComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.lastQuestionDetals = data.lastQuesDetails;
    this.lastQuesNo = data.lastQuesNo;
    this.allQuestions = data.testDetails;
    // console.log(this.lastQuestionDetals,this.lastQuesNo);
  }

  ngOnInit(): void {
  }

  addNewQuestions()
  {
    var quesData = {
      category:this.lastQuestionDetals.category,
      category_id:this.lastQuestionDetals.category_id,
      subCategory:this.lastQuestionDetals.subCategory,
      subCategory_id:this.lastQuestionDetals.subCategory_id,
      lastQuesNo:this.lastQuesNo,
      noOfQuestions:+this.noOfQuestions,
      allQuestions:this.allQuestions
    };
    console.log(quesData);
    sessionStorage.setItem("newQuesData",JSON.stringify(quesData));
    this.routerBtn.navigate(['/admin/add-questions']);
    this.closeModel();
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("Added");
  }

}
