import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-create-new-category',
  templateUrl: './create-new-category.component.html',
  styleUrls: ['./create-new-category.component.css']
})
export class CreateNewCategoryComponent implements OnInit {

  categoryImage;
  editorText: string;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    },
  }

  constructor(
    private testsService: TestsService,
    private routerBtn: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  selectImage(event) {
    const file = event.target.files[0];
    this.categoryImage = file;
    console.log("Media Files : ", this.categoryImage);
  }

  onSubmit(form: NgForm) {
    this.spinner.show();
    form.value.description = this.editorText;
    const formData = new FormData();
    formData.append("category", form.value.category);
    formData.append("title", form.value.title);
    formData.append("description", form.value.description);
    formData.append("photo", this.categoryImage);
    formData.append("preDescription", form.value.preDescription);

    this.testsService.createNewCategory(formData).subscribe(res => {
      if (res["status"]) {
        this.spinner.hide();
        this.toast.info(res["message"], "", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
        this.routerBtn.navigate(['/admin/all-tests']);

      } else {
        this.toast.error(res["message"], "Error Occured", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
      }
    }, err => {
      this.spinner.hide();
      this.toast.error(err, "Error Occured", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    })

  }

}
