import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-report-feedback',
  templateUrl: './show-report-feedback.component.html',
  styleUrls: ['./show-report-feedback.component.css']
})
export class ShowReportFeedbackComponent implements OnInit {

  feedbackData;

  constructor(public dialogRef: MatDialogRef<ShowReportFeedbackComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.feedbackData = data.feedbackData;
    this.feedbackData.imageURL = environment.baseURL + "app/read-file" + this.feedbackData.imageURL;
    console.log(this.feedbackData)
  }

  ngOnInit(): void {
  }

}
