<div class="wrapper" *ngIf="questionCateDetails">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="main-heading">{{ questionCateDetails?.questionCategory }}</h4>
    </div>
    <div>
      <p>
        Break Percentage 1:
        <span class="font-weight-bold"
          >{{ questionCateDetails?.breakPercentage }}%</span
        >
      </p>
      <p>
        Break Percentage 2:
        <span class="font-weight-bold"
          >{{ questionCateDetails?.breakPercentageHigh }}%</span
        >
      </p>
    </div>
  </div>
  <div>
    <p class="mb-1 font-weight-bold heading">Description:</p>
    <p class="ml-4 mb-1">{{ questionCateDetails?.description }}</p>
  </div>

  <div class="mt-4">
    <p class="mb-1 font-weight-bold heading">Description High:</p>
    <p class="ml-4 mb-1">{{ questionCateDetails?.detailedDescriptionHigh }}</p>
    <div class="ml-4 mt-4" [innerHTML]="questionCateDetails?.highDetails"></div>
    <!-- <ng-container
      *ngFor="let detail of questionCateDetails?.lowDescriptionDetails"
    >
      <div class="ml-4 mt-4">
        <h5 class="font-weight-normal">{{ detail?.heading }}</h5>
        <ul>
          <li *ngFor="let point of detail.points">{{ point }}</li>
        </ul>
      </div>
    </ng-container> -->
  </div>

  <div class="mt-4">
    <p class="mb-1 font-weight-bold heading">Description Medium:</p>
    <p class="ml-4 mb-1">
      {{ questionCateDetails?.detailedDescriptionMedium }}
    </p>
    <div class="ml-4 mt-4" [innerHTML]="questionCateDetails?.mediumDetails"></div>
    <!-- <ng-container
      *ngFor="let detail of questionCateDetails?.mediumDescriptionDetails"
    >
      <div class="ml-4 mt-4">
        <h5 class="font-weight-normal">{{ detail?.heading }}</h5>
        <ul>
          <li *ngFor="let point of detail.points">{{ point }}</li>
        </ul>
      </div>
    </ng-container> -->
  </div>

  <div class="mt-4">
    <p class="mb-1 font-weight-bold heading">Description Low:</p>
    <p class="ml-4 mb-1">{{ questionCateDetails?.detailedDescriptionLow }}</p>
    <div class="ml-4 mt-4" [innerHTML]="questionCateDetails?.lowDetails"></div>

    <!-- <ng-container
      *ngFor="let detail of questionCateDetails?.lowDescriptionDetails"
    >
      <div class="ml-4 mt-4">
        <h5 class="font-weight-normal">{{ detail?.heading }}</h5>
        <ul>
          <li *ngFor="let point of detail.points">{{ point }}</li>
        </ul>
      </div>
    </ng-container> -->
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>
