<div class="row myDiv">
  <div class="card card-profile text-center">
    <img
      alt=""
      class="card-img-top"
      src="../../assets/admin-office-binder-wooden-desk-table-colored-pencil-pencils-pen-notebook-paper-79046621.jpg"
    />
    <div class="card-block">
      <!-- <img alt='' class='card-img-profile' src='../../assets/welcomeProfile.jpg' width="150px" height="150px"> -->
      <div class="text-center imageDiv">
        <img
          style="height: 100%; width: 100%; border-radius: 50%"
          src="{{ userImage }}"
          onerror="this.src='../../assets/profile.png'"
          class="main-profile-img"
        />

        <div class="iconClass" style="cursor: pointer">
          <div class="image-upload">
            <label for="file-input">
              <img
                src="../../assets/edit-image-icon-1.jpg"
                style="cursor: pointer"
                height="30px"
                width="30px"
              />
            </label>

            <input id="file-input" type="file" (change)="selectImage($event)" />
          </div>
        </div>
      </div>
      <h4 class="card-title">
        {{ firstName | titlecase }} {{ lastName | titlecase }}
        <small
          ><i class="fa fa-envelope" aria-hidden="true"></i> {{ email }}</small
        >
        <!-- <small><i class="fa fa-phone-square" aria-hidden="true"></i> {{phone}}</small> -->
        <button
          class="btn mt-3"
          (click)="openDialog()"
          style="color: #ffffff; background-color: #4a9f37; border-radius: 10px"
        >
          Edit Details
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 16px"></i>
        </button>
        <br />
        <button
          class="btn mt-3"
          (click)="editPasswordDialog()"
          style="color: #ffffff; background-color: #4a9f37; border-radius: 10px"
        >
          Change Password
          <i
            class="fas fa-shield-alt"
            aria-hidden="true"
            style="font-size: 16px"
          ></i>
        </button>
      </h4>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">{{ uploadText }}...</p>
</ngx-spinner>
