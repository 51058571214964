import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-scale',
  templateUrl: './view-scale.component.html',
  styleUrls: ['./view-scale.component.css']
})
export class ViewScaleComponent implements OnInit {

  scaleDetails;

  constructor(public dialogRef:MatDialogRef<ViewScaleComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.scaleDetails = data.scaleData;
    console.log(this.scaleDetails);
  }

  ngOnInit(): void {
  }

}
