import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from 'src/app/services/tests.service';
import { ShowSubquestionResponsesComponent } from '../show-subquestion-responses/show-subquestion-responses.component';

@Component({
  selector: 'app-user-responses',
  templateUrl: './user-responses.component.html',
  styleUrls: ['./user-responses.component.css']
})
export class UserResponsesComponent implements OnInit {

  subCategoryId;
  userId;

  testDetails = [];
  questionCount = 0;

  @ViewChild('adminCommentVal') adminCommentInput:ElementRef;
  
  constructor(
    public matDialog:MatDialog,
    private route:ActivatedRoute,
    private testsService:TestsService,
    private toast:ToastrService,
    private spinner:NgxSpinnerService
  )
  {
     this.testsService.listen().subscribe((m:any)=>{
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(Params=>{
      this.subCategoryId = Params['subCategoryId'];
      this.userId = Params['userId'];
      this.testsService.getAllUserResult({subCategory_id:this.subCategoryId,uid:this.userId}).subscribe(res=>{
        if(res["status"])
        {
          this.testDetails = res["data"];
        }
        this.spinner.hide();
      })
    });
  }

  showSubQuestionResponses(subquestions)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'show-subquestion-responses-component';
    dialogConfig.height = "1000px";
    dialogConfig.width = "1000px";
    //passing data
    dialogConfig.data = {subquestions:subquestions};
    
    const modalDialog = this.matDialog.open(ShowSubquestionResponsesComponent,dialogConfig);
  }

  checkSelectedResponse(optionAnsId,question)
  {
    const index = question.userResponse.selectedOptions.findIndex(i=>i.answerId==optionAnsId);
    if(index!=-1)
    {
      return true;
    }
    else{
      return false;
    }
  }

  getIndexAdminComment(optionAnsId,question)
  {    
    const index = question.userResponse.selectedOptions.findIndex(i=>i.answerId==optionAnsId);
    if(question.userResponse.selectedOptions[index].comment=='This is dummy text for admin comment')
    {
      question.userResponse.selectedOptions[index].comment = "";
    }
    // console.log(optionAnsId,index);
    return index;
  }

  saveAdminComment(userResponseId,selectedOptions,optionAnsId)
  {
    // if(this.adminCommentInput?.nativeElement.value!=undefined)
    // {
    //   // console.log(this.adminCommentInput?.nativeElement.value);
    //   const index = selectedOptions.findIndex(i=>i.answerId==optionAnsId);
    //   if(index!=-1)
    //   {
    //     selectedOptions[index].comment = this.adminCommentInput?.nativeElement.value;
    //   }
    // }
    console.log(userResponseId,selectedOptions,optionAnsId);
    this.testsService.addAdminComment({id:userResponseId,selectedOptions:selectedOptions}).subscribe(res=>{
      if(res["status"])
      {
        this.toast.success(res["message"]);
      }
      else{
        this.toast.error("Error Occured");
      }
      this.spinner.hide();
     },err=>{
      this.spinner.hide();
      this.toast.error(err);
    });
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.testDetails.length)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

}
