import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';

@Component({
  selector: 'app-edit-multi-question',
  templateUrl: './edit-multi-question.component.html',
  styleUrls: ['./edit-multi-question.component.css']
})
export class EditMultiQuestionComponent implements OnInit,AfterViewInit {

  categoryId;
  categoryName;
  subCategoryId;
  subCategoryName;

  allOptionScales = [];
  defaultOptionScale = "0";
  scaleId = "";
  scaleTitle = "";

  defaultQuesType = "0";

  allResponses = [{text:"",description:""}];

  questionData;

  //For always scrolling to bottom
  @ViewChildren('messages') messages: QueryList<any>;
  @ViewChild('content') content: ElementRef;

  constructor(private toast:ToastrService,private testsService:TestsService,private spinner:NgxSpinnerService,private routerBtn:Router)
  {

  }

  ngOnInit(): void {

    if(sessionStorage.getItem('editMultiQuesData')!=null)
    {
      this.questionData = JSON.parse(sessionStorage.getItem('editMultiQuesData'));
      if(typeof this.questionData.userEntry=="boolean")
      {
        this.questionData.userEntry = this.questionData.userEntry?"1":"0";
      }
      console.log(this.questionData);
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.messages.changes.subscribe(this.scrollToBottom);
  }
  
  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    } catch (err) {}
  }

  addNewResponse()
  {
    // this.allResponses.push({text:"",description:""});
    this.questionData.options.push({text:"",description:""});
  }

  addQuestion(form:NgForm)
  {
    console.log(this.questionData);

    this.questionData.id = this.questionData._id;
    this.testsService.updateMultipleQuestion(this.questionData).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);
        //return back to show screen
        this.routerBtn.navigate(['/admin/show-test-questions-multiple/'+this.questionData.subCategory_id]);
        sessionStorage.removeItem('editMultiQuesData');
      }
      else{
        this.spinner.hide();
        this.toast.error("Error Occured");
      }
     },err=>{
      console.log(err);
      this.spinner.hide();
      this.toast.error(err);
    });
  
  }

  addSubQuestions()
  {
    sessionStorage.setItem('editMultiQuesData',JSON.stringify(this.questionData));
    this.routerBtn.navigate(['/admin/edit-subquestions/']);
  }

}
