<div class="wrapper" style="margin-top: 0px">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 d-flex text-left" style="box-shadow: none">
        <h4>All Users</h4>
      </div>
      <div class="cell2"></div>
      <div class="cell2"></div>

      <div class="cell2 myCell pl-lg-2 mt-2">
        <div class="form-group">
          <span class="fa fa-search form-control-icon"></span>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="filteredStatus"
            placeholder="Search User"
          />
        </div>
      </div>
    </div>
  </div>

  <table class="table text-center" style="box-shadow: none">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Gender</th>
        <th scope="col">Age</th>
        <th scope="col">Registered On</th>
      </tr>
    </thead>
    <tbody style="color: gray; font-weight: 600">
      <tr
        *ngFor="
          let user of allUsers
            | nameFilter: filteredStatus
            | paginate: { itemsPerPage: 10, currentPage: p };
          let i = index
        "
      >
        <th scope="row">{{ i + 1 }}</th>
        <td>
          {{ user.name | titlecase }}
        </td>
        <td>{{ user.user_email == "" ? "NA" : user.user_email }}</td>
        <td>
          {{ user.gender | titlecase }}
        </td>
        <td>
          {{ user.age }}
        </td>
        <td>
          <p>{{ user.createdAt | date: "mediumDate" }}</p>
          <p style="margin-top: -20px; color: #c5c7cd; font-weight: 400">
            {{ user.createdAt | date: "shortTime" }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- pagination -->
  <pagination-controls (pageChange)="p = $event"></pagination-controls>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading user(s)...</p>
  </ngx-spinner>
</div>
