import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { TestsService } from '../services/tests.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-new-scale',
  templateUrl: './create-new-scale.component.html',
  styleUrls: ['./create-new-scale.component.css']
})
export class CreateNewScaleComponent implements OnInit {

  signupForm:FormGroup;

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<CreateNewScaleComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {

  }

  ngOnInit()
  {              
    this.signupForm = new FormGroup({
      'title' : new FormControl(null, [Validators.required]),
      'options':new FormArray([]),
      'reverse' : new FormControl('', [Validators.required]),
    });
  }

  onSubmit()
  {
    // console.log(this.signupForm.value);

    let convertedOptions = this.signupForm.value.options.map(i=>{
      return {"text":i};
    });
    // console.log(convertedOptions);

    var scaleData = {
      title:this.signupForm.value.title,
      multipleAnswers:false,
      options:convertedOptions,
      reverse:this.signupForm.value.reverse=='true'?true:false
    };
    console.log(scaleData);
    if(scaleData.options.length<2)
    {
      this.toast.error("At least 2 options needed");
    }
    else{
      this.testsService.createNewScale(scaleData).subscribe(res=>{
        if(res["status"])
        {
          this.spinner.hide();
          this.toast.success(res["message"]);      
          this.closeModel();
        }
        else{
          this.spinner.hide();
          this.toast.error(res["message"]);
        }
        },err=>{      
          console.log(err);
          this.spinner.hide();
          this.toast.error(err.error["description"]);
      });
    }  
    //resetting FORM after submitting
    // this.signupForm.reset();
  }

  onAddOptions()
  {
    const control = new FormControl(null, Validators.required);
      // typecasting into FormArray
    (<FormArray>this.signupForm.get('options')).push(control);
  }

  onRemoveOptions(index)
  {
    (<FormArray>this.signupForm.get('options')).removeAt(index);
  }

  getControls() {
    return (<FormArray>this.signupForm.get('options')).controls;
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("scale");
  }

}
