<div class="container">
    <div *ngFor="let question of allSubQuestions;let i = index;">
    <div class="row pb-4 pt-2" style="background-color: #4A9F37;" *ngIf="i==questionCount">
        <div class="col-md-3 mt-3" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="decreaseQuesNo()">
            <i class="fas fa-arrow-circle-left" style="font-size: 31px;"></i> <span > Previous Qns </span>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-4 mt-4 p-3" style="background-color: white;border-radius: 16px;">
            <p style="color:#000000;font-size:18px;font-weight: 650;"> Question {{i+1}} out of {{allSubQuestions.length}} </p>
            
            <p style="color:#4A9F37;;font-size:15px;font-weight: 600;">
                 {{question.subQuestion }}
            </p>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 mt-3 text-right" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="increaseQuesNo()">
            <span> Next Qns </span> <i class="fas fa-arrow-circle-right" style="font-size: 31px;"></i>
        </div>
    </div>   
    <div class="row text-center mt-4" *ngIf="i==questionCount">
        <div class="col-md-3"></div>
        <div class="col-md-6" *ngIf="!question.isScale && question.linkedWith!='MANUAL_ENTRY'">
            <h5 class="text-left">All Options</h5>
            <div *ngFor="let option of question.userResponseSubQuestion.selectedOptions;let j = index;">
                <p class="shadow p-3 mb-3 bg-white rounded">{{option.text}}</p>
            </div>
        </div>
        <div class="col-md-6" *ngIf="question.isScale && question.linkedWith!='MANUAL_ENTRY'">
            <h5 class="text-left">All Selected Options</h5>
            <div class="shadow p-3 mb-3 bg-white rounded" *ngFor="let option of question.userResponseSubQuestion.selectedOptions;let j = index;">
                <p style="border-radius: 50px;">
                    {{option.text}}
                    <br>
                    <!-- <progress id="file" value="32" max="100"> 32% </progress> -->
                    <br>
                    <!-- <button class="btn btn-success my-3">{{question.options[question.userResponseSubQuestion.selectedScales[j]].text | titlecase}}</button> -->
                </p>
                <div class="wrapper">
                    <div class="progress-tooltip my-2">
                        <span class="progress-tooltip-info" [ngStyle]="{left: progressValue(question.options[question.userResponseSubQuestion.selectedScales[j]].answerId,question.options)+'%'}">
                            {{question.options[question.userResponseSubQuestion.selectedScales[j]].text | titlecase}}
                        </span>
                        <progress class="progress" [value]="progressValue(question.options[question.userResponseSubQuestion.selectedScales[j]].answerId,question.options)" max="100">55%</progress>
                        <div class="row" style="font-size:15px;">
                            <div class="col-md-6 text-left">
                                {{question.options[0].text}}
                            </div>
                            <div class="col-md-6 text-right">
                                {{question.options[question.options.length-1].text}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="question.linkedWith=='MANUAL_ENTRY'">
            <h5 class="text-left">All Selected Options</h5>
            <div *ngFor="let entry of question.userResponseSubQuestion.manualEntryList;let j = index;">
                <p class="shadow p-3 mb-3 bg-white rounded" *ngIf="entry.length!=0">{{ entry | titlecase}}</p>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row text-center mt-4" *ngIf="i==questionCount && question.userEntry">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <h5 class="text-left">Manual Entry</h5>
            <div *ngFor="let entry of question.userResponseSubQuestion.manualEntry;let j = index;">
                <p class="shadow p-3 mb-3 bg-white rounded">{{entry}}</p>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>

