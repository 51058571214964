import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  private listeners = new Subject<any>();
  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(filterBy) {
    this.listeners.next(filterBy);
  }

  constructor(private http: HttpClient) {
  }

  getAllCategories(data) {
    return this.http.post(environment.baseURL + 'category/list', data);
  }

  getAllSubCategories(data) {
    return this.http.post(environment.baseURL + 'sub-category/list', data);
  }

  createNewSubCategory(params) {
    return this.http.post(environment.baseURL + "sub-category/create", params);
  }

  editSubCategory(params) {
    return this.http.post(environment.baseURL + "sub-category/update", params);
  }

  createNewCategory(params) {
    return this.http.post(environment.baseURL + "category/create", params);
  }

  editCategory(params) {
    return this.http.post(environment.baseURL + "category/update", params);
  }

  getAllQuestionCategories(data) {
    return this.http.post(environment.baseURL + 'question-category/list', data);
  }

  getAllOptionsScales(data) {
    return this.http.post(environment.baseURL + 'scale/list', data);
  }

  createNewQuestion(data) {
    return this.http.post(environment.baseURL + 'question/create', data);
  }

  updateQuestion(data) {
    return this.http.post(environment.baseURL + 'question/update', data);
  }

  updateMultipleQuestion(data) {
    return this.http.post(environment.baseURL + 'question/update-multiple', data);
  }

  getAllSubCategoryQuestions(data) {
    return this.http.post(environment.baseURL + 'question/get-all', data);
  }

  createNewQuestionCategory(data) {
    return this.http.post(environment.baseURL + 'question-category/create', data);
  }

  updateQuestionCategory(data) {
    return this.http.post(environment.baseURL + 'question-category/update', data);
  }

  createNewScale(data) {
    return this.http.post(environment.baseURL + 'scale/create', data);
  }

  updateScale(data) {
    return this.http.post(environment.baseURL + 'scale/update', data);
  }

  getAllCategoryUsersList(data) {
    return this.http.post(environment.baseURL + 'question/user-list', data);
  }

  getAllUserResult(data) {
    return this.http.post(environment.baseURL + 'question/result-user', data);
  }

  addAdminComment(data) {
    return this.http.post(environment.baseURL + 'question/admin-comment', data);
  }

  updateSubCategoryStatus(params) {
    return this.http.post(environment.baseURL + 'sub-category/update-status', params);
  }

  updateCategoryStatus(params) {
    return this.http.post(environment.baseURL + 'category/update-status', params);
  }

  updateImage(params) {
    return this.http.post(environment.baseURL + 'question-category/updateImage', params);
  }

}
