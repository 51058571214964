import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //Behaviour subject for storing authentication state
  loggedIn = new BehaviorSubject<boolean>(false);
  permissions = new BehaviorSubject<[]>([]);

  //Behaviour subject for storing the user
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private routerBtn: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('authUserData'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentUserPermissions(): any {
    const permissions = [];
    for (const [key, value] of Object.entries(this.currentUserValue)) {
      if (typeof value === 'boolean' && value) {
        permissions.push(key);
      }
    }
    return permissions;
  }

  private listeners = new Subject<any>();
  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(filterBy) {
    this.listeners.next(filterBy);
  }

  //method to send signin request to webapi
  loginUser(data) {
    return this.http.post(environment.baseURL + 'admin/login', data);
  }

  loginSubAdmin(data) {
    return this.http.post(environment.baseURL + 'login-sub-admin', data);
  }

  //method to implement auto-login functionality
  autoLogin() {
    //now we will retrieve all data from local storage , whenever the application restarts
    const authUserInfo = localStorage.getItem('authUserData');
    console.log(authUserInfo);
    //checking if that data key exists
    if (!authUserInfo) {
      return;
    } else {
      //emitting login details to BehaviourSubject
      this.loggedIn.next(true);
      this.currentUserSubject.next(JSON.parse(authUserInfo));
    }
  }

  //method to implement LOGOUT  functionality
  logout() {
    //now emitting no user  (setting our User to null)
    this.loggedIn.next(false);

    this.currentUserSubject.next(null);

    //also, removing user data from localStorage
    localStorage.removeItem('authUserData');
    localStorage.removeItem('permissions');
    localStorage.removeItem('userType');

    //redirecting to different component
    this.routerBtn.navigate(['/admin/login']);
  }

  sendToken(data) {
    return this.http.post(environment.baseURL + 'admin-send-token', data);
  }

  adminForgotPwd(data) {
    return this.http.post(environment.baseURL + 'admin-forgot-password', data);
  }

  adminDetails(data) {
    return this.http.post(environment.baseURL + 'admin/profile', data);
  }

  subAdminDetails(id) {
    return this.http.get(environment.baseURL + 'all-sub-admins/' + id);
  }

  editAdminDetails(params) {
    return this.http.post(
      environment.baseURL +
        'admin/create?email=' +
        params.email +
        '&firstName=' +
        params.firstName +
        '&lastName=' +
        params.lastName,
      params
    );
  }

  editAdminPassword(data) {
    return this.http.post(environment.baseURL + 'admin/update-password', data);
  }

  editAdminImage(params, data) {
    return this.http.post(
      environment.baseURL +
        'admin/update?email=' +
        params.email +
        '&id=' +
        params._id +
        '&firstName=' +
        params.firstName +
        '&lastName=' +
        params.lastName +
        '&dashboard=true' +
        '&userData=' +
        params.userData +
        '&testManagement=' +
        params.testManagement +
        '&dimension=' +
        params.dimension +
        '&qResponse=' +
        params.qResponse +
        '&notification=' +
        params.notification +
        '&bugReport=' +
        params.bugReport +
        '&feedback=' +
        params.feedback +
        '&reportFeedback=' +
        params.reportFeedback +
        '&faq=' +
        params.faq +
        '&about=' +
        params.about +
        '&policy=' +
        params.policy +
        '&isSuperAdmin=' +
        params.isSuperAdmin,
      data
    );
  }

  //Sub admin APIs
  getAllSubAdmins() {
    return this.http.get(environment.baseURL + 'admin/all-admins');
  }

  createSubAdmin(params) {
    return this.http.post(
      environment.baseURL +
        'admin/create?email=' +
        params.email +
        '&password=' +
        params.password +
        '&firstName=' +
        params.firstName +
        '&lastName=' +
        params.lastName +
        '&dashboard=true' +
        '&userData=' +
        params.userData +
        '&testManagement=' +
        params.testManagement +
        '&dimension=' +
        params.dimension +
        '&qResponse=' +
        params.qResponse +
        '&notification=' +
        params.notification +
        '&bugReport=' +
        params.bugReport +
        '&feedback=' +
        params.feedback +
        '&reportFeedback=' +
        params.reportFeedback +
        '&faq=' +
        params.faq +
        '&about=' +
        params.about +
        '&policy=' +
        params.policy +
        '&isSuperAdmin=' +
        params.isSuperAdmin,
      params
    );
  }

  editSubAdmin(params) {
    const queryParams = params.password
      ? 'email=' +
        params.email +
        '&password=' +
        params.password +
        '&id=' +
        params.id +
        '&firstName=' +
        params.firstName +
        '&lastName=' +
        params.lastName +
        '&dashboard=true' +
        '&userData=' +
        params.userData +
        '&testManagement=' +
        params.testManagement +
        '&dimension=' +
        params.dimension +
        '&qResponse=' +
        params.qResponse +
        '&notification=' +
        params.notification +
        '&bugReport=' +
        params.bugReport +
        '&feedback=' +
        params.feedback +
        '&reportFeedback=' +
        params.reportFeedback +
        '&faq=' +
        params.faq +
        '&about=' +
        params.about +
        '&policy=' +
        params.policy +
        '&isSuperAdmin=' +
        params.isSuperAdmin
      : 'email=' +
        params.email +
        '&id=' +
        params.id +
        '&firstName=' +
        params.firstName +
        '&lastName=' +
        params.lastName +
        '&dashboard=true' +
        '&userData=' +
        params.userData +
        '&testManagement=' +
        params.testManagement +
        '&dimension=' +
        params.dimension +
        '&qResponse=' +
        params.qResponse +
        '&notification=' +
        params.notification +
        '&bugReport=' +
        params.bugReport +
        '&feedback=' +
        params.feedback +
        '&reportFeedback=' +
        params.reportFeedback +
        '&faq=' +
        params.faq +
        '&about=' +
        params.about +
        '&policy=' +
        params.policy +
        '&isSuperAdmin=' +
        params.isSuperAdmin;
    return this.http.post(
      environment.baseURL + 'admin/update?' + queryParams,
      params
    );
  }

  deleteSubAdmin(params) {
    return this.http.post(environment.baseURL + 'admin/delete', params);
  }
}
