<div class="wrapper mt-2">
  <div class="table2 ">
    <div class="row2">
      <div class="cell2 text-center" style="box-shadow: none;">
        <h4><u>All Feedbacks </u> </h4>
      </div>
    </div>
  </div>

  <div class="table shadow p-3 mb-5" *ngIf="allFeedbacks?.length !== 0">

    <div class="row header">

      <div class="cell">
        S.No
      </div>
      <div class="cell">
        Email
      </div>
      <!-- <div class="cell">
          Description
        </div>  -->
      <div class="cell">
        Theme
      </div>
      <div class="cell">
        Created On
      </div>
      <div class="cell">
        View
      </div>

    </div>

    <div class="row" *ngFor="let report of allFeedbacks | paginate :{itemsPerPage:10 ,currentPage:p, totalItems: totalCount};let i = index;">

      <div class="cell" data-title="S.No.">
        {{i+1}}
      </div>
      <div class="cell" data-title="Email">
        {{ report.user_email }}
      </div>
      <div class="cell" data-title="Email">
        {{ report.theme }}
      </div>
      <!-- <div class="cell" data-title="Description">
            {{ report.description }}
        </div> -->
      <div class="cell" data-title="Created On">
        {{ report.createdAt | date:'medium' }}
      </div>
      <div class="cell" (click)="viewSingleFeedback(report)">
        <i class="fas fa-external-link-alt" aria-hidden="true"
          style="font-size: 17px;color:rgb(16, 107, 243);cursor: pointer;"></i>
      </div>

    </div>
  </div>

  <div class="text-center mt-5" style="margin-left: 0px;" *ngIf="allFeedbacks?.length==0">
    <h5 style="color: darkblue;">**No Feedback Exists**</h5>
  </div>
  <!-- pagination -->
  <pagination-controls (pageChange)="onPageChange($event)" *ngIf="allFeedbacks?.length!=0"></pagination-controls>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>