import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DeleteCustomerComponent } from '../delete-customer/delete-customer.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ShowDetailsComponent } from '../show-details/show-details.component';

@Component({
  selector: 'app-show-all-users',
  templateUrl: './show-all-users.component.html',
  styleUrls: ['./show-all-users.component.css'],
})
export class ShowAllUsersComponent implements OnInit {
  allUsers = [];

  filteredStatus = '';
  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    this.userService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.userService.fetchAllUsers({ skip: 0, limit: 0 }).subscribe((res) => {
      this.allUsers = res['data'];
      this.spinner.hide();
      console.log(this.allUsers);
    });
  }

  showDetails(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'show-details-component';
    dialogConfig.height = '430px';
    dialogConfig.width = '500px';
    //passing data
    dialogConfig.data = { user: user };

    const modalDialog = this.matDialog.open(ShowDetailsComponent, dialogConfig);
  }

  suspendUser(userId) {}

  unblockUser(userId, blockStatus) {
    if (blockStatus == 1) {
      this.toast.error('User is already suspended', '', {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right',
      });
    } else if (blockStatus == 2) {
      this.toast.error('User is already blocked', '', {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right',
      });
    }
  }

  openDeleteDialog(userId) {
    const dialogConfig = new MatDialogConfig();
    //if the user clicks outside the modal, it doesn’t close
    dialogConfig.disableClose = true;
    dialogConfig.id = 'del-module-component';
    dialogConfig.height = '190px';
    dialogConfig.width = '580px';
    //passing data
    dialogConfig.data = { userId: userId };

    const modalDialog = this.matDialog.open(
      DeleteCustomerComponent,
      dialogConfig
    );
  }

  openEditDialog(user) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-user-component';
    dialogConfig.height = '270px';
    dialogConfig.width = '460px';
    //passing data
    dialogConfig.data = { user: user };

    const modalDialog = this.matDialog.open(EditUserComponent, dialogConfig);
  }
}
