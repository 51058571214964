<div class="text-center mt-5">
  <h3 style="color:darkblue"><u>Edit Category </u></h3>
</div>
<div class="shadow p-3 m-4 bg-white rounded">
  <div class="container px-2" class="z-index:10; ">
    <form (ngSubmit)="onSubmit(form)" #form="ngForm" class="ml-lg-5 pl-lg-5">

      <div class="form-group row">

        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Name of Category : </label>
          <input type="text" class="form-control" placeholder="Enter New Category" name="category"
            [(ngModel)]="categoryDetails.category" #categoryRef="ngModel" required>
          <div *ngIf="categoryRef.errors?.required  && categoryRef.touched" class="errorDiv">
            *Category Name is required!
          </div>
        </div>
        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Question : </label>
          <input type="text" class="form-control" placeholder="Enter Category Title" name="title"
            [(ngModel)]="categoryDetails.title" #titleRef="ngModel" required>
          <div *ngIf="titleRef.errors?.required  && titleRef.touched" class="errorDiv">
            *Category Title is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Change Image : </label>
          <input type="file" class="form-control" name="bannerImage" (change)="selectImage($event)" ngModel
            #bannerRef="ngModel">
          <div *ngIf="bannerRef.errors?.required  && bannerRef.touched" class="errorDiv">
            *Category Image is required!
          </div>
        </div>
        <div class="col-sm-5"></div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Short Description : </label>
          <textarea rows="3" class="form-control" placeholder="Enter Short Description" name="preDescription"
            [(ngModel)]="categoryDetails.preDescription" #shortDescriptionRef="ngModel" required></textarea>
          <div *ngIf="shortDescriptionRef.errors?.required  && shortDescriptionRef.touched" class="errorDiv">
            *Short Description is required!
          </div>
        </div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Answer : </label>
          <!-- <textarea class="form-control" rows="7" placeholder="Enter Category Description" name="description" [(ngModel)]="categoryDetails.description" #descriptionRef="ngModel" required></textarea> -->
          <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText"
            [preserveWhitespace]="false" name="description" ngModel #descriptionRef="ngModel">
          </quill-editor>
          <div *ngIf="descriptionRef.errors?.required  && descriptionRef.touched" class="errorDiv">
            *Category Description is required!
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-10 text-center">
          <img src={{categoryDetails.imageUrl}} onerror="this.src='../../assets/personalityTest.jpg'" height="172px"
            width="auto" alt="">
        </div>
      </div>
      <br>

      <div class="form-group row">
        <div class="col-sm-10 text-center">
          <button type="submit" class="btn" [disabled]="!form.valid"
            style="background-color: #4A9F37; width: 200px;border-radius: 10px; color: white;">Save Changes</button>
        </div>
      </div>
    </form>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Updating...</p>
</ngx-spinner>