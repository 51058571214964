
<div class="wrapper " style="margin-top: 0px;">
    <div class="table2 " style="margin-bottom: 10px;">
                                           
        <div class="row2">
      
        <div class="cell2 d-flex text-left" style="box-shadow: none;">
            <h4> All Sugestions</h4>
        </div>
        <div class="cell2"></div>
      
        <div class="cell2 myCell pl-lg-2 mt-2" >
          <div class="form-group">
            <span class="fa fa-search form-control-icon"></span>
            <input type="text" class="form-control" [(ngModel)]="filteredStatus" placeholder="Search Suggestion">   
          </div>
        </div>   
       
        </div>
      </div> 

    <table class="table text-center" style="box-shadow: none;">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">User ID</th>    
            <th scope="col">User Name</th>    
            <th scope="col">Topic</th>    
            <th scope="col">Date</th>           
            
          </tr>
        </thead>
        <tbody  style="color:gray;font-weight: 600;">
          <tr *ngFor="let suggestion of allSuggestions | nameFilter : filteredStatus| paginate :{itemsPerPage:5,currentPage:p};let i = index;">
            <th scope="row">{{i+1}}</th>
            <td>{{suggestion.userId}}</td>
            <td>{{suggestion.userName | titlecase}}</td>
            <td>{{suggestion.topicText}}</td>
            <td >
              <p>{{ suggestion.creationTimeStamp | date:'mediumDate' }}</p>              
              <p style="margin-top: -20px;color: #C5C7CD;font-weight: 400;">{{ suggestion.creationTimeStamp | date:'shortTime' }}</p>
            </td>            

          </tr>
    
        </tbody>
      </table>

   <!-- pagination -->
   <pagination-controls (pageChange)="p=$event"></pagination-controls>
   
  
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading Suggestion(s)...</p>
</ngx-spinner>
