import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) {
  }

  getUserCounts(data) {
    return this.http.post(environment.baseURL + 'dashboard/summary', data);
  }

  getUserGraph(data) {
    return this.http.post(environment.baseURL + 'dashboard/get-users', data);
  }

}
