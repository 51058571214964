import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-ques-category',
  templateUrl: './view-ques-category.component.html',
  styleUrls: ['./view-ques-category.component.css']
})
export class ViewQuesCategoryComponent implements OnInit {
  
  quesCategoryDetails;

  constructor(public dialogRef:MatDialogRef<ViewQuesCategoryComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.quesCategoryDetails = data.quesCategoryData;
  }

  ngOnInit(): void {
  }

}
