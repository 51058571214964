<div class="container">
    <hr>    
    <form (submit)="editQuestion(form)" #form="ngForm" >
        <div class="row mt-4">
            <div class="col-md-5">
                <label> Question Text: </label>
            </div>
            <div class="col-md-7">
                <input type="text" class="form-control" name="questionText" [(ngModel)]="questionDetails.questionText" required placeholder="Enter Question Title....">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-5">
                <label> Associated Dimension: </label>
            </div>
            <div class="col-md-7">
                <select class="select-dropdown form-control" name="questionCategory" [(ngModel)]="defaultQuestionCategory">
                    <option value="0" disabled>Select Category</option>
                    <option [ngValue]="categ" *ngFor="let categ of allQuesCategories">{{categ.questionCategory | titlecase}}</option>                     
                </select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-5">
                <label> Q-Response Type: </label>
            </div>
            <div class="col-md-7">
                <select class="select-dropdown form-control" name="options" required [(ngModel)]="defaultOptionScale" #myOptions 
                (change)='onSelectedScale(myOptions.value)'>
                    <option value="0" disabled>Select Scale</option>
                    <option [ngValue]="scale.options" *ngFor="let scale of allOptionScales">{{scale.title | titlecase}}</option>
                </select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-5">
                <label> Q-Response format: </label>
            </div>
            <div class="col-md-7">
                <select class="select-dropdown form-control" name="questionType" required [(ngModel)]="defaultQuesType">
                    <option value="0" disabled>Select Question Type</option>
                    <option value="1">List Format</option>
                    <option value="2">Line Format</option>
                </select>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <button type="submit" [disabled]="!form.valid" class="btn btn-block btn-success">Save Changes</button>
            </div>
        </div>
    </form>
    <hr>
</div>
