import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-show-policies',
  templateUrl: './show-policies.component.html',
  styleUrls: ['./show-policies.component.css'],
})
export class ShowPoliciesComponent implements OnInit {
  legalData;

  constructor(
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.contentDataService.getLegalData({}).subscribe((res) => {
      this.legalData = res['policyAndConditionsData'];
    });
  }

  updatePolicyURL(policyType) {
    let legalUpdatedData = {
      id: this.legalData._id,
      conditionsUrl: this.legalData.conditionsUrl,
      policyUrl: this.legalData.policyUrl,
    };
    console.log(legalUpdatedData);
    this.contentDataService.updateLegalData(legalUpdatedData).subscribe(
      (res) => {
        if (res['status']) {
          this.spinner.hide();
          this.toast.success(res['description']);
        } else {
          this.spinner.hide();
          this.toast.error(res['description']);
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error['description']);
      }
    );
  }
}
