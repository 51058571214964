<div class="wrapper mt-2">
    <div class="table2 " >                                           
      <div class="row2">
      
        <div class="cell2"></div>
        <div class="cell2"></div>
        <div class="cell2 text-right" style="box-shadow: none;">
            <h4><u>All FAQs </u> </h4>
        </div>
        <div class="cell2 text-lg-right" style="box-shadow:none;border-radius:12px">
          <button (click)="addNewFAQ()" style="background-color: #4A9F37;border-radius: 8px;color: white;" class="btn mb-1"><i class="fas fa-plus"></i> Add Question</button>
        </div>

      </div>
    </div>

    <div class="table shadow p-3 mb-5" *ngIf="allFaqs.length!=0">
      
      <div class="row header" >
        
        <div class="cell">
          S.No
        </div>
        <div class="cell">
          Title
        </div> 
        <div class="cell">
          Created On
        </div>
        <div class="cell">
          
        </div>
        <div class="cell">
          
        </div>
      </div>
      
      <div class="row" *ngFor="let faq of allFaqs | paginate :{itemsPerPage:8,currentPage:p};let i = index;">
      
        <div class="cell" data-title="S.No.">
            {{i+1}}
        </div>
        <div class="cell" data-title="Title">
            {{ faq.title }}
        </div>
        <div class="cell" data-title="Created On">
            {{ faq.createdAt | date:'medium' }}
        </div>
        <div class="cell" (click)="editFaq(faq)">
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;color:darkgreen;cursor: pointer;"></i>
        </div>       
        <div class="cell" style="cursor:pointer;">
            <i class="fas fa-trash" aria-hidden="true" style="font-size: 17px;color:rgb(192, 31, 31);cursor: pointer;"></i>
       </div>
             
      </div>          
    </div>

    <div class="text-center mt-5" style="margin-left: -150px;" *ngIf="allFaqs.length==0">
      <h5 style="color: darkblue;">**No FAQ Exists**</h5>
    </div>
    <!-- pagination -->
    <pagination-controls (pageChange)="p=$event" *ngIf="allFaqs.length!=0"></pagination-controls>
 </div> 

  
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>
