import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ProfileComponent } from '../profile/profile.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css'],
})
export class EditAdminComponent implements OnInit {
  adminEmail;
  admin: any;
  showPassword: boolean;

  constructor(
    private authService: AuthService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.adminEmail = data.adminEmail;
    console.log(this.adminEmail);
  }

  ngOnInit(): void {
    this.showPassword = false;
    this.authService
      .adminDetails({ email: this.adminEmail })
      .subscribe((res) => {
        this.admin = res['data'];
        console.log('Admin : ', this.admin);
      });
  }

  onSubmit(form: NgForm) {
    var newForm = { ...this.admin, ...form.value, id: this.admin._id };
    delete newForm.password;
    this.authService.editSubAdmin(newForm).subscribe((res) => {
      console.log('Result : ', res);
      if (res['status']) {
        localStorage.setItem('authUserData', JSON.stringify(res['data']));
        this.authService.loggedIn.next(true);
        // alert("Client Details Updated");
        this.toast.success('Admin Details Updated', 'Successs', {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right',
        });
        this.closeModel();
      } else {
        this.toast.error('Details not updated....error occured', 'Error', {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right',
        });
      }
    });
  }

  password() {
    this.showPassword = !this.showPassword;
  }

  closeModel() {
    this.dialogRef.close();
    this.authService.filter('Deleted');
  }
}
