import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sub-admin-list',
  templateUrl: './sub-admin-list.component.html',
  styleUrls: ['./sub-admin-list.component.css'],
})
export class SubAdminListComponent implements OnInit {
  allSubAdmins: any[];
  p: number = 1;
  loaderText = 'Loading sub admin(s)....';
  selectedAdmin;
  currentUser;

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    this.auth
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.auth.logout();
          this.toastr.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.getAllSubAdmins();
  }

  getAllSubAdmins() {
    this.loaderText = 'Loading sub admin(s)....';
    this.spinner.show();
    this.auth.getAllSubAdmins().subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.allSubAdmins = res.data;
        }
      },
      (errors: any) => {
        this.spinner.hide();
      }
    );
  }

  createSubAdmin() {
    this.router.navigateByUrl('admin/sub-admin/save');
  }

  editSubAdmin(admin) {
    this.router.navigateByUrl('admin/sub-admin/save/' + admin._id);
  }

  setDeleteAdmin(admin) {
    this.selectedAdmin = admin;
  }

  deleteSubAdmin() {
    this.loaderText = 'Sub admin deleting....';
    this.spinner.show();
    this.auth.deleteSubAdmin({ id: this.selectedAdmin._id }).subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.selectedAdmin = null;
          document.getElementById('closeModal').click();
          this.toastr.success(res.description, 'Successs', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
          this.getAllSubAdmins();
        }
      },
      (errors: any) => {
        this.spinner.hide();
      }
    );
  }
}
