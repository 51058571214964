import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';
import { EditQuestionDialogComponent } from '../edit-question-dialog/edit-question-dialog.component';
import { AddQuestionDialogComponent } from '../add-question-dialog/add-question-dialog.component';

@Component({
  selector: 'app-show-questions',
  templateUrl: './show-questions.component.html',
  styleUrls: ['./show-questions.component.css']
})
export class ShowQuestionsComponent implements OnInit {

  subCategoryId;

  testDetails;
  questionCount = 0;
  
  constructor(public matDialog:MatDialog,private route:ActivatedRoute,private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService)
  {
     this.testsService.listen().subscribe((m:any)=>{
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.spinner.show();

    this.route.params.subscribe(Params=>{
      this.subCategoryId = Params['subCategoryId'];
      console.log(this.subCategoryId);

      this.testsService.getAllSubCategoryQuestions({subCategory_id:this.subCategoryId}).subscribe(res=>{
        if(res["status"])
        {
          if(res["questions"].length!=0)
          {
            this.testDetails = res["questions"];
            console.log(this.testDetails);
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
            this.toast.error("No Questions exists for this Sub-Category");
          }
        }
        else{
          this.spinner.hide();
          this.toast.error("Error Occured");
        }
      });
      
    });
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.testDetails.length)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

  editQuestion(quesObj,index)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'edit-question-dialog-component';
    dialogConfig.height = "440px";
    dialogConfig.width = "490px";
    //passing data
    dialogConfig.data = {questionDetails:quesObj,quesNo:index+1};
    
    const modalDialog = this.matDialog.open(EditQuestionDialogComponent,dialogConfig);
  }

  addNewTestQuestions(quesObj,index)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'add-question-dialog-component';
    dialogConfig.height = "200px";
    dialogConfig.width = "445px";
    //passing data
    dialogConfig.data = {lastQuesDetails:quesObj,lastQuesNo:index+1,testDetails:this.testDetails};
    
    const modalDialog = this.matDialog.open(AddQuestionDialogComponent,dialogConfig);
  }

}
