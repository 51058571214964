import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { AddQuestionDialogComponent } from '../add-question-dialog/add-question-dialog.component';

@Component({
  selector: 'app-all-sub-categories',
  templateUrl: './all-sub-categories.component.html',
  styleUrls: ['./all-sub-categories.component.css']
})
export class AllSubCategoriesComponent implements OnInit {

  categoryId;
  allSubCategories = [];
  categoryName;
  subCategoryName;
  
  p:number =1;

  statuses = [
    { name: 'Soon1', value: 'SOON1' },
    { name: 'Soon2', value: 'SOON2' },
    { name: 'Live', value: 'LIVE' },
    { name: 'Deactivate', value: 'DEACTIVATE' },
  ];

  constructor(public matDialog:MatDialog,private route:ActivatedRoute,private testsService:TestsService,private routerBtn:Router,private spinner:NgxSpinnerService,private toast:ToastrService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(Params=>{
      this.categoryId = Params['id'];

      //Fetching all Sub-Categories
      this.testsService.getAllSubCategories({category_id:this.categoryId}).subscribe(res=>{
        this.allSubCategories = res["subCategoryData"];
        console.log(this.allSubCategories);
        this.testsService.getAllCategories({}).subscribe(resCat=>{
          const index = resCat["categoryData"].findIndex(i=>i.category_id==this.categoryId);
          if(index!=-1)
          {
            this.categoryName = resCat["categoryData"][index].category;
            // console.log("Category Name : ",this.categoryName);
            
            this.spinner.hide();
          }
        })
      });
    });
  }

  showTest(subCategoryId,isMultiple)
  {
    console.log(subCategoryId,isMultiple);
    this.testsService.getAllSubCategoryQuestions({subCategory_id:subCategoryId,multipleAnswers:isMultiple}).subscribe(res=>{
      console.log(res);
      if(res["status"])
      {
        if(!isMultiple)
        {          
          if(res["questions"].length!=0)
          {
            this.routerBtn.navigate(['/admin/show-test-questions/'+subCategoryId]);
          }
          else{
            // this.toast.error("No Questions exists for this Sub-Category");
            //get subcatoryName
            const index = this.allSubCategories.findIndex(i=>i.subCategory_id==subCategoryId);
            if(index!=-1)
            {
              this.subCategoryName = this.allSubCategories[index].subCategory;
            }
            var quesObj = {
              category_id:this.categoryId,
              category:this.categoryName,
              subCategory_id:subCategoryId,
              subCategory:this.subCategoryName
            };
            console.log(quesObj);
            this.addNewTestQuestions(quesObj,-1,res["questions"]);
          }
        }
        else{
          console.log("For Multi Question");
          if(res["questions"].length!=0)
          {
            this.routerBtn.navigate(['/admin/show-test-questions-multiple/'+subCategoryId]);
          }
          else{
            //get subcatoryName
            // const index = this.allSubCategories.findIndex(i=>i.subCategory_id==subCategoryId);
            // if(index!=-1)
            // {
            //   this.subCategoryName = this.allSubCategories[index].subCategory;
            // }
            // var quesObj = {
            //   category_id:this.categoryId,
            //   category:this.categoryName,
            //   subCategory_id:subCategoryId,
            //   subCategory:this.subCategoryName
            // };
            // console.log(quesObj);
            // this.addNewTestQuestions(quesObj,-1,res["questions"]);
          }
        }
      }
      else{
        this.toast.error("Error Occured");
      }
    })
  }

  showUserResponses(subCategoryId,isMultiple)
  {
    console.log(subCategoryId,isMultiple);
    this.routerBtn.navigate(['/admin/participated-users/'+subCategoryId]);
  }

  addNewTestQuestions(quesObj,index,testDetails)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'add-question-dialog-component';
    dialogConfig.height = "200px";
    dialogConfig.width = "445px";
    //passing data
    dialogConfig.data = {lastQuesDetails:quesObj,lastQuesNo:index+1,testDetails:testDetails};
    
    const modalDialog = this.matDialog.open(AddQuestionDialogComponent,dialogConfig);
  }

  addNewTest()
  {
    this.routerBtn.navigate(['/admin/test-details/'+this.categoryId]);
  }

  onStatusChange(e, id) {
    this.testsService.updateSubCategoryStatus({id, testStatus: e.target.value}).subscribe((res: any) => {
      if(res.status) {
        this.toast.success('Status updated successfully!');
      }
    }, error => {
      this.toast.error("Error Occured");
    });
  }

}

