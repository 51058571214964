import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-show-about-image',
  templateUrl: './show-about-image.component.html',
  styleUrls: ['./show-about-image.component.css']
})
export class ShowAboutImageComponent implements OnInit {

  aboutId;
  aboutImage;
  aboutNewImage;

  constructor(private contentDataService: ContentdataService, private toast: ToastrService, private spinner: NgxSpinnerService, public dialogRef: MatDialogRef<ShowAboutImageComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.aboutId = data.aboutId;
    this.aboutImage = environment.baseURL + "app/download" + data.aboutImage;
    console.log(this.aboutId, this.aboutImage);
  }

  ngOnInit(): void {
  }

  selectImage(event) {
    const file = event.target.files[0];
    this.aboutNewImage = file;
    console.log(this.aboutNewImage);

    this.spinner.show();

    const formData = new FormData();
    formData.append("photo", this.aboutNewImage);

    if (this.aboutNewImage != null) {
      this.contentDataService.updateAboutImage(this.aboutId, formData).subscribe(res => {
        if (res["status"]) {
          this.spinner.hide();
          this.toast.success(res["description"]);
          this.closeModel();
        }
        else {
          this.spinner.hide();
          this.toast.error(res["description"]);
        }
      });
    }

  }

  closeModel() {
    this.dialogRef.close();
    this.contentDataService.filter("scale");
  }

}
