<div class="wrapper mt-2">
    <div class="table2 " >                                           
      <div class="row2">      
        <div class="cell2">
            <button (click)="showAboutAppData()" class="btn" [ngClass]="{selectedClass: showAboutApp==true,nonSelectedClass:showAboutApp==false}">About App</button>
          </div>
          <div class="cell2">
              <button (click)="showAboutFounderData()" class="btn" [ngClass]="{selectedClass: showAboutApp==false,nonSelectedClass:showAboutApp==true}">About Founder</button>
          </div>
          <div class="cell2">
            <button (click)="addNewAboutContent()" class="btn btn-primary btn-block">Add New Content</button>
        </div>
      </div>
      <div class="row2">      
        <div class="cell2">
        </div>
        <div class="cell2">
        </div>
        <div class="cell2">
            <button (click)="showAboutImage()" class="btn btn-block" style="background-color: orange;color: white;">View Image</button>
        </div>
      </div>
    </div> 

    <div class="table shadow p-3 mb-5" *ngIf="aboutContents.length!=0">
      
      <div class="row header" >
        
        <div class="cell">
          S.No
        </div>
        <div class="cell">
          Title
        </div> 
        <div class="cell">
          Description
        </div>        
        <div class="cell">
          
        </div>
        <div class="cell">
          
        </div>
      </div>
      
      <div class="row" *ngFor="let opScale of aboutContents | paginate :{itemsPerPage:8,currentPage:p};let i = index;">
      
        <div class="cell" data-title="S.No.">
            {{i+1}}
        </div>
        <div class="cell" data-title="Title">
            {{ opScale.title }}
        </div>
        <div class="cell" data-title="Description">
            {{ opScale.newDescription }}
        </div>
        <div class="cell" (click)="editAboutDetails(opScale)">
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;color:darkgreen;cursor: pointer;"></i>
        </div>       
        <div class="cell" style="cursor:pointer;">
            <i class="fas fa-trash" aria-hidden="true" style="font-size: 17px;color:rgb(192, 31, 31);cursor: pointer;"></i>
       </div>
             
      </div>          
    </div>

    <div class="text-center mt-5" style="margin-left: -100px;" *ngIf="aboutContents.length==0">
      <h5 style="color: darkblue;">**No About Content Exists**</h5>
    </div>
    <!-- pagination -->
    <pagination-controls (pageChange)="p=$event" *ngIf="aboutContents.length!=0"></pagination-controls>
 </div> 

  
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>
