<div class="container">
    <div *ngFor="let question of testDetails;let i = index;">
    <div class="row pb-4 pt-2" style="background-color: #4A9F37;" *ngIf="i==questionCount">
        <div class="col-md-3 mt-3" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="decreaseQuesNo()">
            <i class="fas fa-arrow-circle-left" style="font-size: 31px;"></i> <span > Previous Qns </span>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-4 mt-4 p-3" style="background-color: white;border-radius: 16px;">
            <p style="color:#000000;font-size:18px;font-weight: 650;"> Question {{i+1}} out of {{testDetails.length}} </p>
            
            <p style="color:#4A9F37;;font-size:15px;font-weight: 600;">
                 {{question.questionText }}
            </p>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 mt-3 text-right" style="color: #FFFFFF;font-weight: 500;cursor: pointer;" (click)="increaseQuesNo()">
            <span> Next Qns </span> <i class="fas fa-arrow-circle-right" style="font-size: 31px;"></i>
        </div>
    </div>   
    <div class="row text-center mt-4" *ngIf="i==questionCount">
        <div class="col-md-4 text-lg-left mt-lg-4">
            <div class="row mt-4" >
                <div class="col-md-12">
                    <button class="btn" (click)="showSubQuestionResponses(question.subQuestion)" style="border-radius: 8px;background-color: rgb(60, 154, 241);color: white;">
                        <i class="fas fa-plus"></i> Show SubQuestions Responses
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <h5 class="text-left">All Options</h5>
            <div *ngFor="let option of question.options;let j = index;">
                <div class="row">
                    <div class="col-md-6">
                        <p class="shadow p-3 mb-3 bg-white rounded" [ngClass]="{'correctAns':checkSelectedResponse(option.answerId,question)}" >{{option.text}}</p>
                    </div>
                    <div class="col-md-5">
                        <div *ngIf="checkSelectedResponse(option.answerId,question)">
                            <div *ngIf="question.userResponse.adminComment; else noComment">
                                <!-- <div *ngIf="question.userResponse.selectedOptions[getIndexAdminComment(option.answerId,question)].comment=='This is dummy text for admin comment';else prefilledComment"> -->
                                    <input type="text" placeholder="Enter Admin Comment" name="adminComment{{j}}" [(ngModel)]="question.userResponse.selectedOptions[getIndexAdminComment(option.answerId,question)].comment"
                                    class="form-control mt-2" style="height: 45px;" #adminCommentVal>
                                <!-- </div>
                                <ng-template #prefilledComment>
                                    <input type="text" placeholder="Enter Admin Comment" name="adminComment{{j}}" [(ngModel)]="question.userResponse.selectedOptions[getIndexAdminComment(option.answerId,question)].comment"
                                    class="form-control mt-2" style="height: 45px;">
                                </ng-template> -->
                                
                            </div>
                            <ng-template #noComment>
                                <input type="text" placeholder="Enter Admin Comment" name="adminComment{{j}}" ngModel
                                class="form-control mt-2" style="height: 45px;">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-1" *ngIf="checkSelectedResponse(option.answerId,question)">
                        <button class="btn btn-primary" style="margin-top: 12px;" type="button" (click)="saveAdminComment(question.userResponse._id,question.userResponse.selectedOptions,option.answerId)">
                            <i class="fas fa-check-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- <div class="col-md-4">
            <div *ngFor="let commentData of question.userResponse.selectedOptions;let m = index;">
                <div *ngIf="question.userResponse.adminComment; else noComment">
                    <input type="text" placeholder="Enter Admin Comment" name="adminComment{{m}}" [(ngModel)]="commentData.comment"
                    class="form-control mt-2" style="height: 45px;">
                </div>
                <ng-template #noComment>
                    <input type="text" placeholder="Enter Admin Comment" name="adminComment{{m}}" ngModel
                    class="form-control mt-2" style="height: 45px;">
                </ng-template>
            </div>
        </div> -->
    </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>


<!-- <div class="wrapper">
    <div class="progress-tooltip my-2">
      <span class="progress-tooltip-info" style="left: 55%;">55%</span>
      <progress class="progress" value="55" max="100">55%</progress>
    </div>
</div> -->
