<div *ngIf="isLoggedIn">
  <div class="d-flex" id="wrapper">
    <!-- Sidebar -->
    <div style="border-right: 2px solid #d3d3d3" id="sidebar-wrapper">
      <div class="sidebar-heading text-center my-lg-1">
        <img src="../assets/flourish.png" width="100px" height="105px" alt="" />
        <br />
        Flourish Thyself
      </div>
      <div
        class="list-group list-group-flush mt-lg-2 mySideBar"
        style="overflow-y: auto; height: 540px"
      >
        <a
          routerLink="/admin/dashboard"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.dashboard"
        >
          <i class="fas fa-th-large"></i> &nbsp; Dashboard</a
        >
        <a
          routerLink="/admin/all-users"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.userData"
          ><i class="fas fa-users"></i> &nbsp;User data</a
        >
        <a
          routerLink="/admin/all-tests"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.testManagement"
          ><i class="fas fa-envelope-open-text"></i> &nbsp; Test Management</a
        >
        <a
          routerLink="/admin/all-quescategories"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.dimension"
          ><i class="fas fa-history"></i> &nbsp; Dimension/report management</a
        >
        <a
          routerLink="/admin/all-scales"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.qResponse"
          ><i class="fab fa-quinscape"></i> &nbsp; Q-Response type management</a
        >
        <a
          routerLink="/admin/send-notifications"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.notification"
          ><i class="fas fa-bell"></i> &nbsp; Notifications management</a
        >
        <a
          routerLink="/admin/sub-admins"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.isSuperAdmin"
          ><i class="fas fa-users"></i> &nbsp;Sub admins</a
        >
        <a
          routerLink="/admin/waitlist"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.isSuperAdmin"
          ><i class="fa fa-quote-right" aria-hidden="true"></i> &nbsp;Waitlist</a
        >
        <a
          routerLink="/admin/track-quotes"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.isSuperAdmin"
          ><i class="fa fa-quote-right" aria-hidden="true"></i> &nbsp;Track quotes</a
        >
        <a
          routerLink="/admin/all-reports"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.bugReport"
          ><i class="fas fa-flag-checkered"></i> &nbsp; Bug report management</a
        >
        <a
          routerLink="/admin/all-feedbacks"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.feedback"
          ><i class="fas fa-comment-alt"></i> &nbsp; Feedback</a
        >
        <a
          routerLink="/admin/all-features"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.feedback"
          ><i class="fas fa-comment-alt"></i> &nbsp; Features</a
        >
        <a
          routerLink="/admin/report-feedbacks"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.reportFeedback"
          ><i class="far fa-window-restore"></i> &nbsp; Report Feedback</a
        >
        <a
          routerLink="/admin/all-faqs"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.faq"
          ><i class="fas fa-question-circle"></i> &nbsp; FAQ management</a
        >
        <a
          routerLink="/admin/about-details"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.about"
          ><i class="fas fa-address-card"></i> &nbsp;About management</a
        >
        <a
          routerLink="/admin/show-policies"
          routerLinkActive="selected"
          style="font-size: 17px; width: 100%; padding: 13px"
          class="pl-lg-4"
          *ngIf="adminDetails?.policy"
          ><i class="fas fa-file-pdf"></i> &nbsp; Policy management</a
        >
      </div>
    </div>
    <!-- /#sidebar-wrapper -->
    <!-- Page Content -->
    <div id="page-content-wrapper">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light1"
        style="border-bottom: 2px solid lightgray"
      >
        <div class="text-center pl-lg-5 py-lg-2">
          <h2 class="myText">Admin Panel</h2>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          (click)="toggleNavbar = !toggleNavbar"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <!-- <a class="nav-link" style="font-weight: 600;" routerLink="/admin/dashboard">Home <span class="sr-only">(current)</span></a> -->
            </li>

            <li class="nav-item dropdown">
              <a
                *ngIf="adminId != undefined"
                style="font-weight: 600; color: black"
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ firstName | titlecase }} {{ lastName | titlecase }}
                <img
                  src="{{ adminImage }}"
                  onerror="this.src='../assets/welcomeProfile.jpg'"
                  style="border-radius: 10px"
                  height="30px"
                  width="36px"
                />
              </a>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <a
                  class="dropdown-item"
                  style="font-weight: 600; color: black"
                  routerLink="/admin/profile"
                  ><i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp;
                  Profile
                </a>
                <div class="dropdown-divider"></div>
                <a
                  (click)="logout()"
                  class="dropdown-item"
                  href="#"
                  style="font-weight: 600; color: black"
                  ><i class="fa fa-info-circle" aria-hidden="true"></i> &nbsp;
                  Logout</a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <router-outlet></router-outlet>

              <!-- <canvas baseChart 
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</div>
<div *ngIf="!isLoggedIn">
  <app-login-user></app-login-user>
</div>
