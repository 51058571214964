import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from '../services/tests.service';
import { ShowSubQuestionsDialogComponent } from '../show-sub-questions-dialog/show-sub-questions-dialog.component';

@Component({
  selector: 'app-show-question-multiple',
  templateUrl: './show-question-multiple.component.html',
  styleUrls: ['./show-question-multiple.component.css']
})
export class ShowQuestionMultipleComponent implements OnInit {

  subCategoryId;

  testDetails;
  questionCount = 0;
  
  constructor(public matDialog:MatDialog,private routerBtn:Router,private route:ActivatedRoute,private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService)
  {
     this.testsService.listen().subscribe((m:any)=>{
      console.log(m);
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    // this.spinner.show();

    this.route.params.subscribe(Params=>{
      this.subCategoryId = Params['subCategoryId'];
      console.log(this.subCategoryId);

      this.testsService.getAllSubCategoryQuestions({subCategory_id:this.subCategoryId,multipleAnswers:true}).subscribe(res=>{
        console.log(res);
        if(res["status"])
        {
          if(res["questions"].length!=0)
          {
            this.testDetails = res["questions"];
            console.log(this.testDetails);
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
            this.toast.error("No Questions exists for this Sub-Category");
          }
        }
        else{
          this.spinner.hide();
          this.toast.error("Error Occured");
        }
      });
      
    });
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.testDetails.length)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

  showSubQuestions(subQuestion)
  {
    if(subQuestion.length!=0)
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.id = 'show-sub-questions-dialog-component';
      dialogConfig.height = '100vh';
      dialogConfig.width = "630px";
      //passing data
      dialogConfig.data = {subQuestion:subQuestion};
      
      const modalDialog = this.matDialog.open(ShowSubQuestionsDialogComponent,dialogConfig);
    }
    else{
      this.toast.error("No Sub-Question exists");
    }   
  }

  editMultiQuestion(quesData)
  {
    console.log(quesData);
    sessionStorage.setItem('editMultiQuesData',JSON.stringify(quesData));
    this.routerBtn.navigate(['admin/edit-test-questions-multiple/'+quesData._id]);
  }

}
