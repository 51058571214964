<div class="container text-center">
    <hr class="my-3">
    <div class="row">
        <div class="col-md-6">
            <label for="">Title : </label>
        </div>
        <div class="col-md-6">
            {{scaleDetails.title | titlecase}}
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <label for="">Options : </label>
        </div>
        <div class="col-md-6 my-2">
            <div class="row" *ngFor="let op of scaleDetails.options">
                <div class="col-md-12">
                    {{op.text | titlecase}}
                </div>
            </div>
        </div>
    </div>
    <hr class="my-3">
</div>