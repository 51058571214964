<div class="wrapper mt-2">
  <div class="table2 ">
    <div class="row2">

      <div class="cell2 text-right" style="box-shadow: none;">
        <h4><u>Tests in {{categoryName}} </u> </h4>
      </div>
      <div class="cell2 text-lg-right" style="box-shadow:none;border-radius:12px">
        <button (click)="addNewTest()" style="background-color: #4A9F37;border-radius: 8px;color: white;"
          class="btn mb-1"><i class="fas fa-plus"></i> Add Test</button>
      </div>

    </div>
  </div>

  <div class="table shadow p-3 mb-5" *ngIf="allSubCategories.length!=0">

    <div class="row header">

      <div class="cell">
        S.No
      </div>
      <div class="cell">
        Name of Test
      </div>
      <div class="cell">
        Curios Question
      </div>
      <div class="cell">
        Edit
      </div>
      <div class="cell" style="width: 10%;">
        Status
      </div>
      <div class="cell" >
        Test
      </div>
      <div class="cell">
        User Responses
      </div>
    </div>

    <div class="row" *ngFor="let subCat of allSubCategories | paginate :{itemsPerPage:8,currentPage:p};let i = index;">

      <div class="cell" data-title="S.No.">
        {{i+1}}
      </div>
      <div class="cell" data-title="Name of Test">
        {{ subCat.subCategory }}
      </div>
      <div class="cell" data-title="Curios Question">
        {{ subCat.title }}
      </div>
      <div class="cell" data-title="Edit">
        <i routerLink="/admin/edit-sub-category/{{subCat.category_id}}/{{subCat._id}}" class="fas fa-pen"
          aria-hidden="true" style="font-size: 17px;color:darkgreen;cursor: pointer;"></i>
      </div>
      <div>
        <select class="custom-select custom-select-sm" [(ngModel)]="subCat.testStatus" (change)="onStatusChange($event, subCat._id)">
          <option *ngFor="let status of statuses" [value]="status.value">{{ status.name }}</option>
        </select>
      </div>
      <div class="cell" data-title="Test" style="cursor:pointer;color:darkblue"
        (click)="showTest(subCat.subCategory_id,subCat.isMultiple)">
        View &nbsp; <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px;"></i>
      </div>
      <div class="cell" data-title="Test" style="cursor:pointer;color:darkblue"
        (click)="showUserResponses(subCat.subCategory_id,subCat.isMultiple)">
        View &nbsp; <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px;"></i>
      </div>

    </div>
  </div>

  <div class="text-center mt-5" *ngIf="allSubCategories.length==0">
    <h5 style="color: darkblue;">**No Sub-Category Exists**</h5>
  </div>
  <!-- pagination -->
  <pagination-controls (pageChange)="p=$event" *ngIf="allSubCategories.length!=0"></pagination-controls>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>