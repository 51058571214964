import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TestsService } from 'src/app/services/tests.service';

@Component({
  selector: 'app-show-subquestion-responses',
  templateUrl: './show-subquestion-responses.component.html',
  styleUrls: ['./show-subquestion-responses.component.css']
})
export class ShowSubquestionResponsesComponent implements OnInit {
  
  allSubQuestions = [];
  questionCount = 0;

  constructor(
    private routerBtn:Router,
    private testsService:TestsService,
    public dialogRef:MatDialogRef<ShowSubquestionResponsesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toast:ToastrService
  )
  {
    this.allSubQuestions = data.subquestions;
  }

  ngOnInit(): void {
  }

  progressValue(answerId,allOptions)
  {
    const index = allOptions.findIndex(i=>i.answerId==answerId);
    // console.log(index);
    let sliderValue = (((index+1)/allOptions.length)*100).toString();
    return sliderValue;
  }

  decreaseQuesNo()
  {
    if((this.questionCount - 1)>=0)
    {
      this.questionCount = this.questionCount - 1;
    }
    else{
      this.toast.error("No Previous Question");
    }
  }

  increaseQuesNo()
  {
    if((this.questionCount + 1)!=this.allSubQuestions.length)
    {
      this.questionCount = this.questionCount + 1;
    }
    else{
      this.toast.error("No Next Question");
    }
  }

}
