import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentdataService {

  private listeners = new Subject<any>();
  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(filterBy) {
    this.listeners.next(filterBy);
  }

  constructor(private http: HttpClient) {
  }

  getAllFaqs() {
    return this.http.get(environment.baseURL + 'faq/list');
  }

  createNewFaq(data) {
    return this.http.post(environment.baseURL + 'faq/create', data);
  }

  updateFaq(data) {
    return this.http.post(environment.baseURL + 'faq/update', data);
  }

  getAllAboutList(data) {
    return this.http.post(environment.baseURL + 'content/list', data);
  }

  updateAboutList(data) {
    return this.http.post(environment.baseURL + 'content/update', data);
  }

  updateAboutImage(id, data) {
    return this.http.post(environment.baseURL + 'content/update-image?id=' + id, data);
  }

  getLegalData(data) {
    return this.http.post(environment.baseURL + 'policy/list', data);
  }

  updateLegalData(data) {
    return this.http.post(environment.baseURL + 'policy/update', data);
  }

  getAllReports(data) {
    return this.http.post(environment.baseURL + 'bug/list', data);
  }

  getAllFeedbacks(data) {
    return this.http.post(environment.baseURL + 'feedback/list', data);
  }

  getAllFeatures(data) {
    return this.http.post(environment.baseURL + 'feature/list', data);
  }

  getAllReportFeedbacks(data) {
    return this.http.post(environment.baseURL + 'suggestion/list', data);
  }

  getAllQuotes(data) {
    return this.http.post(environment.baseURL + 'track-quote/list', data);
  }

  deleteQuote(data) {
    return this.http.post(environment.baseURL + 'track-quote/delete', data);
  }

  createQuote(data) {
    return this.http.post(environment.baseURL + 'track-quote/create', data);
  }

  updateQuote(data) {
    return this.http.post(environment.baseURL + 'track-quote/update', data);
  }

  getWaitlist(data) {
    return this.http.post(environment.baseURL + 'wait-list/list', data);
  }

  createWishlistUser(data) {
    return this.http.post(environment.baseURL + 'wait-list/create', data);
  }

  sendMail(data) {
    return this.http.post(environment.baseURL + 'wait-list/send', data);
  }

  getImageFromUrl(img: any) {
    return this.http.get(environment.baseURL + 'app/download' + img);
  }

  public readFile(url) {
    return this.http
      .get(environment.baseURL + "app/download" + url)
      .pipe(switchMap((blob: any) => this.convertBlobToBase64(blob)));
  }

  convertBlobToBase64(blob: Blob) {
    return Observable.create((observer: any) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        console.log('File could not be read: ' + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

}

