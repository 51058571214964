import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContentdataService } from '../services/contentdata.service';

@Component({
  selector: 'app-create-faq',
  templateUrl: './create-faq.component.html',
  styleUrls: ['./create-faq.component.css']
})
export class CreateFaqComponent implements OnInit {

  constructor(private contentDataService:ContentdataService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<CreateFaqComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {

  }

  ngOnInit(): void {
  }

  createNewFaq(form:NgForm)
  {
    console.log(form.value);
    this.contentDataService.createNewFaq(form.value).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);      
        this.closeModel();
      }
      else{        
        this.spinner.hide();
        this.toast.error(res["description"]);
      }
      },err=>{      
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  closeModel()
  {
    this.dialogRef.close();
    this.contentDataService.filter("scale");
  }
}
