<div class="container">
  <hr class="my-3" />
  <div class="row">
    <div class="col-md-6">
      <label for="">Title : </label>
    </div>
    <div class="col-md-6">
      {{ quesCategoryDetails.questionCategory | titlecase }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Description : </label>
    </div>
    <div class="col-md-6">
      {{ quesCategoryDetails.description }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Description Low: </label>
    </div>
    <div class="col-md-6">
      {{ quesCategoryDetails.detailedDescriptionLow }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Description Medium: </label>
    </div>
    <div class="col-md-6">
      {{ quesCategoryDetails.detailedDescriptionMedium }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Description High: </label>
    </div>
    <div class="col-md-6">
      {{ quesCategoryDetails.detailedDescriptionHigh }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Break Percentage 1: </label>
    </div>
    <div class="col-md-6">{{ quesCategoryDetails.breakPercentage }} %</div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <label for="">Break Percentage 2: </label>
    </div>
    <div class="col-md-6">{{ quesCategoryDetails.breakPercentageHigh }} %</div>
  </div>

  <hr class="my-3" />
</div>
