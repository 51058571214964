<div class="text-center mt-5">
  <h3 style="color:darkblue"><u>Edit Dimension </u></h3>
</div>
<div class="shadow p-3 m-4 bg-white rounded">
  <div class="container px-2" class="z-index:10; ">
    <form (ngSubmit)="onSubmit(form)" #form="ngForm" class="ml-lg-5 pl-lg-5" *ngIf="subCategoryDetails">

      <div class="form-group row">

        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Name of test : </label>
          <input type="text" class="form-control" placeholder="Enter New Category" name="subCategory"
            [(ngModel)]="subCategoryDetails.subCategory" #categoryRef="ngModel" required>
          <div *ngIf="categoryRef.errors?.required  && categoryRef.touched" class="errorDiv">
            *Sub-Category Name is required!
          </div>
        </div>
        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Curios Question : </label>
          <input type="text" class="form-control" placeholder="Enter Category Title" name="title"
            [(ngModel)]="subCategoryDetails.title" #titleRef="ngModel" required>
          <div *ngIf="titleRef.errors?.required  && titleRef.touched" class="errorDiv">
            *Sub-Category Title is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Report type of results : </label>
          <select class="select-dropdown form-control" name="graph_type" [(ngModel)]="subCategoryDetails.graph_type">
            <option value="-1" disabled>Select Graph Type</option>
            <option value="1">Ranked traits</option>
            <option value="2">Trait opposites</option>
            <option value="3">Unranked lists</option>
          </select>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Change Image : </label>
          <input type="file" class="form-control" name="bannerImage" (change)="selectImage($event)" ngModel
            #bannerRef="ngModel">
          <div *ngIf="bannerRef.errors?.required  && bannerRef.touched" class="errorDiv">
            *Sub-Category Image is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Time of test : </label>
          <input type="text" class="form-control" placeholder="Enter time" name="time"
            [(ngModel)]="subCategoryDetails.time" #timeRef="ngModel" required>
          <div *ngIf="timeRef.errors?.required  && timeRef.touched" class="errorDiv">
            *Time is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Diamonds of test : </label>
          <input type="text" class="form-control" placeholder="Enter Diamonds" name="points"
            [(ngModel)]="subCategoryDetails.points" #pointsRef="ngModel" required>
          <div *ngIf="pointsRef.errors?.required  && pointsRef.touched" class="errorDiv">
            *Dimonds is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Coins of test : </label>
          <input type="text" class="form-control" placeholder="Enter Coins" name="coins"
            [(ngModel)]="subCategoryDetails.coins" #coinsRef="ngModel" required>
          <div *ngIf="coinsRef.errors?.required  && coinsRef.touched" class="errorDiv">
            *Coins is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Special test : </label>
          <br>
          <mat-slide-toggle style="padding-top: 5px;" name="specialTest" ngModel
          [(ngModel)]="subCategoryDetails.specialTest"></mat-slide-toggle>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Unlock coins : </label>
          <input type="text" class="form-control" placeholder="Enter unlock coins" name="unlockCoins"
            [(ngModel)]="subCategoryDetails.unlockCoins" #unlockCoinsRef="ngModel" required>
          <div *ngIf="unlockCoinsRef.errors?.required  && unlockCoinsRef.touched" class="errorDiv">
            *Unlock coins is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Unlock diamonds : </label>
          <input type="text" class="form-control" placeholder="Enter unlock diamonds" name="unlockDiamonds"
            [(ngModel)]="subCategoryDetails.unlockDiamonds" #unlockDiamondsRef="ngModel" required>
          <div *ngIf="unlockDiamondsRef.errors?.required  && unlockDiamondsRef.touched" class="errorDiv">
            *Unlock diamonds is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Price : </label>
          <input type="text" class="form-control" placeholder="Enter price" name="price"
            [(ngModel)]="subCategoryDetails.price" #priceRef="ngModel" required>
          <div *ngIf="priceRef.errors?.required  && priceRef.touched" class="errorDiv">
            *Price is required!
          </div>
        </div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Source: </label>
          <input type="text" class="form-control" placeholder="Enter Source" name="source"
            [(ngModel)]="subCategoryDetails.source" #sourceRef="ngModel" style="width: 100%" required>
          <div *ngIf="sourceRef.errors?.required  && sourceRef.touched" class="errorDiv">
            *Short Description is required!
          </div>
        </div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Short Description: </label>
          <textarea rows="3" class="form-control" placeholder="Enter Short Description" name="preDescription"
            [(ngModel)]="subCategoryDetails.preDescription" style="width: 100%" #shortDescriptionRef="ngModel"
            required></textarea>
          <div *ngIf="shortDescriptionRef.errors?.required  && shortDescriptionRef.touched" class="errorDiv">
            *Short Description is required!
          </div>
        </div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Description of test: </label>
          <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText"
            [preserveWhitespace]="false" name="description" ngModel #descriptionRef="ngModel">
          </quill-editor>
          <div *ngIf="descriptionRef.errors?.required  && descriptionRef.touched" class="errorDiv">
            *Sub-Category Description is required!
          </div>
        </div>

        <div class="col-sm-10 text-center mt-3">
          <img src={{subCategoryDetails.imageUrl}} onerror="this.src='../../assets/personalityTest.jpg'" height="172px"
            width="auto" alt="">
        </div>
      </div>
      <!-- <div class="form-group row">
            <div class="col-sm-10 text-center">                
                <img src={{subCategoryDetails.imageUrl}} onerror="this.src='../../assets/personalityTest.jpg'" height="172px" width="auto" alt="">
            </div>
          </div> -->
      <br>

      <div class="form-group row">
        <div class="col-sm-10 text-center">
          <button type="submit" class="btn" [disabled]="!form.valid"
            style="background-color: #4A9F37; width: 200px;border-radius: 10px; color: white;">Save Changes</button>
        </div>
      </div>
    </form>
  </div>
</div>