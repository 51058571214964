<div class="container">
    <hr class="my-3">
    <div class="row text-center" style="border: 1px solid rgb(167, 163, 163);border-radius:10px;">
        <div class="col-md-12 pt-1">
           <b> {{feedbackData.data[0].questionCategory | titlecase}} </b>
        </div>
        <div class="col-md-12">
            {{feedbackData.data[0].description}}
        </div>
        <div class="col-md-12">
            <div class="progress-tooltip my-2">
                <span class="progress-tooltip-info" [ngStyle]="{left: feedbackData.data[0].percentage+'%'}">
                    {{feedbackData.data[0].percentage}}%
                </span>
                <progress class="progress" [value]="feedbackData.data[0].percentage" max="100">55%</progress>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            {{feedbackData.data[0].detailedDescription}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12" *ngFor="let desc of feedbackData.data[0].detailedDescriptionDetails">
            <div class="row">
                <div class="col-md-12 mt-2">
                    <b>{{desc.heading}}</b>
                </div>
                <div class="col-md-12" *ngFor="let pt of desc.points">
                    <ul>
                        <li>
                            {{pt}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-4 text-center" style="border: 1px solid rgb(167, 163, 163);border-radius:10px;">
        <div class="col-md-12 my-2">
            <b>USER FEEDBACK : </b>
        </div>
        <div class="col-md-12 text-left">
            {{feedbackData.description}}
        </div>
        <div class="col-md-12 my-2">
            <a style="cursor: pointer;" href='{{feedbackData.imageURL}}' target="_blank" >
                Download Attachment 
                <i class="fas fa-cloud-download-alt" aria-hidden="true" style="color: rgb(231, 43, 43); font-size: 20px; cursor: pointer; text-decoration: none;"></i>
            </a>
        </div>
    </div>

    <hr class="my-3">
</div>
