<div class="text-right m-1">
    <button mat-raised-button id="model-cancel-button" class="btn btn-danger" (click)="closeModel()">X</button>
</div>
<div class="container-fluid dash_cus"> 
 <div class="container">
     <div class="row">
         <div class="col-sm-2"></div>
         <div class="col-sm-8">
             <div class="user_info">
                 <div class="col-sm-12">
                     <h2>Edit Admin Password :-</h2>
                 </div>
                 <div class="col-sm-12">
                     <form (ngSubmit)="onSubmit(form)" #form="ngForm">
                         <div class="form-group row">
                             <label for="newName" class="col-md-3 col-form-label">Password : </label>
                             <div class="col-md-9">
                                 <input type="text" class="form-control cus_input" name="password" value="" placeholder="Enter new password...." ngModel required #firstNmRef="ngModel">
                                 <div *ngIf="firstNmRef.errors?.required  && firstNmRef.touched" class="errorDiv">
                                   *Password is required!
                               </div>
                               </div>
                         </div>
                         <div class="form-group row">
                             <label for="newName" class="col-md-3 col-form-label"></label>
                             <div class="col-md-9">
                                 <button type="submit" class="form-control cus_input_btn" [disabled]="!form.valid">Submit</button>
                             </div>
                         </div>
                     
                     </form>
                 </div>
             </div>		
         </div>

     </div>
 </div>
</div>