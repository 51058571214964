<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous">

<div class="container-fluid">
    <div *ngFor="let ques of allSubQuestions;let i=index;">
    <div *ngIf="i==questionCount">
        <form (submit)="addQuestion(form,i)" #form="ngForm" >
            <div class="card inner-card-1">
                <div class="text-center row">
                    <div class="col-md-3 text-left" (click)="decreaseQuesNo()" style="cursor: pointer;">
                        <i class="fas fa-chevron-circle-left" style="font-size: 22px;"></i>
                    </div>
                    <div class="col-md-6">
                        <h1 style="font-size: 25px;"><u>Add SubQuestion {{i+1}}</u></h1>
                    </div>
                    <div class="col-md-3 text-right" (click)="increaseQuesNo()" style="cursor: pointer;">
                        <i class="fas fa-chevron-circle-right" style="font-size: 22px;"></i>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-4">
                        Question Text : 
                    </div>
                    <div class="col-md-8">
                        <!-- <input type="text" name="questionText" [(ngModel)]="allSubQuestions[i].quesText" required placeholder="Enter Question Title...."> -->
                        <textarea type="text" style="width: 100%;"  name="subQuestion" [(ngModel)]="allSubQuestions[i].subQuestion" required placeholder="Enter Question Title...."></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        Responses : 
                    </div>
                    <div class="col-md-8">
                        <select class="select-dropdown" name="linkedWith" [(ngModel)]="allSubQuestions[i].linkedWith" style="width: 100%;" required #myLinks 
                        (change)='onSelectedLink(myLinks.value,i)'>
                            <option value="-1" disabled>Select Response Type</option>
                            <option value="RESPONSE_MAIN_QUESTION">Selected Responses of Main Question</option>
                            <option value="ALL_RESPONSE_MAIN_QUESTION">All Responses of Main Question</option>
                            <option value="RESPONSE_SUB_QUESTION">Selected Responses of Sub Question Number</option>
                            <option value="ALL_RESPONSE_SUB_QUESTION">All Responses of Sub Question Number</option>
                            <option value="MANUAL_ENTRY">Only Manual Entry of Responses</option>
                            <option value="RESPONSE_SUB_QUESTION_WITH_SCALE">Selected Response of Sub Question with Scale</option>
                        </select>
                    </div>
                </div>
                <div class="row" *ngIf="allSubQuestions[i].showLinkSubQuesNo">
                    <div class="col-md-4">
                        SubQues Number : 
                    </div>
                    <div class="col-md-8">
                        <!-- <textarea type="text" style="width: 100%;" rows="1" name="linkSubQuesNo" [(ngModel)]="allSubQuestions[i].linkSubQuesNo" required placeholder="Enter Sub-Question Number...."></textarea> -->
                        <input type="number" style="width: 100%;" rows="1" name="linkSubQuesNo" [(ngModel)]="allSubQuestions[i].number" required placeholder="Enter Sub-Question Number....">
                    </div>
                </div>
                <div class="row" *ngIf="allSubQuestions[i].showManualEntryLimit">
                    <div class="col-md-4">
                        Manual Entry Limit : 
                    </div>
                    <div class="col-md-8">
                        <!-- <textarea type="text" style="width: 100%;" rows="1" name="limitManualEntry" [(ngModel)]="allSubQuestions[i].limitManualEntry" required placeholder="Enter Manual Entry Limit...."></textarea> -->
                        <input type="number" style="width: 100%;" name="limitManualEntry" [(ngModel)]="allSubQuestions[i].number" required placeholder="Enter Manual Entry Limit....">
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-4">
                        Scale : 
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-3">
                        <input type="radio" (change)="handleRadioChangePositive($event,i)" [(ngModel)]="allSubQuestions[i].isScale" name="isScale" value="1" required> Yes
                    </div>
                    <div class="col-md-3">
                        <input type="radio" (change)="handleRadioChangeNegative($event,i)" [(ngModel)]="allSubQuestions[i].isScale" name="isScale" value="0" required> No
                    </div>
                </div> 
                <div class="row" *ngIf="allSubQuestions[i].isScale==1"> 
                    <div class="col-md-4">
                        Scale : 
                    </div>
                    <div class="col-md-7">
                        <!-- <select class="select-dropdown" name="scaleId" required [(ngModel)]="allSubQuestions[i].scaleId" >
                            <option value="0" disabled>Select Scale</option>
                            <option [ngValue]="scale.scaleId" *ngFor="let scale of allOptionScales">{{scale.title | titlecase}}</option>
                        </select> --> 
                        <div class="row text-center">
                            <div class="col-md-12 mb-1" *ngIf="scaleData.title.length!=0 || allSubQuestions[i].scaleName.length!=0">
                                <input style="width: 80%;" type="text" [(ngModel)]="allSubQuestions[i].scaleName" name="scaleTitle" disabled required>
                            </div>
                            <div class="col-md-12">
                                <button (click)="addNewScale(i)" type="button" class="btn1"><i class="fas fa-plus"></i> Create New</button>                                
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="row"> 
                    <div class="col-md-4">
                        Manual User Entry : 
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-3">
                        <input type="radio" [(ngModel)]="allSubQuestions[i].userEntry" name="userEntry" value="1" required> Yes
                    </div>
                    <div class="col-md-3">
                        <input type="radio" [(ngModel)]="allSubQuestions[i].userEntry" name="userEntry" value="0" required> No
                    </div>
                </div>            
                <div class="row text-center" style="margin-top:-20px">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <button (click)="addSubQuestions()" type="button" class="btn btn-success">Add SubQuestion</button>                        
                    </div>
                    <div class="col-md-4">
                        <button type="submit" [disabled]="!form.valid" id="saveBtn" class="btn btn-success">Submit</button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>

        </form>        
    </div>
    </div>

    <button (click)="returnToMainQuestion()" type="button" class="btn float my-float" style="background-color: #F06528; color: white;">
        Return
    </button>   
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please Wait...</p>
</ngx-spinner>
