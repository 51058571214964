<div class="container">
    <!-- <div class="row text-center">
        <div class="col-md-12">
            <h6 style="margin-left: auto;margin-right: auto;">Select Scale Options</h6>
        </div>
    </div> -->
    <hr class="my-3" style="color:rgb(131, 128, 128);">
    <form (submit)="onSubmit(form)" #form="ngForm">
    <div class="row mt-3" *ngFor="let ques of previousSubquestions;let i = index;">
        <div class="col-md-4 text-left">
            <input *ngIf="existingAnswers.length!=0 && existingAnswers[i].scaleName!=undefined" class="form-check-input ml-2 checkmark" type="checkbox" name="scaleName" [checked]="testMain(i,ques.scaleName)" (change)="dataScale($event,i+1)" [value]="ques.scaleName" #checkBox>
            <input *ngIf="existingAnswers.length==0" class="form-check-input ml-2 checkmark" type="checkbox" name="scaleName" (change)="dataScale($event,i+1)" [value]="ques.scaleName" #checkBox ngModel>
            <label class="form-check-label ml-5" for="gridCheck1" style="font-weight: 550;">
                <!-- {{ques.scaleName}} -->
                SubQuestion {{ques.subQuesNo}}
            </label>
        </div>
        <div class="col-md-8 text-center" *ngIf="showSubquesOptions[i].showOptions">
            <div class="row">
                <div class="col-md-12">
                    <h6>{{ques.scaleName}}</h6>
                </div>
                <div class="col-md-4" *ngFor="let op of ques.options;let j=index;">
                    <!-- {{op.text}} -->
                    <input class="form-check-input ml-2 checkmark" [checked]="test(ques.scaleName,op.text)"  type="checkbox" name="optionName" (change)="dataScaleOptions($event,i+1)" [value]="op.text" #checkBoxOp>
                    <label class="form-check-label ml-5" for="gridCheck1">
                        {{op.text}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-2">
        <div class="col-md-1"></div>
        <div class="col-md-5">
            <button type="submit" class="btn btn-primary btn-block">Save Options</button>
        </div>
        <div class="col-md-5">
            <button (click)="closeModel()" class="btn btn-danger btn-block">Cancel</button>
        </div>
        <div class="col-md-1"></div>
    </div>
    </form>
    <hr class="my-3">
</div>