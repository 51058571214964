import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from './services/auth.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public toggleNavbar = true;
  public isLoggedIn = false;

  public firstName = '';
  public lastName = '';
  public email = '';

  adminId;
  adminImage;
  adminDetails: any;

  constructor(private authService: AuthService) {
    this.authService.listen().subscribe((m: any) => {
      console.log(m);
      this.ngOnInit();
    });
  }

  toggleFunc() {
    $('#menu-toggle').on('click', function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
  }

  ngOnInit() {
    this.authService.autoLogin();
    $('#menu-toggle').on('click', function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });

    this.authService.loggedIn.subscribe((res) => {
      this.isLoggedIn = res;

      if (JSON.parse(localStorage.getItem('authUserData')) != null) {
        this.adminId = JSON.parse(localStorage.getItem('authUserData'))._id;
        // console.log(this.adminId);
        this.firstName = JSON.parse(
          localStorage.getItem('authUserData')
        ).firstName;
        this.lastName = JSON.parse(
          localStorage.getItem('authUserData')
        ).lastName;
        this.email = JSON.parse(localStorage.getItem('authUserData')).email;
        this.adminDetails = JSON.parse(localStorage.getItem('authUserData'));

        this.authService
          .adminDetails({ email: this.email })
          .subscribe((res: any) => {
            this.firstName = res.data.firstName;
            this.lastName = res.data.lastName;
            this.adminImage =
              environment.baseURL + 'app/download' + res.data.adminImage;
          });
      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
