import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { TestsService } from '../services/tests.service';
import { ViewCategoryDetailsComponent } from '../view-category-details/view-category-details.component';

@Component({
  selector: 'app-all-tests',
  templateUrl: './all-tests.component.html',
  styleUrls: ['./all-tests.component.css'],
})
export class AllTestsComponent implements OnInit {
  allCategories = [];

  statuses = [
    { name: 'Soon', value: 'SOON' },
    { name: 'Live', value: 'LIVE' },
    { name: 'Deactivate', value: 'DEACTIVATE' },
  ];

  constructor(
    public matDialog: MatDialog,
    private testsService: TestsService,
    private routerBtn: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => {},
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

    this.spinner.show();
    this.testsService.getAllCategories({}).subscribe((res) => {
      this.allCategories = res['categoryData'];
      console.log(this.allCategories);
      this.spinner.hide();
    });
  }

  goToSubCategories(id) {
    this.routerBtn.navigate(['/admin/all-subcategories/' + id]);
  }

  viewCategoryDetails(categoryDetails) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-category-details-component';
    dialogConfig.height = '420px';
    dialogConfig.width = '500px';
    //passing data
    dialogConfig.data = { categoryDetails: categoryDetails };

    const modalDialog = this.matDialog.open(
      ViewCategoryDetailsComponent,
      dialogConfig
    );
  }

  toggle(event: MatSlideToggleChange, categoryDetails) {
    const archive: any = event.checked;
    this.spinner.show();
    console.log('Toggle fired', event.checked);
    const formData = new FormData();
    formData.append('category', categoryDetails.category);
    formData.append('title', categoryDetails.title);
    formData.append('description', categoryDetails.description);
    formData.append('id', categoryDetails._id);
    formData.append('archive', archive);

    this.testsService.editCategory(formData).subscribe(
      (res) => {
        // console.log(res);
        if (res['status']) {
          this.spinner.hide();
          this.toast.info(res['description'], '', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
          this.routerBtn.navigate(['/admin/all-tests']);
        } else {
          this.spinner.hide();
          this.toast.error(res['description'], 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err, 'Error Occured', {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right',
        });
      }
    );
  }

  onStatusChange(e, id) {
    this.testsService.updateCategoryStatus({id, categoryStatus : e.target.value}).subscribe((res: any) => {
      if(res.status) {
        this.toast.success('Status updated successfully!');
      }
    }, error => {
      this.toast.error("Error Occured");
    });
  }
}
