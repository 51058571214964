// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBWmvo08HB4yaghDLDVE1IdO2T6AfJwBoc',
    authDomain: 'demonotification-ef0eb.firebaseapp.com',
    projectId: 'demonotification-ef0eb',
    storageBucket: 'demonotification-ef0eb.appspot.com',
    messagingSenderId: '213217089668',
    appId: '1:213217089668:web:be9e1c43f44ef518bf8ad1',
    measurementId: 'G-2Q5SZ232DG',
  },
  // baseURL: 'https://personalityapp.herokuapp.com/api/v1/'
  
  // baseURL: 'https://92.205.28.214:3000/api/v1/',
  baseURL: 'https://apip.scientific-selfdiscovery.com/api/v1/',
  // baseURL: "http://82.180.160.159:3001/api/v1/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
