import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestsService } from '../services/tests.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-quescategory',
  templateUrl: './edit-quescategory.component.html',
  styleUrls: ['./edit-quescategory.component.css']
})
export class EditQuescategoryComponent implements OnInit {

  categoryId;
  quesCategoryDetails;
  allCategories = [];
  allSubCategories = [];
  defaultCategory = "0";
  defaultSubCategory = "0";

  categoryName = "";
  subCategoryName = "";
  
  lowEditorText: string;
  mediumEditorText: string;
  highEditorText: string;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    },
  }

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<EditQuescategoryComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.quesCategoryDetails = data.quesCategoryData;
    this.lowEditorText = this.quesCategoryDetails.lowDetails;
    this.mediumEditorText = this.quesCategoryDetails.mediumDetails;
    this.highEditorText = this.quesCategoryDetails.highDetails;
  }

  ngOnInit(): void {
    this.testsService.getAllCategories({}).subscribe(res=>{
      this.allCategories = res["categoryData"];
      this.defaultCategory = this.quesCategoryDetails.category_id;
      //Fetching all Sub-Categories
      this.testsService.getAllSubCategories({category_id:this.defaultCategory}).subscribe(res=>{
        this.allSubCategories = res["subCategoryData"];
        console.log(this.allSubCategories);
        this.defaultSubCategory = this.quesCategoryDetails.subCategory_id;
      });
    });
  }

  editQuestionCategory(form:NgForm)
  {
    var quesCategoryData = {
      id:this.quesCategoryDetails._id,
      questionCategory:form.value.questionCategory,
      // category:this.categoryName,
      // category_id:this.defaultCategory,
      // subCategory:this.subCategoryName,
      // subCategory_id:this.defaultSubCategory,
      description:form.value.description,
      detailedDescriptionHigh:form.value.detailedDescriptionHigh,
      detailedDescriptionLow:form.value.detailedDescriptionLow,
      detailedDescriptionMedium:form.value.detailedDescriptionMedium,
      breakPercentage:form.value.breakPercentage,
      breakPercentageHigh:form.value.breakPercentageHigh,
      lowDetails: this.lowEditorText,
      mediumDetails: this.mediumEditorText,
      highDetails: this.highEditorText
    };
    console.log(quesCategoryData);
    this.testsService.updateQuestionCategory(quesCategoryData).subscribe(res=>{
      if(res["status"])
      {
        this.spinner.hide();
        this.toast.success(res["description"]);      
        this.closeModel();
      }
      else{        
        this.spinner.hide();
        this.toast.error(res["description"]);    
      }
      },err=>{      
        console.log(err);
        this.spinner.hide();
        this.toast.error(err.error["description"]);
    });
  }

  onSelectedCategory(value)
  {
    console.log("Value "+value);

    this.defaultCategory = value.split(':')[1].trim();
    this.categoryId = this.defaultCategory;
    const index = this.allCategories.findIndex(i=>i.category_id==this.categoryId);
    if(index!=-1)
    {
      this.categoryName = this.allCategories[index].category;
      console.log(this.categoryName);
    }

    //Fetching all Sub-Categories
    this.testsService.getAllSubCategories({category_id:this.categoryId}).subscribe(res=>{
      this.allSubCategories = res["subCategoryData"];
      console.log(this.allSubCategories);
      this.onSelectedSubCategory("0:"+this.allSubCategories);
    });
    
  }

  onSelectedSubCategory(value)
  {
    console.log("Sub Category value : ",value);
    if(value.split(':')[0].trim()==0)
    {
      this.defaultSubCategory = this.allSubCategories[0].subCategory_id;
    }
    else{
      this.defaultSubCategory = value.split(':')[1].trim();
    }
    console.log(this.defaultSubCategory);
    const index = this.allSubCategories.findIndex(i=>i.subCategory_id==this.defaultSubCategory);
    if(index!=-1)
    {
      this.subCategoryName = this.allSubCategories[index].subCategory;
      console.log(this.subCategoryName);
    }
  }

  closeModel()
  {
    this.dialogRef.close();
    this.testsService.filter("Created");
  }

}
