<div class="text-center mt-5">
  <h3 style="color:darkblue"><u>Add New Category </u></h3>
</div>
<div class="shadow p-3 m-4 bg-white rounded">
  <div class="container px-2" class="z-index:10; ">
    <form (ngSubmit)="onSubmit(form)" #form="ngForm" class="ml-lg-5 pl-lg-5">

      <div class="form-group row">

        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Category : </label>
          <input type="text" class="form-control" placeholder="Enter New Category" name="category" ngModel
            #categoryRef="ngModel" required>
          <div *ngIf="categoryRef.errors?.required  && categoryRef.touched" class="errorDiv">
            *Category Name is required!
          </div>
        </div>
        <div class="col-sm-5">
          <label for="" style="font-weight: 600;">Title : </label>
          <input type="text" class="form-control" placeholder="Enter Category Title" name="title" ngModel
            #titleRef="ngModel" required>
          <div *ngIf="titleRef.errors?.required  && titleRef.touched" class="errorDiv">
            *Category Title is required!
          </div>
        </div>
        <div class="col-sm-5 mt-3">
          <label for="" style="font-weight: 600;">Category Image : </label>
          <input type="file" class="form-control" name="bannerImage" (change)="selectImage($event)" ngModel required
            #bannerRef="ngModel">
          <div *ngIf="bannerRef.errors?.required  && bannerRef.touched" class="errorDiv">
            *Category Image is required!
          </div>
        </div>
        <div class="col-sm-5"></div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Short Description : </label>
          <textarea rows="3" class="form-control" placeholder="Enter Short Description" name="preDescription" ngModel
            #shortDescriptionRef="ngModel" required></textarea>
          <div *ngIf="shortDescriptionRef.errors?.required  && shortDescriptionRef.touched" class="errorDiv">
            *Short Description is required!
          </div>
        </div>
        <div class="col-sm-10 mt-3">
          <label for="" style="font-weight: 600;">Description : </label>
          <!-- <input type="text" class="form-control" placeholder="Enter Category Description" name="description" ngModel #descriptionRef="ngModel" required> -->
          <!-- <textarea rows="7" class="form-control" placeholder="Enter Category Description" name="description" ngModel #descriptionRef="ngModel" required></textarea> -->
          <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText"
            [preserveWhitespace]="false" name="description" ngModel #descriptionRef="ngModel">
          </quill-editor>
          <div *ngIf="descriptionRef.errors?.required  && descriptionRef.touched" class="errorDiv">
            *Category Description is required!
          </div>
        </div>

      </div>

      <br>

      <div class="form-group row">
        <div class="col-sm-10 text-center">
          <button type="submit" class="btn" [disabled]="!form.valid"
            style="background-color: #4A9F37; width: 200px;border-radius: 10px; color: white;">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Creating...</p>
</ngx-spinner>