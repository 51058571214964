import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { ContentdataService } from '../services/contentdata.service';
import { ViewSingleFeatureComponentComponent } from '../view-single-feature-component/view-single-feature-component.component';

@Component({
  selector: 'app-all-features',
  templateUrl: './all-features.component.html',
  styleUrls: ['./all-features.component.css']
})
export class AllFeaturesComponent implements OnInit {
  features = [];
  p: number = 1;
  totalCount: number;

  constructor(
    public matDialog: MatDialog,
    private contentDataService: ContentdataService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService
      .adminDetails({
        email: JSON.parse(localStorage.getItem('authUserData')).email,
      })
      .subscribe(
        (res: any) => { },
        (errors: any) => {
          this.authService.logout();
          this.toast.error(errors.error.description, 'Error Occured', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
        }
      );

      this.getAllFeatures(0)
  }

  getAllFeatures(skip) {
    this.spinner.show();
    let payload = {
      skip,
      limit: 10
    }

    this.contentDataService
      .getAllFeatures(payload)
      .subscribe((res) => {
        this.features = res['featureData'];
        this.totalCount = res['featureTotal']
        this.spinner.hide();
      });
  }

  onPageChange(e) {
    this.p = e;
    let skip = e == 1 ? 0 : (e - 1) * 10;
    this.getAllFeatures(skip)
  }

  viewSingleFeature(feature: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'view-single-report-component';
    dialogConfig.height = "auto";
    dialogConfig.width = '460px';
    //passing data
    dialogConfig.data = { feature };

    const modalDialog = this.matDialog.open(
      ViewSingleFeatureComponentComponent,
      dialogConfig
    );
  }

}
