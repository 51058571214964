import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-category-details',
  templateUrl: './view-category-details.component.html',
  styleUrls: ['./view-category-details.component.css']
})
export class ViewCategoryDetailsComponent implements OnInit {

  categoryDetails;

  constructor(public dialogRef: MatDialogRef<ViewCategoryDetailsComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoryDetails = data.categoryDetails;
    this.categoryDetails.imageUrl = environment.baseURL + "app/download" + this.categoryDetails.imageUrl;
    console.log(this.categoryDetails);
  }

  ngOnInit(): void {
  }

}
