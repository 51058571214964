import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ProfileComponent } from '../profile/profile.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-admin-password',
  templateUrl: './edit-admin-password.component.html',
  styleUrls: ['./edit-admin-password.component.css']
})
export class EditAdminPasswordComponent implements OnInit {

  adminEmail;
  admin:any;

  constructor(private authService:AuthService,private toast:ToastrService,public dialogRef:MatDialogRef<ProfileComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.adminEmail = data.adminEmail;
    console.log(this.adminEmail);
  }

  ngOnInit(): void {
    this.authService.adminDetails({email:this.adminEmail}).subscribe(res=>{
      this.admin = res["data"];
      console.log("Admin : ", this.admin);
    });
  }

  onSubmit(form:NgForm)
  {
    var newForm = {
      email:this.admin.email,
      newPassword:form.value.password
    };
    console.log(newForm);
    this.authService.editAdminPassword(newForm).subscribe(res=>{
      console.log("Result : ",res)
      if(res["status"])
      {
        this.toast.success(res["message"],"Successs",{
          timeOut:2500,
          progressBar:true,
          progressAnimation:'increasing',
          positionClass:'toast-top-right'
        });
        this.closeModel();
      }
      else
      {
        this.toast.error("Details not updated....error occured","Error",{
          timeOut:2500,
          progressBar:true,
          progressAnimation:'increasing',
          positionClass:'toast-top-right'
        })
      }
    })
  
  }

  closeModel()
  {
    this.dialogRef.close();
    this.authService.filter("Deleted");
  }

}
