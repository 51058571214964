import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { TestsService } from '../services/tests.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-scale-multiple',
  templateUrl: './create-scale-multiple.component.html',
  styleUrls: ['./create-scale-multiple.component.css']
})
export class CreateScaleMultipleComponent implements OnInit {

  signupForm:FormGroup;
  subQuesIndex;

  constructor(private testsService:TestsService,private toast:ToastrService,private spinner:NgxSpinnerService,public dialogRef:MatDialogRef<CreateScaleMultipleComponent> ,@Optional() @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.subQuesIndex = +data.index;
  }

  ngOnInit()
  {              
    this.signupForm = new FormGroup({
      'title' : new FormControl(null, [Validators.required]),
      'options':new FormArray([]),      
      'reverse' : new FormControl('', [Validators.required]),
    });
  }

  onSubmit()
  {
    let convertedOptions = this.signupForm.value.options.map(i=>{
      return {"text":i};
    });
    // console.log(convertedOptions);

    var scaleData = {
      title:this.signupForm.value.title,
      options:convertedOptions,
      reverse:this.signupForm.value.reverse=='true'?true:false
    };
    
    if(scaleData.options.length<2)
    {
      this.toast.error("At least 2 options needed");
    }
    else{
      // console.log(scaleData);
      this.closeModel(scaleData);
    }

  }

  onAddOptions()
  {
    const control = new FormControl(null, Validators.required);
      // typecasting into FormArray
    (<FormArray>this.signupForm.get('options')).push(control);
  }

  onRemoveOptions(index)
  {
    (<FormArray>this.signupForm.get('options')).removeAt(index);
  }

  getControls() {
    return (<FormArray>this.signupForm.get('options')).controls;
  }

  closeModel(scaleData)
  {
    this.dialogRef.close();
    this.testsService.filter({type:"multiQuesScale",data:scaleData,index:this.subQuesIndex});
  }

}
