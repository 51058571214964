<div class="wrapper" style="margin-top: 0px">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 text-left" style="box-shadow: none">
        <h4>{{ headingText }}</h4>
      </div>
    </div>
  </div>

  <form
    [formGroup]="subAdminForm"
    (ngSubmit)="onSubmit()"
    class="ml-lg-5 mt-lg-5"
  >
    <div class="row">
      <div class="col">
        <div class="form-group col-md-10">
          <label>First Name</label>
          <input
            type="text"
            class="form-control"
            formControlName="firstName"
            placeholder="Enter your first name"
          />
          <div
            *ngIf="
              (formSubmitted || subAdminForm.controls.firstName.touched) &&
              subAdminForm.controls.firstName.errors?.required
            "
            class="error"
          >
            *First name is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Last Name</label>
          <input
            type="text"
            class="form-control"
            formControlName="lastName"
            placeholder="Enter your last name"
          />
          <div
            *ngIf="
              (formSubmitted || subAdminForm.controls.lastName.touched) &&
              subAdminForm.controls.lastName.errors?.required
            "
            class="error"
          >
            *Last name is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Permissions</label>
          <div *ngFor="let permission of permissions" class="ml-4">
            <input
              type="checkbox"
              class="form-check-input permission-checkbox"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="permission.isChecked"
            />
            <span class="form-check-label">{{ permission.name }}</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group col-md-10">
          <label>Enter Email ID</label>
          <input
            type="email"
            class="form-control"
            name="email"
            formControlName="email"
            placeholder="Enter your email"
          />
          <div
            *ngIf="
              (formSubmitted || subAdminForm.controls.email.touched) &&
              subAdminForm.controls.email.errors?.required
            "
            class="error"
          >
            *Email is required!
          </div>
          <div
            *ngIf="
              (formSubmitted || subAdminForm.controls.email.touched) &&
              subAdminForm.controls.email.errors?.email
            "
            class="error"
          >
            *Enter valid email id!
          </div>
        </div>
        <div class="form-group col-md-10">
          <div class="input-group">
            <input
              [type]="showPassword ? 'text' : 'password'"
              class="form-control cus_input"
              formControlName="password"
              placeholder="Password"
            />
            <div
              class="input-group-append"
              style="cursor: pointer"
              (click)="passwordVisibility()"
            >
              <span class="input-group-text">
                <i
                  [ngClass]="{
                    'fas fa-eye-slash': !showPassword,
                    'fa fa-eye': showPassword
                  }"
                ></i>
              </span>
            </div>
          </div>
          <div
            *ngIf="
              (formSubmitted || subAdminForm.controls.password.touched) &&
              subAdminForm.controls.password.errors?.required
            "
            class="error"
          >
            *Password is required!
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row mt-4">
      <div class="col-sm-10 text-center">
        <button
          type="submit"
          class="btn"
          style="
            background-color: #4a9f37;
            width: 200px;
            border-radius: 10px;
            color: white;
          "
          [disabled]="!subAdminForm.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">{{ loaderText }}</p>
</ngx-spinner>
